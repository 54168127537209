/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eol-last */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Text,
  Image,
  Heading,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Select,
  useColorModeValue,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import Navbar from '../../components/Navbar/Navbar';
import BookList from './sections/BookList';
import CreateSeries from './sections/CreateSeries';
import UploadImg from '../../assets/images/upload.png';
import { axiosPrivate } from '../../api/axios';
import SeriesList from './sections/SeriesList';

const baseDropzoneStyle = {
  height: '400px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#888',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#000',
};

const ReleaseList = [
  { value: 'COMING_SOON', label: 'COMING_SOON' },
  { value: 'LIVE', label: 'LIVE' },
];
const CategoryList = [
  { value: 'None', label: 'None' },
  { value: 'Theology', label: 'Theology' },
  { value: 'Biography', label: 'Biography' },
  { value: 'Sermons', label: 'Sermons' },
  { value: 'History', label: 'History' },
];
function BookUpload() {
  const [initStage, setInitStage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formStage, setFormStage] = useState(false);
  const [tempFile, setTempFile] = useState<Blob>();

  const onDropAccepted = async (files:any) => {
    // console.log(files[0]);
    setTempFile(files[0] as Blob);
    setInitStage(false);
    setFormStage(true);
  };
  const cancelPublish = () => {
    window.location.reload();
  };
  const onDropRejected = () => alert('Your file format cannot be accepted');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['.txt', '.docx'], // TODO: Accept other book types
    multiple: true,
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
  });
  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);
  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width="75vw"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image marginX="auto" width="32px" objectFit="contain" src={UploadImg} />
        <Text
          fontFamily="PtSerif"
          fontSize={{ base: '1.2em', md: '1.5em' }}
          fontWeight="300"
          textAlign="center"
        >
          Drag and drop your book here to upload

        </Text>
        <Text fontFamily="PtSerif">or click here</Text>
      </Flex>
    </Flex>
  );
  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          title: '',
          author: '',
          description: '',
          releaseState: 'COMING_SOON',
          category: 'none',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Must include a title'),
          author: Yup.string()
            .required('Must include an author'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setUploading(true);
          // const bookData = {
          //   title: values.title,
          //   author: values.author,
          //   description: values.description,
          // };
          // console.log(values.releaseState);
          const config = {
            header: { 'content-type': 'multipart/form-data' },
            timeout: 5 * 60 * 60 * 1000,
            useCredentials: true,
          };
          const formData = new FormData();
          formData.append('file', tempFile as Blob);
          formData.append('title', values.title);
          formData.append('author', values.author);
          formData.append('description', values.description);
          formData.append('releaseState', values.releaseState);
          formData.append('category', values.category);
          // console.log(`all files including thumbs: ${allFiles}`);
          // console.log(`all thumbs: ${thumbFiles}`);
          const res = await axiosPrivate.post('/api/book/createStringFromText', formData, config);
          if (res.status === 200) {
            alert('File have been sucessfully uploaded!');
            window.location.reload();
          }
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props:any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >

              <Field name="title">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="title"
                      ml="4px"
                      fontWeight="450"
                    >
                      Title
                    </FormLabel>

                    <Input
                      id="title"
                      placeholder="Add a title to your file"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="author">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="author"
                      ml="4px"
                      fontWeight="450"
                    >
                      Author
                    </FormLabel>

                    <Input
                      id="author"
                      placeholder="Add a author for your file"
                      value={values.author}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="description"
                      ml="4px"
                      fontWeight="450"
                    >
                      Description (Optional)
                    </FormLabel>

                    <Textarea
                      id="description"
                      placeholder="Add a album description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="releaseState">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="releaseState"
                      ml="4px"
                      fontWeight="450"
                    >
                      Release State
                    </FormLabel>
                    <Select
                      id="releaseState"
                      onChange={handleChange}
                    >
                      {ReleaseList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="category">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="category"
                      ml="4px"
                      fontWeight="450"
                    >
                      Category
                    </FormLabel>
                    <Select
                      id="category"
                      onChange={handleChange}
                    >
                      {CategoryList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  type="button"
                  onClick={cancelPublish}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="10px"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="10px"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  useEffect(() => {
    setInitStage(true);
  }, []);
  return (
    <Box
      color="#FFF"
      bg="#111"
    >
      <Navbar />
      <Tabs
        position="relative"
        variant="unstyled"
        paddingTop="4rem"
        width={{ base: '90%', lg: '90%', xl: '85%' }}
        marginX="auto"
      >
        <TabList>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            New Boook

          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Books

          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            New Series

          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Series

          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minH="80vh"
            >
              { /* <AlertBanner message={alertMessage} /> */ }
              <Heading fontWeight="500" fontSize={{ base: '1.3em', sm: '1.5em', lg: '2em' }} margin="2rem">Upload Book File</Heading>
              {initStage
                ? (
                  <Box>
                    {renderDropzone()}
                  </Box>
                )
                : (
                  <Box />
                )}
              {formStage
                ? (
                  <Box>
                    {renderDetailsForm()}
                  </Box>
                )
                : (
                  <Box />
                )}
            </Flex>
          </TabPanel>
          <TabPanel>
            <BookList />
          </TabPanel>
          <TabPanel>
            <CreateSeries />
          </TabPanel>
          <TabPanel>
            <SeriesList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default BookUpload;