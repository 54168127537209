/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Spinner,
  Link,
  Avatar,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from '../../api/axios';
import { postBook } from '../../_reducers/bookSlice';
import { Friend, UserBasic } from '../../_interfaces/User';
import BookView from '../../components/BookSections/BookView';
import Navbar from '../../components/Navbar/Navbar';
import { setMyHighlights, setMyNotes } from '../../_reducers/NoteSlice';

export default function DetailedFriendBook() {
  const dispatch = useDispatch();
  const { userId, isPaid } = useSelector((state: UserBasic) => state.user.userData);
  const [friendProfile, setFriendProfile] = useState<Friend>();
  const [err, setErr] = useState();
  const [failedMsg, setFailedMsg] = useState<string>();
  const [isPaidUser, setIsPaidUser] = useState<boolean>();
  const textManual = useColorModeValue('#000', '#FFF');
  const bgButton = useColorModeValue('#CCC', '#333');
  const bg = useColorModeValue('#FFF', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const [retrievedBook, setRetrievedBook] = useState(false);
  const [friendStatus, setFriendStatus] = useState();
  const { bookId, friendUsername } = useParams();
  // split by page number and line number
  // ensure that chapter jumping works correctly in mobile

  const renderSpinner = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      <Text
        fontFamily="Garamond"
      >
        Loading Book...

      </Text>
    </Flex>

  );
  const retrieveUserBook = () => {
    axios({
      url: '/api/book/retrieveBookById',
      method: 'POST',
      data: {
        bookId,
        username: friendUsername,
        isPaid: true,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const { book, bookContent } = res.data;
          dispatch(postBook(
            {
              bookData: book,
              bookContent,
              userBook: true,
              isPaid: true,
            },
          ));
          dispatch(setMyNotes(book.userNotes));
          dispatch(setMyHighlights(book.highlights));
          // console.log(book);
          setRetrievedBook(true);
          // setRetrievedBook(true);
        }
      })
      .catch((mongoErr) => {
        setErr(mongoErr);
      });
  };

  const getUserData = (username: string) => {
    axios({
      method: 'POST',
      url: '/api/connections/checkFriendshipUsername',
      data: {
        userId,
        friendUsername: username,
      },
    })
      .then((res) => {
        // console.log(res.data.books);
        const {
          foundUser,
          isFriends,
        } = res.data;
        setFriendProfile(foundUser);
        if (isFriends) {
          retrieveUserBook();
          setFriendStatus(isFriends);
        } else {
          setFailedMsg(`
          This Account is Friends Only.
          <br />
          Connect to see their books and notes.`);
          setFriendStatus(isFriends);
        }
      });
  };
  useEffect(() => {
    // const { bookId } = useParams();
    if (isPaid) {
      getUserData(friendUsername || '');
    } else {
      setFailedMsg(`
      Please subscribe
      <a
        href="/subscription"
        style="text-decoration:underline;"
      >
        here
      </a>
      if you would like see your friend&apos;s book highlights, notes and comments.`);
      setIsPaidUser(false);
    }
  }, [friendUsername, isPaid]);

  return (
    <Box
      className="friendbook-container"
      bg={bg}
      color={toggleColor}
    >
      <Navbar />
      <Box
        width="90%"
        marginX="auto"
        paddingTop="2rem"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          paddingX={{ base: '0', md: '4em' }}
          paddingY="2em"
          textAlign="center"
        >
          <Box>
            <Link
              href={`/profile/${friendProfile?._id}`}
              _focus={{ outline: 'none' }}
            >
              <Avatar
                size="lg"
                src={friendProfile?.image}
                filter="brightness(0.9)"
                _hover={{
                  filter: 'brightness(1)',
                  transform: 'scale(1.08)',
                  transition: 'transform 0.25s ease',
                }}
              />
            </Link>
            <Link
              href={`/profile/${friendProfile?._id}`}
              _focus={{ outline: 'none' }}
            >
              <Text
                fontFamily="PtSerif"
                fontWeight="300"
                fontSize={{ base: '14px', lg: '18px' }}
                color={textManual}
                marginTop="10px"
              >
                {friendProfile?.username}
              </Text>
            </Link>
          </Box>
        </Flex>
      </Box>
      <Box>
        {isPaidUser === false || friendStatus === false ? (
          <Flex
            minH="60vh"
            flexDir="column"
            justifyContent="center"
          >
            <Box>
              <Flex
                justifyContent="center"
                flexDir="column"
                alignItems="center"
                height={{ base: '200px', md: '300px' }}
                marginY="4em"
                backgroundColor={bgButton}
                textAlign="center"
              >
                <Text
                  fontWeight="600"
                  fontFamily="PtSerif"
                  fontSize={{ base: '14px', md: '16px' }}
                  width="90%"
                  marginX="auto"
                >
                  {parse(failedMsg || '')}
                </Text>
              </Flex>
            </Box>
          </Flex>
        )
          : (
            <Box>
              {retrievedBook
                ? (
                  <Box>
                    <BookView />
                  </Box>
                ) : (
                  renderSpinner()
                )}
            </Box>
          )}
      </Box>
    </Box>
  );
}
