import React from 'react';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import '../SignUp.scss';
import SmallLogo from '../../../../../components/Logos/SmallLogo/SmallLogo';

function Standard() {
  return (
    <Box
      as="article"
      className="card card-black"
    >
      <SmallLogo color="white" />
      <Text
        as="p"
        data-testid="standard-package-subheader"
        marginTop="1.5rem"
        className="large-text large-text-white"
        textAlign="center"
      >
        Standard Package
      </Text>
      <Text
        as="p"
        data-testid="standard-package-subheader"
        marginTop="1.5rem"
        className="medium-text medium-text-white"
      >
        For Limited time - Early Adopters:
      </Text>
      <Text
        as="p"
        className="section-heading large-text-white"
      >
        $6.95
      </Text>
      <Text
        as="p"
        className="medium-text medium-text-white"
        textDecor="underline"
      >
        Standard Package includes:
      </Text>
      <Text
        as="p"
        className="small-text small-text-white"
        textAlign="left"
        data-testid="standard-feature-one"
        marginTop="2rem"
      >
        - Annotate and Highlight
        <br />
        - The ability to share with friends and groups your thoughts
        <br />
        - Ability to bookmark
        <br />
        <i> - And many more to come</i>
      </Text>
      <Box
        as="a"
        className="link-btn link-btn-grey"
        href="/register"
      >
        Register
      </Box>
    </Box>
  );
}

export default Standard;
