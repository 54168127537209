/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import axios, { axiosPrivate } from '../../../api/axios';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

interface User {
  username: string,
  email: string,
}

export default function RegularUsers() {
  const user = useSelector((state: UserBasic) => state.user.userData);
  const [regUsers, setRegUsers] = useState<User[]>([]);
  const [deleteCandidate, setDeleteCandidate] = useState<string>();
  const [numPaidUsers, setNumPaidUsers] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [numberRegUsers, setNumberRegUsers] = useState(1);

  const itemsPerPage = 18;
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const getPaidUsers = () => {
    axios({
      method: 'GET',
      url: '/api/user/getNumPaidUsers',
    })
      .then((res) => {
        const { userCount } = res.data;
        // console.log(userCount);
        setNumPaidUsers(userCount);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const getRegUsers = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'POST',
      url: '/api/user/initRegUsers',
      data: {
        itemsPerPage,
      },
    })
      .then((res) => {
        const { users, userCount } = res.data;
        setRegUsers(users);
        setNumberRegUsers(userCount);
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(userCount / itemsPerPage));
        setItemOffset(endOffset);
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setUsersDisplayed(standardUsers.slice(itemOffset, endOffset));
        // setListOfAdmins(adminUsers);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  useEffect(() => {
    if (user.username) {
      getPaidUsers();
      getRegUsers();
      // getTestUsers();
    }
  }, [user.username]);

  const unRegisterUser = () => {
    console.log('testing');
  };

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % numberRegUsers;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axios({
      method: 'POST',
      url: '/api/user/RegUsersIndex',
      data: {
        itemsPerPage,
        offsetIndex: newOffset,
      },
    })
      .then((res) => {
        const { users } = res.data;
        setRegUsers(users);
        // setUsersDisplayed(standardUsers.slice(itemOffset, endOffset));
        // setListOfAdmins(adminUsers);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        setItemOffset(newOffset);
        document.getElementById('reg-user-list')?.scrollIntoView();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  return (
    <Box
      as="main"
      minH="80vh"
    >
      <Box
        marginBottom="100px"
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Text
            marginTop="4rem"
            className="medium-text medium-text-white medium-text-bold"
          >
            Standard User Registry
          </Text>
          <Text
            className="small-text small-text-white"
            marginTop="1rem"
          >
            No. of Users:
            {' '}
            {numberRegUsers}
          </Text>
          <Text
            className="small-text small-text-white"
            marginY="1rem"
          >
            No. of Paid Users:
            {' '}
            {numPaidUsers}
          </Text>
        </Flex>
        <SimpleGrid
          id="reg-users-list"
          gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr 1fr' }}
          width={{ base: '95%', lg: '1023px' }}
          marginX="auto"
          marginY="2rem"
          gap="1rem"
        >
          {regUsers?.map((regUser: User) => (
            <Box
              borderRadius="8px"
              bg="#222"
              boxShadow="rgba(100, 100, 111, 0.2) 0px 4px 16px 0px"
              overflowX="scroll"
              flexWrap="nowrap"
              width="95%"
              marginX="auto"
              scrollBehavior="smooth"
              textAlign="center"
              padding="1rem"
            >

              <Text
                className="small-text small-text-white"
              >
                {regUser.username}
              </Text>
              <Text
                className="small-text small-text-white"
              >
                {regUser.email}
              </Text>
              <CustomButton
                label="Delete User"
                testId={`delete_user_${regUser.username}`}
                buttonColor="night"
                buttonProps={{
                  onClick: (() => {
                    setDeleteCandidate(regUser.username);
                    onOpenDeleteModal();
                  }),
                  id: `delete_user_${regUser.username}`,
                }}
                spinnerId={undefined}
                buttonType="button"
                size="small"
              />
            </Box>
          ))}
        </SimpleGrid>
        <CustomPagination
          pageCount={pageCount}
          startingPage={startingPage}
          handlePageClick={handlePageClick}
        />
      </Box>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent
          bg="#222"
          color="#FFF"
        >
          <ModalHeader
            className="medium-text medium-text-white"
          >
            Unregister User
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              className="small-text small-text-white"
            >
              Are you sure you want to unregister user?
            </Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="Cancel"
              testId="delete_user_cancel"
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseDeleteModal();
                }),
                id: 'delete_user_cancel',
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Submit"
              testId="delete_user_submit"
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  unRegisterUser();
                }),
                id: 'delete_user_submit',
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
