/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { compressAccurately } from 'image-conversion';
import {
  Box,
  Flex,
  Spinner,
  Text,
  Stack,
  Button,
  Avatar,
  Link,
  Alert,
  AlertIcon,
  AlertDescription,
  Tabs,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import heic2any from 'heic2any';
import { AxiosRequestConfig } from 'axios';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import FriendList from '../../components/FriendsList/FriendsList';
import { UserBasic } from '../../_interfaces/User';
import { setMyBooks } from '../../_reducers/userSlice';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import './Account.scss';
import BookList from './BookList';

const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

export default function Account() {
  const dispatch = useDispatch();
  const [userLoading, setUserLoading] = useState(false);
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [avatarImg, setAvatarImg] = useState<File & { preview: string; }>();
  const [isPaidUser, setIsPaidUser] = useState<boolean>();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const user = useSelector((state: UserBasic) => state.user.userData);
  const itemsPerPage = 8;

  const convertHeicImages = async (file: Blob | MediaSource) => {
    const filePath = URL.createObjectURL(file);
    const res = await fetch(filePath);
    const blob = await res.blob();
    const conversionResult = await heic2any({ blob, toType: 'image/png' }) as BlobPart;
    const newFile = new File([conversionResult], `${nanoid()}.png`, {
      type: 'image/png',
    });
    return newFile;
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/*', '.HEIC', '.heic'],
    multiple: false,
    onDrop: async (acceptedFile:any) => {
      // console.log(acceptedFile);
      const extension = acceptedFile[0].path.split('.').pop();
      // console.log(extension);
      if (extension === 'heic' || extension === 'HEIC') {
        const imageFile = await convertHeicImages(acceptedFile[0]);
        setAvatarImg(
          Object.assign(imageFile, { preview: URL.createObjectURL(imageFile) }),
        );
      } else {
        setAvatarImg(
          Object.assign(acceptedFile[0], { preview: URL.createObjectURL(acceptedFile[0]) }),
        );
      }
    },
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    // const extension = avatarImg.path.split('.').pop();
    // console.log("location: " + avatarImg.location);
    const options = {
      size: 400,
      accuracy: 1,
      scale: 0.2,
    };
    compressAccurately(avatarImg as Blob, options)
      .then(async (result) => {
        // console.log(result)
        const newFile = new File([result], avatarImg?.name || '');
        // console.log(newFile);
        formData.append('file', newFile);
        formData.append('username', user.username);
        setUserLoading(true);
        const config = {
          header: { 'content-type': 'multipart/form-data' },
        };
        try {
          await axios.post('/api/image/updateUserImage', formData, config as AxiosRequestConfig<FormData>);
          window.location.reload();
        } catch (err:any) {
          // TODO: Handle exception
          setFormErrorMessage(err.message);
        }
      })
      .catch((err) => {
        setFormErrorMessage(err.message);
      });
  };
  return (
    <Box
      className="account-container"
    >
      <Navbar />
      <Box marginTop="4em" minHeight="90vh">
        {userLoading
          ? (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text fontSize="2.5rem">Updating</Text>
            </Flex>
          )
          : (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Box {...getRootProps({ style: dropzoneStyle })}>
                <input {...getInputProps()} />
                <Box
                  display={{ base: 'none', lg: 'block' }}
                >
                  <Avatar
                    size="2xl"
                    src={avatarImg?.preview ?? user?.image}
                  />
                </Box>
                <Box
                  display={{ base: 'block', lg: 'none' }}
                >
                  <Avatar
                    size="xl"
                    src={avatarImg?.preview ?? user?.image}
                  />
                </Box>
              </Box>
              <Text
                fontFamily="PtSerif"
                fontWeight="300"
                marginY="5px"
                fontSize="2.5rem"
              >
                {user?.username}
              </Text>
              <Stack isInline>
                <Button
                  color="#FFF"
                  onClick={onSubmit}
                  display="block"
                  isDisabled={!avatarImg}
                  width={{ base: '90px', md: '110px' }}
                  borderRadius="24px"
                  bg="#FF1A75"
                  fontSize="1.6rem"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Update
                </Button>
              </Stack>
            </Flex>
          )}
        <Box>
          {
            isPaidUser === false
              ? (
                <Box
                  width={{ base: '95%', md: '50%' }}
                  marginX="auto"
                  marginTop="2em"
                >
                  <Alert status="info" borderRadius="5px" display="flex" justifyContent="center">
                    <AlertIcon />
                    <AlertDescription fontSize={{ base: '0.9em', md: '1.2em' }}>
                      Please subscribe
                      {' '}
                      <Link
                        href="/subscription"
                        textDecor="underline"
                      >
                        here
                      </Link>
                      {' '}
                      if you would like to access your saved books, highlights and notes
                    </AlertDescription>
                  </Alert>
                </Box>
              ) : <Box />
            }
        </Box>
        <Tabs
          variant="unstyled"
          paddingY="4rem"
          align="center"
        >
          <TabList
            width={{ base: '90%', lg: '90%', xl: '75%' }}
            marginX="auto"
          >
            <Tab
              _selected={{ borderBottom: '2px solid #FFF' }}
              className="medium-text medium-text-bold"
            >
              Books
            </Tab>
            <Tab
              _selected={{ borderBottom: '2px solid #FFF' }}
              className="medium-text medium-text-bold"
            >
              Friends
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <BookList />
            </TabPanel>
            <TabPanel>
              <FriendList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
