import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import CustomButton from '../../components/CustomButton/CustomButton';
import './SettingsPage.scss';

interface User {
  user: {
    userData: {
      username: string,
    }
  }
}

interface Device {
  deviceName: string,
  deviceId: string
}

function Settings() {
  const user = useSelector((state:User) => state.user.userData);
  const [devices, setDevices] = useState<Device[]>([]);
  const getUserSettings = () => {
    axios({
      url: '/api/user/userSettings',
      method: 'POST',
      data: {
        username: user.username,
      },
    })
      .then((res) => {
        const { deviceList } = res.data;
        setDevices(deviceList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (user.username) {
      getUserSettings();
    }
  }, [user]);
  const logoffDevice = (deviceId: string) => {
    axios({
      url: '/api/user/logoffDevice',
      method: 'PUT',
      data: {
        username: user.username,
        deviceId,
      },
    })
      .then((res) => {
        const { deviceList } = res.data;
        setDevices(deviceList);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const renderUserDevices = () => devices?.map((device: Device) => (
    <Box
      padding="2rem"
      borderRadius="8px"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 4px 16px 0px"
      overflowX="scroll"
      flexWrap="nowrap"
      scrollBehavior="smooth"
      textAlign="left"
      bg="#222"
    >
      <Box>
        <Text
          className="small-text small-text-white"
          marginY="1rem"
        >
          Device Name:
          {' '}
          <b style={{ color: 'blue.200' }}>{device.deviceName}</b>
        </Text>
        <Text
          className="small-text small-text-white"
          marginY="1rem"
        >
          Device Id:
          {' '}
          <b>{device.deviceId}</b>
        </Text>
        <br />
        <CustomButton
          label="Logoff Device"
          buttonColor="night"
          testId={`remove_device_${device.deviceId}`}
          spinnerId={undefined}
          buttonType="button"
          buttonProps={{
            onClick: () => logoffDevice(device.deviceId),
          }}
          size="small"
        />
      </Box>
    </Box>
  ));

  return (
    <Box
      className="settings-page-container"
      as="section"
    >
      <Navbar />
      <Box
        minH="90vh"
      >
        <Text
          as="h1"
          paddingY="3rem"
          className="large-text large-text-white"
        >
          Login Device Listing
        </Text>
        <SimpleGrid
          gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
          width={{ base: '95%', lg: '1023px' }}
          marginX="auto"
          rowGap="2rem"
          columnGap="2rem"
        >
          {renderUserDevices()}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default Settings;
