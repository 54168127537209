/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { axiosPrivate } from '../api/axios';
import { User, Friend } from '../_interfaces/User';
import { BookListItem } from '../_interfaces/Books';

const initState = {
  userData: {
    _id: '',
    username: '',
    userId: '',
    roles: [],
    trailDays: 0,
    trialEndMsg: false,
    trialMode: false,
    trialStartMsg: false,
    image: '',
    isPaid: false,
  },
  friendData: {
    friendList: [] as Friend [],
    pageCount: 0,
    numFriends: 0,
    itemOffset: 0,
  },
  userBooks: {
    myBookList: [] as BookListItem [],
    pageCount: 0,
    numBooks: 0,
    itemOffset: 0,
  },
};
const BASE_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:5000'
  : 'https://theologianspen.herokuapp.com/';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({

  }),
});
const checkUser = (user: User) => {
  if (user?.roles.includes('2023')
  || user?.roles.includes('2024')
  || user?.roles.includes('2025')
  || user?.roles.includes('2026')
  ) {
    return true;
  }
  return false;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    updateUser: (state, action) => {
      const userData = action.payload;
      console.log(userData);
      state.userData = userData;
      state.userData.isPaid = checkUser(action.payload);
    },
    loginUser: (state, action) => {
      axiosPrivate
        .post('/api/user/loginUser', action.payload.dataToSubmit)
        .then((response) => response);
    },
    refreshUser: (state, action) => {
      // console.log(action.payload)
      const extraHeaders = action.payload;
      axiosPrivate({
        headers: extraHeaders,
        url: '/api/token/refreshToken',
        method: 'GET',
      })
        .then((res) => res.data)
        .catch((err) => err);
    },
    checkUserAccess: (state, action) => {
      const extraHeaders = action.payload;
      axiosPrivate({
        headers: extraHeaders,
        url: '/api/middleware/checkAccessToken',
        method: 'GET',
      })
        .then((res) => res.data)
        .catch((err) => err);
    },
    setMyBooks: (state, action) => {
      state.userBooks.myBookList = action.payload;
    },
    setBookData: (state, action) => {
      state.userBooks.itemOffset = action.payload.itemOffset;
      state.userBooks.numBooks = action.payload.numBooks;
      state.userBooks.pageCount = action.payload.pageCount;
    },
    setFriendList: (state, action) => {
      state.friendData.friendList = action.payload;
    },
    setFriendData: (state, action) => {
      state.friendData.itemOffset = action.payload.itemOffset;
      state.friendData.numFriends = action.payload.numFriends;
      state.friendData.pageCount = action.payload.pageCount;
    },
  },
});

export const {
  updateUser,
  loginUser,
  refreshUser,
  checkUserAccess,
  setFriendList,
  setMyBooks,
  setBookData,
  setFriendData,
} = userSlice.actions;

export default userSlice.reducer;
