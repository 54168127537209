/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box,
  Link,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import './Navbar.scss';
import MainLogo from '../../../../components/Logos/MainLogo/MainLogo';

export default function NavbarLanding() {
  return (
    <Box
      as="header"
      width="100%"
      className="header"
      bg="#000"
    >
      <MainLogo theme="light" />
      <Box
        display={{ base: 'Flex', md: 'Flex' }}
        gap="1rem"
      >
        <Link
          className="link-btn link-btn-small link-btn-night"
          href="/"
          data-testid="learn-more-btn"
        >
          Learn More
        </Link>
      </Box>
      <Menu>
        <MenuButton
          display={{ base: 'none', md: 'none' }}
          aria-label="menu none"
          className="hamburger-menu-test"
          bg="#111"
          color="#FFF"
        >
          <hr />
          <hr />
          <hr />
        </MenuButton>
        <MenuList
          display={{ base: 'none', md: 'none' }}
          className="menu-list"
          bg="#333 !important"
        >
          <Link
            data-testid="mobile-learn-more-btn"
            className="link-btn link-btn-night"
            href="/"
          >
            Learn More
          </Link>
        </MenuList>
      </Menu>
    </Box>
  );
}
