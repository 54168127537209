/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Spinner,
  Button,
  Flex,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  useColorModeValue,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios, { axiosPrivate } from '../../../api/axios';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { BookListItem } from '../../../_interfaces/Books';

export default function SeriesEdit(props: {
  seriesId: string,
  coverFileName: string,
  name: string,
  author: string,
  description: string,
  getSeries: () => void,
}) {
  const {
    seriesId,
    coverFileName,
    name,
    author,
    description,
    getSeries,
  } = props;
  const [allBooks, setAllBooks] = useState<BookListItem []>([]);
  const [numBooks, setNumBooks] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [retrievedBooks, setRetrievedBooks] = useState(false);
  const [allSeriesBooks, setAllSeriesBooks] = useState<BookListItem []>([]);
  const [selectedBooks, setSelectedBooks] = useState<string []>([]);
  const [seletedBookCount, setSelectedBookCount] = useState<number>();
  const {
    isOpen: isOpenDeleteSeries,
    onOpen: onOpenDeleteSeries,
    onClose: onCloseDeleteSeries,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleteCover,
    onOpen: onOpenDeleteCover,
    onClose: onCloseDeleteCover,
  } = useDisclosure();
  const {
    isOpen: isOpenEditSeries,
    onOpen: onOpenEditSeries,
    onClose: onCloseEditSeries,
  } = useDisclosure();
  const {
    isOpen: isOpenBookSelector,
    onOpen: onOpenBookSelector,
    onClose: onCloseBookSelector,
  } = useDisclosure();
  const {
    isOpen: isOpenBookRemoval,
    onOpen: onOpenBookRemoval,
    onClose: onCloseBookRemoval,
  } = useDisclosure();
  const booksPerPage = 16;
  const startingPage = 0;
  const bg = useColorModeValue('#EEE', '#111');
  const toggleColor = useColorModeValue('#111', '#EEE');
  const buttonBg = useColorModeValue('#DDD', '#222');
  const buttonToggle = useColorModeValue('#222', '#DDD');
  const buttonHover = useColorModeValue('#CCC', '#333');

  const deleteCoverImage = () => {
    axiosPrivate({
      method: 'DELETE',
      url: '/api/bookseries/deleteBookSeriesCover',
      data: {
        coverFileName,
        seriesId,
      },
    })
      .then((res) => {
        if (res.status === 200) window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const deleteBookSeries = () => {
    axiosPrivate({
      method: 'DELETE',
      url: '/api/bookseries/deleteBookSeries',
      data: {
        seriesId,
        coverFileName,
      },
    })
      .then((res) => {
        if (res.status === 200) window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const openOriginal = () => {
    window.open(`/bookseries/${seriesId}`, '_blank');
  };
  const handlePageClick = () => {
    axios({
      url: '/api/book/indexLiveBooks',
      method: 'POST',
      data: {
        offsetIndex: '',
        booksPerPage,
      },
    })
      .catch((errRes) => {
        // eslint-disable-next-line no-console
        console.log(errRes);
      });
  };
  const retrieveBooksBySeries = () => {
    axios({
      url: '/api/bookseries/booksBySeries',
      method: 'POST',
      data: {
        seriesId,
      },
    })
      .then((res) => {
        const { books } = res.data;
        setAllSeriesBooks(books);
      })
      .catch((errRes) => {
        // eslint-disable-next-line no-console
        console.log(errRes);
      });
  };
  useEffect(() => {
    axios({
      url: '/api/book/indexLiveBooks',
      method: 'POST',
      data: {
        offsetIndex: 0,
        booksPerPage,
      },
    })
      .then((res) => {
        const { books, bookCount } = res.data;
        setNumBooks(bookCount);
        setAllBooks(books);
        setPageCount(Math.ceil(bookCount / booksPerPage));
        setRetrievedBooks(true);
        retrieveBooksBySeries();
      })
      .catch((errRes) => {
        // eslint-disable-next-line no-console
        console.log(errRes);
      });
  }, []);
  const updateSeries = () => {
    // eslint-disable-next-line no-console
    console.log(selectedBooks);
    if (selectedBooks.length === 0) return;
    axios({
      url: '/api/bookseries/updateBookIds',
      method: 'PUT',
      data: {
        seriesId,
        bookIds: selectedBooks,
      },
    })
      .then((res) => {
        const { updatedSeriesBooks } = res.data;
        setAllSeriesBooks(updatedSeriesBooks);
        setSelectedBooks([]);
        onCloseBookSelector();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const removeFromSeries = () => {
    // eslint-disable-next-line no-console
    console.log(selectedBooks);
    if (selectedBooks.length === 0) return;
    axios({
      url: '/api/bookseries/removeBookIds',
      method: 'PUT',
      data: {
        seriesId,
        bookIds: selectedBooks,
      },
    })
      .then((res) => {
        const { updatedSeriesBooks } = res.data;
        setAllSeriesBooks(updatedSeriesBooks);
        setSelectedBooks([]);
        onCloseBookRemoval();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const toggleImage = (bookId: string) => {
    const firstBookCount = selectedBooks.length;
    const updatedSelected = selectedBooks.filter((id) => id !== bookId);
    const bookForm = document.getElementById(`bookform_${bookId}`);
    const bookRemoval = document.getElementById(`bookremoval_${bookId}`);
    if (updatedSelected.length !== firstBookCount) {
      // console.log('help');
      // eslint-disable-next-line no-console
      console.log(updatedSelected.length);
      setSelectedBooks(updatedSelected);
      setSelectedBookCount(updatedSelected.length);
      if (bookForm) {
        bookForm.style.border = '';
      }
      if (bookRemoval) {
        bookRemoval.style.border = '';
      }
    } else {
      const updatedBooks = selectedBooks;
      updatedBooks.push(bookId);
      setSelectedBooks(updatedBooks);
      setSelectedBookCount(updatedBooks.length);
      if (bookForm) {
        bookForm.style.border = '1px solid blue';
      }
      if (bookRemoval) {
        bookRemoval.style.border = '1px solid blue';
      }
    }
    // console.log(selectedBooks);
  };
  const renderBooksForm = () => allBooks.map((book) => (
    <Flex
      id={`bookform_${book._id}`}
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      padding="10px"
      marginX="auto"
    >
      {book?.coverImage
        ? (
          <Image
            cursor="pointer"
            borderRadius="5px"
            src={book.coverImage}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
            }}
            onClick={() => { toggleImage(book._id); }}
          />
        ) : (
          <Flex
            backgroundColor="#1F1F1F"
            color="#FFF"
            borderRadius="5px"
            justifyContent="center"
            flexDir="column"
            textAlign="center"
            paddingX="5px"
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
            }}
          >
            <Text
              fontSize="16px"
              fontFamily="PTSerif"
            >
              {book.title}
            </Text>
            <Text
              marginTop="10px"
              fontSize="14px"
              fontFamily="PTSerif"
            >
              {book.author}
            </Text>
          </Flex>
        )}
    </Flex>
  ));
  const renderRemovalForm = () => allSeriesBooks.map((book) => (
    <Flex
      id={`bookremoval_${book._id}`}
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      padding="10px"
      marginX="auto"
      onClick={() => { toggleImage(book._id); }}
    >
      {book?.coverImage
        ? (
          <Image
            cursor="pointer"
            borderRadius="5px"
            src={book.coverImage}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
            }}
          />
        ) : (
          <Flex
            backgroundColor="#1F1F1F"
            color="#FFF"
            borderRadius="5px"
            justifyContent="center"
            flexDir="column"
            textAlign="center"
            paddingX="5px"
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
            }}
          >
            <Text
              fontSize="16px"
              fontFamily="PTSerif"
            >
              {book.title}
            </Text>
            <Text
              marginTop="10px"
              fontSize="14px"
              fontFamily="PTSerif"
            >
              {book.author}
            </Text>
          </Flex>
        )}
    </Flex>
  ));
  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          name,
          author,
          description,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Must include a name'),
          author: Yup.string()
            .required('Must include an author'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          axiosPrivate({
            method: 'PUT',
            url: '/api/bookseries/seriesMetaData',
            data: {
              seriesId,
              name: values.name,
              author: values.author,
              description: values.description,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                alert('Updated book series metada successfully!');
                getSeries();
                onCloseEditSeries();
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props:any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
            >

              <Field name="name">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="title"
                      ml="4px"
                      fontWeight="450"
                    >
                      Name
                    </FormLabel>

                    <Input
                      id="name"
                      placeholder="Add a name to your series"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="author">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="author"
                      ml="4px"
                      fontWeight="450"
                    >
                      Author
                    </FormLabel>

                    <Input
                      id="author"
                      placeholder="Add a author for the book series"
                      value={values.author}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="description"
                      ml="4px"
                      fontWeight="450"
                    >
                      Description (Optional)
                    </FormLabel>

                    <Textarea
                      fontFamily="PtSerif"
                      id="description"
                      placeholder="Add a series description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  fontFamily="PtSerif"
                  fontSize="18px"
                  type="button"
                  onClick={onCloseEditSeries}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="10px"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  fontFamily="PtSerif"
                  fontSize="18px"
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="10px"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );

  return (
    <Box>
      <Menu>
        <MenuButton
          className="link-btn link-btn-night link-btn-small"
          marginTop="1rem"
          marginBottom="2rem"
        >
          Settings
        </MenuButton>
        <MenuList
          zIndex="9999"
          bg="#222"
          border="1px solid #888"
        >
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={openOriginal}
          >
            Go to Series
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenEditSeries}
          >
            Edit Series
          </MenuItem>
          {coverFileName
            ? (
              <MenuItem
                className="link-btn link-btn-night link-btn-small"
                onClick={onOpenDeleteCover}
              >
                Remove Cover
              </MenuItem>
            ) : <Box />}
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenDeleteSeries}
          >
            Delete Series
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenBookSelector}
          >
            Add Books
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenBookRemoval}
          >
            See/Remove Books
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenDeleteCover} onClose={onCloseDeleteCover}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="medium-text medium-text-white">Delete Series Cover</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to Delete this series cover?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              mr={3}
              onClick={onCloseDeleteCover}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              onClick={deleteCoverImage}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteSeries} onClose={onCloseDeleteSeries}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="medium-text medium-text-white">Delete Book Series</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to delete this book series?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              mr={3}
              onClick={onCloseDeleteSeries}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              onClick={deleteBookSeries}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenEditSeries} onClose={onCloseEditSeries}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Book</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenBookSelector}
        onClose={() => { onCloseBookSelector(); setSelectedBooks([]); }}
      >
        <ModalOverlay />
        <ModalContent
          // width={{ base: '90vw' }}
          maxW="90%"
          paddingY="10px"
          bg={bg}
          color={toggleColor}
        >
          <ModalHeader>
            Select books to add into
            {' '}
            <i>
              {name}
            </i>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => { onCloseBookSelector(); setSelectedBooks([]); }}
            _focus={{ outline: 'none' }}
          />
          <ModalBody>
            <SimpleGrid
              marginTop="20px"
              gridTemplateColumns={{
                base: '1fr 1fr 1fr 1fr',
                lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              }}
              width={{ base: '90%', lg: '90%', xl: '85%' }}
              gridGap={{ base: '10px', md: '15px' }}
              marginX="auto"
            >
              {renderBooksForm()}
            </SimpleGrid>
            <CustomPagination
              pageCount={pageCount}
              startingPage={startingPage}
              handlePageClick={handlePageClick}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              bg={buttonBg}
              color={buttonToggle}
              _hover={{ bg: `${buttonHover}` }}
              _focus={{ outline: 'none' }}
              mr={3}
              onClick={() => { onCloseBookSelector(); setSelectedBooks([]); }}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              bg={buttonBg}
              color={buttonToggle}
              _hover={{ bg: `${buttonHover}` }}
              _focus={{ outline: 'none' }}
              onClick={() => { updateSeries(); }}
            >
              Add +
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenBookRemoval}
        onClose={() => { onCloseBookRemoval(); setSelectedBooks([]); }}
      >
        <ModalOverlay />
        <ModalContent
          // width={{ base: '90vw' }}
          maxW="90%"
          paddingY="10px"
          bg={bg}
          color={toggleColor}
        >
          <ModalHeader>
            Select books to remove from
            {' '}
            <i>
              {name}
            </i>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => { onCloseBookRemoval(); setSelectedBooks([]); }}
            _focus={{ outline: 'none' }}
          />
          <ModalBody>
            <SimpleGrid
              marginTop="20px"
              gridTemplateColumns={{
                base: '1fr 1fr 1fr 1fr',
                lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              }}
              width={{ base: '90%', lg: '90%', xl: '85%' }}
              gridGap={{ base: '10px', md: '15px' }}
              marginX="auto"
            >
              {renderRemovalForm()}
            </SimpleGrid>
            <CustomPagination
              pageCount={pageCount}
              startingPage={startingPage}
              handlePageClick={handlePageClick}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              bg={buttonBg}
              color={buttonToggle}
              _hover={{ bg: `${buttonHover}` }}
              _focus={{ outline: 'none' }}
              mr={3}
              onClick={() => { onCloseBookRemoval(); setSelectedBooks([]); }}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              bg={buttonBg}
              color={buttonToggle}
              _hover={{ bg: `${buttonHover}` }}
              _focus={{ outline: 'none' }}
              onClick={() => { removeFromSeries(); }}
            >
              Remove -
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
