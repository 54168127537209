/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  Box,
  Textarea,
  Flex,
  Button,
  Text,
  Avatar,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import CustomPagination from '../../CustomPagination/CustomPagination';
import LoadinngSpinner from '../../LoadingSpinner/LoadingSpinner';
import ReplyComments from './sections/ReplyComments';
import { UserBasic } from '../../../_interfaces/User';

interface BookState {
  book: {
    currentBook: {
      bookId: string,
    }
  }
}

export default function Comments() {
  const {
    userId,
    image,
    username,
    isPaid,
  } = useSelector((state: UserBasic) => state.user.userData);
  const { bookId } = useSelector((state: BookState) => state.book.currentBook);
  const [commentContent, setCommentContent] = useState<any>();
  const [bookComments, setBookComments] = useState<any>([]);
  const [updatingComments, setUpdatingComments] = useState<any>(false);
  const bgColor = useColorModeValue('#DDD', '#222');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const [commentCount, setCommentCount] = useState<any>(0);
  const [startingPage, setStartingPage] = useState<any>(0);
  const [pageCount, setPageCount] = useState<any>();
  const itemsPerPage = 12;
  const itemOffset = 0;

  const getComments = () => {
    axios({
      method: 'POST',
      url: '/api/comments/getCommentsOriginal',
      withCredentials: true,
      data: {
        bookId,
        reponseTo: null,
        offSetIndex: itemOffset,
        itemsPerPage,
      },
    })
      .then((res) => {
        const { comments, commentsCount } = res.data;
        setBookComments(comments);
        setPageCount(Math.ceil(commentsCount / itemsPerPage));
        setCommentCount(commentsCount);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
        // navigate('/login');
      });
  };
  useEffect(() => {
    if (bookId) {
      getComments();
    }
  }, [bookId]);
  const saveNote = () => {
    // console.log(currentNoteId);
    // console.log(currentNote);
    // console.log(bookId);
    // console.log(user.username);
    if (!commentContent) return;
    // setUpdatingComments(true);
    axios({
      method: 'POST',
      url: '/api/comments/saveCommentOriginal',
      data: {
        userId,
        content: commentContent,
        bookId,
      },
    })
      .then((res) => {
        const { newDate, commentId } = res.data;
        // console.log(savedComment);
        // console.log(bookComments);
        const updatedBooks = bookComments;
        updatedBooks.unshift({
          _id: commentId,
          writer: {
            username,
            image,
          },
          bookId,
          content: commentContent,
          createdAt: newDate,
        });
        // console.log(updatedBooks);
        setBookComments(updatedBooks);
        setCommentContent(undefined);
        const commentElement:any = document.getElementById('comment-textarea');
        if (commentElement?.value) {
          commentElement.value = '';
        }
        // setUpdatingComments(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
      });
  };
  const handlePageClick = (event:any) => {
    if (bookComments?.length > 0) {
      setUpdatingComments(true);
      const newOffset = (event.selected * itemsPerPage) % commentCount;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
      // );
      axios({
        method: 'POST',
        url: '/api/comments/getCommentsOriginal',
        data: {
          bookId,
          reponseTo: null,
          itemsPerPage,
          offSetIndex: newOffset,
        },
      })
        .then((res) => {
          // eslint-disable-next-line no-console
          // console.log(res.data.books);
          const { comments, commentsCount } = res.data;
          // const endOffset = itemOffset + itemsPerPage;
          // console.log(`Loading items from ${itemOffset} to ${newOffset}`);
          // setBooksDisplayed(books.slice(itemOffset, endOffset));
          // setPageCount(Math.ceil(bookCount / itemsPerPage));
          // setItemOffset(newOffset);
          setBookComments(comments);
          setUpdatingComments(false);
          // setLoadingBooks(false);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  };
  const getDate = (dateString:any) => {
    const newDate = new Date(dateString);
    return newDate.toDateString();
  };
  const renderComments = () => bookComments?.map((comment:any) => (
    <Box
      marginTop="2em"
    >
      <Box paddingTop="1px">
        <Flex
          gap="10px"
        >
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Avatar
              size="sm"
              src={comment.writer.image}
              filter="brightness(0.9)"
              _hover={{
                filter: 'brightness(1.1)',
              }}
            />
          </Link>
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              fontWeight="500"
              color={toggleColor}
            >
              {comment.writer.username}
            </Text>
          </Link>
          <Text
            marginTop="3px"
            fontSize={{ base: '10px', md: '12px' }}
            fontWeight="200"
            color={toggleColor}
          >
            {getDate(comment.createdAt)}
          </Text>
        </Flex>
        <Box
          paddingLeft="44px"
        >
          <Text
            fontSize={{ base: '10px', md: '14px' }}
            color={toggleColor}
          >
            {comment.content}
          </Text>
        </Box>
        <Box
          paddingLeft="44px"
        >
          <ReplyComments
            commentId={comment._id}
            userId={userId}
            username={username}
            userImage={image}
            bookId={bookId}
          />
        </Box>
      </Box>
    </Box>
  ));
  return (
    <Box
      maxW="1200px"
      width="90%"
      marginX="auto"
      paddingX={{ base: '10px', md: '4em' }}
      bg={bgColor}
      paddingY={{ base: '1em', md: '4em' }}
      paddingBottom="4rem"
      borderRadius="5px"
    >
      <Box>
        <Box>
          <Text
            fontSize={{ base: '16px', md: '20px' }}
          >
            Comments
          </Text>
        </Box>
        <Box>
          <Flex
            justifyContent="center"
            marginTop="2em"
          >
            <Textarea
              id="comment-textarea"
              borderRadius="5px"
              marginX="auto"
              _focus={{ outlineColor: '#DDD' }}
              onChange={(event) => {
                setCommentContent(event.target.value);
              }}
              // value={Comment}
              placeholder="write some comments"
              fontSize="1.6rem"
            />
          </Flex>
          <Flex
            justifyContent="flex-end"
            width={{ base: '100%' }}
            marginX="auto"
            marginTop="10px"
          >
            <Button
              width={{ base: '100px', sm: '100px', md: '150px' }}
              height={{ base: '42px', sm: '42px', md: '52px' }}
              onClick={() => { saveNote(); }}
              _focus={{ outline: 'none' }}
              fontSize={{ base: '12px', md: '16px' }}
            >
              Submit
            </Button>
          </Flex>
        </Box>
        <Box>
          {updatingComments ? <LoadinngSpinner />
            : (
              <Box>
                {renderComments()}
              </Box>
            )}
        </Box>
        <CustomPagination
          handlePageClick={handlePageClick}
          startingPage={startingPage}
          pageCount={pageCount}
        />
      </Box>
    </Box>
  );
}
