import React from 'react';
import {
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from '../Footer';
import TermsMenu from './TermsMenu';
import Header from './Header';

function Cookies() {
  const bg = useColorModeValue('#EEE', '#EEE');
  const toggleColor = useColorModeValue('#222', '#222');
  return (
    <Box
      bg={bg}
      color={toggleColor}
    >
      <Header />
      <TermsMenu />
      <Box
        width={{
          base: '95%', sm: '90%', md: '80%', lg: '70%', xl: '50%',
        }}
        marginX="auto"
        paddingY="2em"
        minH="750px"
      >
        <Text
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Cookies
        </Text>
      </Box>
    </Box>
  );
}

export default Cookies;
