/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box,
  Link,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import './NavbarLanding.scss';
import MainLogo from '../Logos/MainLogo/MainLogo';

export default function NavbarLanding() {
  const whiteBackground = useColorModeValue('#FFF', '#FFF');
  return (
    <Box
      as="section"
      aria-label="header section"
      width="100%"
      className="header"
      bg={whiteBackground}
    >
      <MainLogo theme="dark" />
      <Box
        display={{ base: 'none', md: 'Flex' }}
        gap="1rem"
      >
        <Link
          className="link-btn link-btn-medium link-btn-black"
          href="/register"
          data-testid="signup-button"
        >
          Register
        </Link>
        <Link
          className="link-btn link-btn-medium link-btn-white"
          href="/login"
          data-testid="login-button"
        >
          Sign In
        </Link>
      </Box>
      <Menu>
        <MenuButton
          display={{ base: 'block', md: 'none' }}
          aria-label="menu option"
          className="hamburger-menu"
        >
          <hr />
          <hr />
          <hr />
        </MenuButton>
        <MenuList
          display={{ base: 'block', md: 'none' }}
          className="menu-list"
        >
          <Box
            as="a"
            className="link-btn link-btn-medium link-btn-white"
            href="/register"
          >
            Register
          </Box>
          <Box
            as="a"
            className="link-btn link-btn-medium link-btn-white"
            href="/login"
          >
            Sign In
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
}
