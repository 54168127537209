/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { compressAccurately } from 'image-conversion';
import {
  Box,
  useColorModeValue,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  TabIndicator,
} from '@chakra-ui/react';
import heic2any from 'heic2any';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import FriendsSearch from './sections/FriendsSearch';
import DiscoverFriends from './sections/DiscoverFriends';
import PendingRequests from './sections/PendingRequests';
import SentRequests from './sections/SentRequests';
import { UserBasic } from '../../_interfaces/User';
import FriendList from '../../components/FriendsList/FriendsList';
import './Friends.scss';

export default function Friends() {
  const [isPaidUser, setIsPaidUser] = useState<boolean>();
  const [newSentRequestId, setNewSentRequestsId] = useState<string>();
  const [newFriendId, setNewFriendId] = useState<string>();
  const [myBooks, setMyBooks] = useState();
  const user = useSelector((state:UserBasic) => state.user.userData);
  const bg = useColorModeValue('#EEE', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const itemsPerPage = 8;
  const checkUser = () => {
    if (user?.roles?.includes('2023')
    || user?.roles?.includes('2024')
    || user?.roles?.includes('2025')
    ) {
      setIsPaidUser(true);
      return true;
    }
    setIsPaidUser(false);
    return false;
  };
  const addToRequests = (addedUserId: string) => {
    // console.log('adding user');
    setNewSentRequestsId(addedUserId);
  };
  const addToFriends = (addedUserId: string) => {
    // console.log('adding user');
    setNewFriendId(addedUserId);
  };
  return (
    <Box
      as="main"
      className="friends-container"
    >
      <Navbar />
      <Box
        marginTop="4em"
        minHeight="90vh"
        paddingBottom="4em"
        marginX="auto"
      >
        <Box>
          <FriendsSearch
            addToRequests={addToRequests}
          />
        </Box>
        <Box>
          <DiscoverFriends
            addToRequests={addToRequests}
          />
        </Box>
        <Tabs
          variant="unstyled"
          marginY="4rem"
          align="center"
        >
          <TabList
            marginX="auto"
          >
            <Tab
              _selected={{ borderBottom: '2px solid #FFF' }}
              className="medium-text medium-text-bold"
            >
              Friends
            </Tab>
            <Tab
              _selected={{ borderBottom: '2px solid #FFF' }}
              className="medium-text medium-text-bold"
            >
              Requests
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FriendList />
            </TabPanel>
            <TabPanel>
              <PendingRequests
                addToFriends={addToFriends}
              />
              <SentRequests
                newSentRequestId={newSentRequestId || ''}
                addToRequests={addToRequests}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
