/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Box,
  Input,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import axios from '../../api/axios';
import NavbarLanding from '../../components/NavbarLanding/NabarLanding';
import { User } from '../../_interfaces/User';
import CustomButton from '../../components/CustomButton/CustomButton';

export default function ForgotPassword() {
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [foundUser, setFoundUser] = useState<User>();
  const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const sendPasswordReset = async () => {
    if (!foundUser?.username) {
      return;
    }
    setSendingPasswordReset(true);
    try {
      const res = await axios.post('/api/password/sendPasswordReset', { username: foundUser?.username });
      if (res.data.success) {
        setResetEmailSent(true);
      }
    } catch (err) {
      // TODO: Handle error
    } finally {
      setSendingPasswordReset(false);
    }
  };

  const renderProfileCard = () => (
    <Flex
      paddingY="2rem"
      paddingX="16px"
      marginTop="16px"
      borderRadius="8px"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      width={{ base: '94%', md: '460px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack isInline>
        <Avatar src={foundUser?.image} size="lg" />
        <Stack spacing={1}>
          <Text className="small-text small-text-black small-text-bold" m="0">{foundUser?.username}</Text>
          <Text className="small-text small-text-black" m="0">{foundUser?.email}</Text>
        </Stack>
      </Stack>
      <Button
        onClick={sendPasswordReset}
        isLoading={sendingPasswordReset}
        loadingText="Submitting..."
        display="block"
        width="160px"
        color="#FFF"
        fontSize="1.6rem"
        height="4rem"
        borderRadius="24px"
        bg="#111"
        _hover={{
          bg: '#333',
        }}
        marginTop={{ base: '1rem', md: '0' }}
      >
        Reset Password
      </Button>
    </Flex>
  );

  if (resetEmailSent) {
    return (
      <Box
        backgroundColor="#EEE"
        color="#111"
      >
        <NavbarLanding />
        <Flex
          justifyContent="center"
          flexDir="column"
          alignItems="center"
          width="100%"
          minHeight="90vh"
          paddingX={{ base: '1rem', md: '0' }}
        >
          <EmailIcon
            w="64px"
            h="64px"
            color="#FF1A75"
          />
          <Text
            fontWeight="350"
            fontSize={{ base: '1.3em', lg: '1.6em' }}
            marginTop="2rem"
            textAlign="center"
          >
            A link to reset your password has been sent to your email.
          </Text>
          <Text
            fontSize={{ base: '1.1em', lg: '1.3em' }}
            fontWeight="350"
            marginTop="2rem"
          >
            You&apos;ll have 24 hours to pick your new password.

          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      backgroundColor="#EEE"
      color="#111"
    >
      <NavbarLanding />
      <Formik
        initialValues={{
          query: '',
        }}
        validationSchema={Yup.object().shape({
          query: Yup.string()
            .required('Email or username is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            try {
              const dataToSubmit = {
                query: values.query,
              };
              const res = await axios.post('/api/password/findUser', dataToSubmit);
              if (res.data.success) {
                setFoundUser(res.data.user);
              }
            } catch (err:any) {
              const errMsg = err.response.status === 404
                ? 'Check that your email address or username is correct'
                : 'Something went wrong';
              setFormErrorMessage(errMsg);
              setFoundUser(undefined);
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            }
            setSubmitting(false);
          }, 500);
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props:any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box>
              <Flex
                justifyContent="center"
                flexDir="column"
                alignItems="center"
                width="100%"
                minHeight="90vh"
              >
                <Text
                  className="large-text large-text-black"
                  marginY="1rem"
                >
                  Find your account
                </Text>
                <Box onSubmit={handleSubmit} width={{ base: '94%', md: '460px' }}>
                  <Field name="query">
                    {() => (
                      <FormControl
                        isRequired
                        isInvalid={errors.query && touched.query}
                        mb="4px"
                      >
                        <FormLabel className="medium-text medium-text-black" htmlFor="query" ml="4px" fontWeight="300">
                          Email address or username
                        </FormLabel>
                        <input
                          id="query"
                          className="input-custom"
                          aria-label="Enter Email or Username"
                          type="email"
                          placeholder="Enter your email address or username"
                          value={values.query}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Box
                          color="red"
                          fontSize="14px"
                          minHeight="21px"
                        >
                          {touched.query && errors.query}
                        </Box>
                      </FormControl>
                    )}
                  </Field>
                  {formErrorMessage && (
                    <p
                      style={{
                        color: '#ff0000bf',
                        fontSize: '0.7rem',
                        border: '1px solid',
                        padding: '1rem',
                        borderRadius: '10px',
                      }}
                    >
                      {formErrorMessage}
                    </p>
                  )}
                </Box>
                <CustomButton
                  buttonType="submit"
                  buttonColor="black"
                  testId="find-acc-button"
                  buttonProps={{
                    id: 'find-acc-button',
                    onClick: handleSubmit,
                    onLoad: isSubmitting,
                  }}
                  label="Find Account"
                  spinnerId="find-account-spinner"
                  size="medium"
                />
                {foundUser && renderProfileCard()}
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
