/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,

} from '@chakra-ui/react';
import { axiosPrivate } from '../../../api/axios';
import { UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

interface Tester {
  username: string,
  email: string,
}

export default function Testusers() {
  const user = useSelector((state: UserBasic) => state.user.userData);
  const [testUsers, setTestUsers] = useState<Tester[]>([]);
  const [testerCandidate, setTesterCandidate] = useState<string>();
  const [removeTester, setRemoveTester] = useState<string>();

  const {
    isOpen: isTesterOpenModal,
    onOpen: onTesterOpenModal,
    onClose: onCloseTesterModal,
  } = useDisclosure();
  const {
    isOpen: isRemoveTesterOpenModal,
    onOpen: onOpenRemoveTesterOpenModal,
    onClose: onCloseRemoveTesterModal,
  } = useDisclosure();

  const addUserAsTester = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'PUT',
      url: '/api/user/addUserAsTester',
      data: { candidate: testerCandidate },
    })
      .then(() => {
        // eslint-disable-next-line no-alert
        alert(`${testerCandidate} is now a test user!`);
        window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const removeUserAsTester = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'PUT',
      url: '/api/user/removeUserAsTester',
      data: { username: removeTester },
    })
      .then(() => {
        // eslint-disable-next-line no-alert
        alert(`${removeTester} is no longer a test user!`);
        window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const getTestUsers = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'GET',
      url: '/api/user/getTestUsers',
    })
      .then((res) => {
        const { users } = res.data;
        // console.log(users);
        setTestUsers(users);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    if (user.username) {
      getTestUsers();
      // getTestUsers();
    }
  }, [user.username]);

  const setTester = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTesterCandidate(event.target.value);
  };
  return (
    <Box
      as="main"
      minH="80vh"
    >
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="300px"
        bg="#222"
        width={{ base: '95%', lg: '1023px' }}
        marginX="auto"
        marginY="2rem"
        padding="2rem"
      >
        <Text
          className="medium-text medium-text-white"
        >
          Enter username or email to become a tester
        </Text>
        <Input
          color="#000"
          width={{ base: '250px', lg: '400px' }}
          marginX="auto"
          style={{ color: '#FFF' }}
          placeholder="Username"
          border="0"
          outline="0"
          _focus={{ outline: '0' }}
          backgroundColor="#555"
          marginBottom="2rem"
          onChange={setTester}
        />
        <CustomButton
          label="Submit"
          testId={`set_tester_${testerCandidate}`}
          buttonColor="night"
          buttonProps={{
            onClick: (() => {
              if (testerCandidate) {
                onTesterOpenModal();
              }
            }),
            id: `set_tester_${testerCandidate}`,
          }}
          spinnerId={undefined}
          buttonType="button"
          size="small"
        />
      </Flex>
      <Box>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          height="100px"
        >
          <Text
            className="medium-text medium-text-white"
          >
            Test User Registry
          </Text>
        </Flex>
        <SimpleGrid
          gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
          width={{ base: '95%', lg: '1023px' }}
          marginX="auto"
          gap="1rem"
          marginY="2rem"
        >
          {testUsers?.map((testUser: Tester) => (
            <Box
              borderRadius="8px"
              bg="#222"
              boxShadow="rgba(100, 100, 111, 0.2) 0px 4px 16px 0px"
              overflowX="scroll"
              flexWrap="nowrap"
              width="95%"
              marginX="auto"
              scrollBehavior="smooth"
              textAlign="center"
              padding="1rem"
            >
              <Text
                className="small-text small-text-white"
              >
                {testUser.username}
              </Text>
              <Text
                className="small-text small-text-white"
              >
                {testUser.email}
              </Text>
              <CustomButton
                label="Remove User"
                testId={`remove_tester_${testerCandidate}`}
                buttonColor="night"
                buttonProps={{
                  onClick: (() => {
                    setRemoveTester(testUser.username);
                    onOpenRemoveTesterOpenModal();
                  }),
                  id: `remove_tester_${testerCandidate}`,
                }}
                spinnerId={undefined}
                buttonType="button"
                size="small"
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Modal isOpen={isTesterOpenModal} onClose={onCloseTesterModal}>
        <ModalOverlay />
        <ModalContent
          bg="#222"
          color="#FFF"
        >
          <ModalHeader
            className="medium-text medium-text-white"
          >
            Tester Add
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              className="small-text small-text-white"
            >
              Are you sure you want to add user as a tester?
            </Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="Cancel"
              testId={`cancel_test_${testerCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseTesterModal();
                }),
                id: `cancel_test_${testerCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Submit"
              testId={`submit_test_${testerCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  addUserAsTester();
                }),
                id: `submit_test_${testerCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRemoveTesterOpenModal} onClose={onCloseRemoveTesterModal}>
        <ModalOverlay />
        <ModalContent
          bg="#222"
          color="#FFF"
        >
          <ModalHeader>Tester Add</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontFamily="PtSerif"
              fontSize={{ base: '16px', md: '18px' }}
            >
              Are you sure you want to remove user as a tester?
            </Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="Cancel"
              testId={`cancel_remove_tester_${testerCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseRemoveTesterModal();
                }),
                id: `cancel_remove_tester_${testerCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Submit"
              testId={`submit_remove_tester_${testerCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  removeUserAsTester();
                }),
                id: `submit_remove_tester_${testerCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
