/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import axios, { axiosPrivate } from '../../../api/axios';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { UserBasic } from '../../../_interfaces/User';

interface User {
  username: string,
  email: string,
  trialDays: number,
}

export default function UserTesters() {
  const bg = useColorModeValue('#EEE', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const user = useSelector((state: UserBasic) => state.user.userData);
  const [trialUsers, setTrialUsers] = useState<User[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [numTrialUsers, setNumTrialUsers] = useState(1);

  const itemsPerPage = 18;

  const getTrialUsers = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'POST',
      url: '/api/user/trialUsersIndex',
      data: {
        itemsPerPage,
        offsetIndex: 0,
      },
    })
      .then((res) => {
        const { users, userCount } = res.data;
        setTrialUsers(users);
        setNumTrialUsers(userCount);
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(userCount / itemsPerPage));
        setItemOffset(endOffset);
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setUsersDisplayed(standardUsers.slice(itemOffset, endOffset));
        // setListOfAdmins(adminUsers);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  useEffect(() => {
    if (user.username) {
      getTrialUsers();
      // getTestUsers();
    }
  }, [user.username]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % numTrialUsers;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // ); q
    axios({
      method: 'POST',
      url: '/api/user/trialUsersIndex',
      data: {
        itemsPerPage,
        offsetIndex: newOffset,
      },
    })
      .then((res) => {
        const { users } = res.data;
        setTrialUsers(users);
        // setUsersDisplayed(standardUsers.slice(itemOffset, endOffset));
        // setListOfAdmins(adminUsers);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        setItemOffset(newOffset);
        document.getElementById('reg-user-list')?.scrollIntoView();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  return (
    <Box>
      <Box
        marginBottom="100px"
        minH="80vh"
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Text
            className="small-text small-text-white"
            marginTop="1rem"
          >
            Trial User Registry
          </Text>
          <Text
            className="small-text small-text-white"
            marginTop="1rem"
          >
            No. of Trial Users:
            {' '}
            {numTrialUsers}
          </Text>
        </Flex>
        <Box
          id="reg-users-list"
          marginBottom="2em"
          padding="2em"
          width={{ base: '95%', lg: '1023px' }}
          marginX="auto"
        >
          <SimpleGrid
            gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
            width={{ base: '95%', lg: '1023px' }}
            marginX="auto"
            gap="1rem"
            marginY="2rem"
          >
            {trialUsers?.map((regUser) => (
              <Box
                borderRadius="8px"
                bg="#222"
                boxShadow="rgba(100, 100, 111, 0.2) 0px 4px 16px 0px"
                overflowX="scroll"
                flexWrap="nowrap"
                width="95%"
                marginX="auto"
                scrollBehavior="smooth"
                textAlign="center"
                padding="1rem"
              >
                <Box>
                  <Text
                    className="small-text small-text-white"
                  >
                    {regUser.username}
                  </Text>
                  <Text
                    className="small-text small-text-white"
                  >
                    {regUser.email}
                  </Text>
                  <Box>
                    <Text
                      className="small-text small-text-white"
                    >
                      Trial:
                      {' '}
                      {regUser.trialDays}
                      {' '}
                      Days Left
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </SimpleGrid>
          <CustomPagination
            pageCount={pageCount}
            startingPage={startingPage}
            handlePageClick={handlePageClick}
          />
        </Box>
      </Box>
    </Box>
  );
}
