/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
} from '@chakra-ui/react';
import Navbar from '../../components/Navbar/Navbar';
import ImagePreview from '../../assets/images/theologians.png';
import ContactForm from './ContactForm';
import './SubCancel.scss';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';

function SubCancel() {
  return (
    <Box
      className="sub-cancel-container"
    >
      <Navbar />
      <Box>
        <Flex
          justifyContent="center"
          flexDir="column"
          alignItems="center"
          minH="900px"
        >
          <Box>
            <SmallLogo color="white" />
            <Image
              data-testid="app-preview"
              src={ImagePreview}
              width={{ base: '50%', md: '400px' }}
              marginX="auto"
            />
          </Box>
          <Box
            width="95%"
            marginX="auto"
            textAlign="center"
          >
            <Text
              marginTop="1em"
              fontSize={{ base: '22px', md: '26px' }}
              fontFamily="PTSerif"
            >
              We are sorry to hear that you canceled your subscription
            </Text>
          </Box>
          <Box
            marginTop="2em"
          >
            <Text
              maxW={{ base: '95%', md: '800px' }}
              marginX="auto"
              fontFamily="PtSerif"
              fontWeight="400"
              textAlign="left"
              fontSize="16px"
            >
              If you would like to send additional feedback, feel free to fill out the form below
            </Text>
          </Box>
          <Box
            marginY="20px"
          >
            <ContactForm />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default SubCancel;
