/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
  Button,
  IconButton,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import MobileView from './MobileView/MoibileView';
import DesktopView from './DesktopView/DesktopView';
import ChapterList from './ChapterList/ChapterList';
import BookHeader from './BookHeader/BookHeader';
import Comments from './Comments/Comments';
import FriendNotes from './FriendNotes/FriendNotes';
import DesktopPagination from './DesktopPagination/DesktopPagination';
import MobilePagination from './MobilePagination/MobilePagination';
import MyNotes from './MyNotes/MyNotes';
import { UserBasic } from '../../_interfaces/User';
import FootNotes from './FootNotes/FootNotes';
import ColorModeBtn from './ColorModeBtn/ColorModeBtn';

export default function BookView() {
  const [bookViewUpdating, setBookViewUpdating] = useState<any>(false);
  const { isPaid } = useSelector((state:UserBasic) => state.user.userData);
  const { bookId } = useParams();

  const bg = useColorModeValue('#FFF', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const bgButton = useColorModeValue('#CCC', '#333');
  // split by page number and line number
  // ensure that chapter jumping works correctly in mobile

  const renderSpinner = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      <Text
        fontFamily="Garamond"
      >
        Loading Book...

      </Text>
    </Flex>

  );

  return (
    <Box
      bg={bg}
      color={toggleColor}
      paddingBottom="4rem"
    >
      <Box>
        <Box>
          {
            isPaid === false
              ? (
                <Box
                  width={{ base: '90vw', sm: '400px', md: 'auto' }}
                  marginX="auto"
                  marginTop="2em"
                >
                  <Alert status="info" borderRadius="5px" display="flex" justifyContent="center">
                    <AlertIcon />
                    <AlertDescription fontSize={{ base: '0.9em', md: '1.2em' }}>
                      Please subscribe
                      {' '}
                      <Link
                        href="/subscription"
                        textDecor="underline"
                      >
                        here
                      </Link>
                      {' '}
                      if you would like to automatically save an instance of the book you
                      are reading and save highlights and create notes.
                    </AlertDescription>
                  </Alert>
                </Box>
              ) : <Box />
          }
        </Box>
        <Box
          id="book-header"
          display={{ base: 'block', md: 'flex' }}
          width="90%"
          justifyContent="space-between"
          marginX="auto"
          paddingTop="2rem"
        >
          <Box>
            <Box
              display={{ base: 'flex', md: 'none' }}
            >
              <ColorModeBtn />
            </Box>
            <BookHeader />
            <FriendNotes />
            <Box
              display={{ base: 'none', md: 'block' }}
            >
              <ChapterList />
            </Box>
          </Box>
          <Box>
            <Box
              display={{ base: 'none', md: 'block' }}
            >
              <ColorModeBtn />
            </Box>
            <MyNotes />
            <Box
              display={{ base: 'block', md: 'none' }}
            >
              <ChapterList />
            </Box>
          </Box>
        </Box>
        {bookViewUpdating ? renderSpinner()
          : (
            <Box
              display={{ base: 'none', md: 'block' }}
            >
              <DesktopView />
              <DesktopPagination />
            </Box>
          )}
        {bookViewUpdating ? renderSpinner()
          : (
            <Box
              display={{ base: 'block', md: 'none' }}
            >
              <MobileView />
              <MobilePagination />
            </Box>
          )}
        {isPaid
          ? (
            <Comments />
          ) : (
            <Flex
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              height={{ base: '200px', md: '300px' }}
              marginY="4em"
              backgroundColor={bgButton}
              textAlign="center"
            >
              <Text
                fontWeight="600"
                fontFamily="PtSerif"
                fontSize={{ base: '14px', md: '16px' }}
                width="90%"
                marginX="auto"
              >
                Please subscribe
                {' '}
                <Link
                  href="/subscription"
                  textDecor="underline"
                >
                  here
                </Link>
                {' '}
                if you would like see comments.
              </Text>
            </Flex>
          )}
        <FootNotes />
      </Box>
    </Box>
  );
}
