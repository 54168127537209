/* eslint-disable no-underscore-dangle */
import {
  Box,
  Input,
  Text,
  Flex,
  Avatar,
  Button,
  SimpleGrid,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { User, UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

const SearchButton = styled(SearchAlt)`
  width: 36px;
  outline: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
  }
  @media (max-width: 48em) {
    width: 26px;
  }
`;

export default function FriendsSearch(props: {
  addToRequests: (arg: any) => void,
}) {
  const { addToRequests } = props;
  const { userId } = useSelector((state: UserBasic) => state.user.userData);
  const [friendQuery, setFriendQuery] = useState<string>();
  const [displayQuery, setDisplayQuery] = useState<string>();
  const [results, setResults] = useState<User []>();
  const [queryCompleted, setQueryCompleted] = useState(false);
  const [waitingForResults, setWaitingForResults] = useState(false);
  const cardBackground = useColorModeValue('#DDD', '#222');
  const bgButton = useColorModeValue('#CCC', '#333');
  const bgHover = useColorModeValue('#BBB', '#444');
  const textManual = useColorModeValue('#000', '#FFF');
  // const bgHover = useColorModeValue('#CCC', '#333');
  const searchForFriend = () => {
    if (!friendQuery) return;
    setQueryCompleted(false);
    setDisplayQuery(friendQuery);
    setWaitingForResults(true);
    axios({
      method: 'POST',
      url: '/api/connections/queryFriends',
      data: {
        userId,
        userQuery: friendQuery,
      },
    })
      .then((res) => {
        // console.log(res.data.books);
        const { resultList } = res.data;
        setResults(resultList);
        setQueryCompleted(true);
        setWaitingForResults(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const sendFriendRequest = (targetUserId: string) => {
    console.log(targetUserId);
    axios({
      method: 'POST',
      url: '/api/connections/createFriendRequest',
      data: {
        userId,
        recipientId: targetUserId,
      },
    })
      .then(() => {
        // console.log(res.data.success);
        // console.log(targetUserId);
        addToRequests(targetUserId);
        const buttonElement = document.getElementById(`req_button_${targetUserId}`) as HTMLButtonElement;
        buttonElement.innerText = 'Added';
        buttonElement.disabled = true;
        const buttonDiscElement = document.getElementById(`req_disc_button_${targetUserId}`) as HTMLButtonElement;
        if (buttonDiscElement) {
          buttonDiscElement.innerText = 'Added';
          buttonDiscElement.disabled = true;
        }
        // const { resultList } = res.data;
        // setResults(resultList);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    function handlekeydownEvent(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        // console.log(searchQuery);
        searchForFriend();
      }
    }
    document.addEventListener('keyup', handlekeydownEvent);
    return () => {
      document.removeEventListener('keyup', handlekeydownEvent);
    };
  });
  const renderResults = results?.map((result: User) => (
    <Box
      textAlign="center"
      bg="#111"
      paddingY="2rem"
      borderRadius="5px"
    >
      <Box>
        <Link
          href={`/profile/${result?._id}`}
          _focus={{ outline: 'none' }}
        >
          <Avatar
            size="xl"
            src={result?.image}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
              transform: 'scale(1.08)',
              transition: 'transform 0.25s ease',
            }}
          />
        </Link>
      </Box>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          href={`/profile/${result?._id}`}
          className="small-text small-text-link"
        >
          {result.username}
        </Link>
      </Box>
      <CustomButton
        label="Add +"
        testId={`req_friend_search_${result._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            sendFriendRequest(result._id || '');
          }),
          id: `req_friend_search_${result._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  return (
    <Box>
      <Box
        width={{
          base: '90%', sm: '350px', md: '400px', lg: '500px',
        }}
        marginX="auto"
        marginTop="4em"
      >
        {/*
          <Image
            data-testid="app-preview"
            src={Theologians}
            width={{ base: '50%', md: '300px' }}
            marginX="auto"
          />
          */}
        <Box
          opacity="0.6"
          marginBottom="10px"
          fontSize="12px"
          lineHeight="20px"
          fontWeight="500"
          textTransform="uppercase"
          fontFamily="Montserrat, sans-serif"
          letterSpacing="1px"
          textAlign="center"
        >
          - Theologian&apos;s Pen -
        </Box>

        <Text
          fontWeight="600"
          fontSize={{ base: '18px', sm: '24px' }}
          marginTop="10px"
          marginBottom="1em"
          textAlign="center"
          fontFamily="PtSerif"
        >
          Find more Friends!
        </Text>
        <Flex
          borderRadius={{ base: '40px', md: '50px' }}
          height={{ base: '40px', md: '50px' }}
          width="100%"
          padding="5px 15px"
          margin="auto"
          backgroundColor="#777"
        >
          <Input
            id="book-search-bar"
            color="#000"
            style={{ color: '#FFF' }}
            placeholder="Search for a Friend"
            onChange={(event) => { setFriendQuery(event.target.value); }}
            border="0"
            outline="0"
            fontSize={{ base: '12px', md: '16px' }}
            height={{ base: '30px', md: '40px' }}
            marginRight="10px"
            _focus={{ outline: '0', backgroundColor: '#666' }}
          />
          <SearchButton
            cursor="pointer"
            id="search-book-button"
            color="#FFF"
            onClick={() => { searchForFriend(); }}
          />
        </Flex>
      </Box>
      <Box>
        {
          queryCompleted ? (
            <Box
              width={{ base: '90%', lg: '90%', xl: '95%' }}
              marginX="auto"
              marginTop="4em"
            >
              <Text
                fontSize={{ base: '18px', md: '24px' }}
                fontWeight="550"
                fontFamily="PtSerif"
              >
                Results for
                {' '}
                &quot;
                {displayQuery}
                &quot;
              </Text>
              <Box>
                {waitingForResults ? <LoadingSpinner /> : <Box />}
              </Box>
              <SimpleGrid
                marginTop="20px"
                gridTemplateColumns={{
                  base: '1fr 1fr',
                  md: '1fr 1fr 1fr 1fr',
                  xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                }}
                gridGap={{ base: '10px', md: '20px' }}
                marginX="auto"
              >
                {renderResults}
              </SimpleGrid>
            </Box>
          ) : <Box />
        }
      </Box>
    </Box>
  );
}
