/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, createRef,
} from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios, { axiosPrivate } from '../../api/axios';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { BookSeries } from '../../_interfaces/Books';

export default function SeriesList() {
  const [mySeriesList, setMySeriesList] = useState<BookSeries []>([]);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [allSeriesCount, setAllSeriesCount] = useState(0);
  const itemsPerPage = 24;
  const itemOffset = 0;
  // const [selectedBook, setSelectedBook] = useState();
  const [err, setErr] = useState();
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % allSeriesCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axiosPrivate({
      method: 'POST',
      url: '/api/bookseries/seriesByIndex',
      data: {
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { seriesList, seriesCount } = res.data;
        if (seriesList.length < 0) {
          setMySeriesList([]);
          return;
        }
        setMySeriesList(seriesList);
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        document.getElementById('admin-book-list')?.scrollIntoView();
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  const getSeries = () => {
    axios({
      method: 'POST',
      url: '/api/bookseries/seriesByIndex',
      withCredentials: true,
      data: {
        offSetIndex: itemOffset,
        itemsPerPage,
      },
    })
      .then((res) => {
        const { seriesList, seriesCount } = res.data;
        setMySeriesList(seriesList);
        setPageCount(Math.ceil(seriesCount / itemsPerPage));
        setAllSeriesCount(seriesCount);
      })
      .catch((resErr) => {
        setErr(resErr);
        // eslint-disable-next-line no-console
        navigate('/login');
      });
  };

  useEffect(() => {
    getSeries();
  }, []);

  const renderSeriesList = () => mySeriesList?.map((series) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    // width="180px"
    // height="285px"
      marginX="auto"
    >
      <Link
        href={`/bookseries/${series._id}`}
      >
        {series?.imageUrl
          ? (
            <Image
              cursor="pointer"
              borderRadius="5px"
              src={series.imageUrl}
            // width="180px"
            // height="285px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            />
          ) : (
            <Flex
              backgroundColor="#1F1F1F"
              color="#FFF"
            // width="180px"
            // height="285px"
              borderRadius="5px"
              justifyContent="center"
              flexDir="column"
              textAlign="center"
              paddingX="5px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            >
              <Text
                fontSize="16px"
                fontFamily="PTSerif"
              >
                {series.name}
              </Text>
              <Text
                marginTop="10px"
                fontSize="14px"
                fontFamily="PTSerif"
              >
                {series.author}
              </Text>
            </Flex>
          )}
      </Link>
    </Flex>
  ));
  return (
    <Box
      paddingY="2em"
    >
      <Box
        width={{ base: '90%', lg: '90%', xl: '75%' }}
        marginX="auto"
      >
        <Text
          className="large-text large-text-white large-text-bold"
        >
          Book Series
        </Text>
      </Box>
      <SimpleGrid
        marginTop="20px"
        gridTemplateColumns={{
          base: '1fr 1fr 1fr 1fr',
          lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        width={{ base: '90%', lg: '90%', xl: '75%' }}
        gridGap={{ base: '10px', md: '20px' }}
        marginX="auto"
      >
        {renderSeriesList()}
      </SimpleGrid>
      <Box
        paddingY="2em"
      >
        <CustomPagination
          pageCount={pageCount}
          startingPage={startingPage}
          handlePageClick={handlePageClick}
        />
      </Box>
      <Box />
    </Box>
  );
}
