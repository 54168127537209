import React from 'react';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import '../SignUp.scss';
import SmallLogo from '../../../../../components/Logos/SmallLogo/SmallLogo';

function Free() {
  return (
    <Box
      as="article"
      className="card card-black"
    >
      <SmallLogo color="white" />
      <Text
        as="p"
        className="large-text large-text-white"
        marginTop="2rem"
      >
        Starter
      </Text>
      <Text
        as="p"
        className="section-heading large-text-white"
        marginY="4rem"
        textTransform="uppercase"
      >
        Free
      </Text>
      <Text
        className="large-text large-text-white"
      >
        Sign Up Today!
      </Text>
      <Box
        as="a"
        id="bottom-register"
        href="/register"
        className="link-btn link-btn-grey"
      >
        Register
      </Box>
    </Box>
  );
}

export default Free;
