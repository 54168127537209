/* eslint-disable react/forbid-prop-types */
import React, {
  useState, useEffect, MouseEvent, ChangeEvent,
} from 'react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FootNote } from '../../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      footNotes: FootNote[]
    }
  }
}

export default function FootNotes() {
  const [currentFootnote, setCurrentFootnote] = useState<FootNote>();
  const { footNotes } = useSelector((state:BookState) => state.book.currentBook);
  const bg = useColorModeValue('#DDD', '#222');
  const {
    isOpen: isOpenFootnote,
    onOpen: onOpenFootnote,
    onClose: onCloseFootnote,
  } = useDisclosure();

  useEffect(() => {
    if (footNotes.length) {
      document.addEventListener('click', (event: any) => {
        const currentTarget = event.target;
        if (currentTarget?.id?.match('ref')) {
          for (let i = 0; i < footNotes.length; i += 1) {
            if (currentTarget.id.match(footNotes[i].id?.toString())) {
              setCurrentFootnote(footNotes[i]);
              break;
            }
          }
          onOpenFootnote();
        }
      });
    }
  }, [footNotes]);

  return (
    <Drawer placement="bottom" isOpen={isOpenFootnote} onClose={onCloseFootnote}>
      <DrawerOverlay />
      <DrawerContent
        bg={bg}
      >
        <DrawerHeader
          className="large-text"
        >
          <Text
            className="large-text"
          >
            Footnote
          </Text>
        </DrawerHeader>
        <DrawerCloseButton size="lg" />
        <DrawerBody paddingY="1em">
          <Text
            className="medium-text"
          >
            {currentFootnote?.note}
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
