/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Flex,
  Text,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';
import SearchIcon from '../../_assets/images/search_icon.png';
import AllBooks from './AllBooks';

export default function BookSearch() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const updateQuery = (event:any) => {
    // console.log(event.target.value);
    setSearchQuery(event.target.value);
  };

  const initSearch = () => {
    // console.log(searchQuery);
    if (searchQuery?.length) navigate(`/search-result/${searchQuery}`);
  };

  useEffect(() => {
    document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        // console.log(searchQuery);
        initSearch();
      }
    });
  });
  return (
    <Box>
      <Navbar />
      <Flex
        justifyContent="center"
        flexDir="column"
        alignItems="center"
        minH="95vh"
        color="#FFF"
        bg="#111"
        paddingY="4rem"
      >
        <Box
          width={{
            base: '90%', sm: '350px', md: '400px', lg: '500px',
          }}
        >
          <SmallLogo color="white" />
          <Text
            textAlign="center"
            className="large-text large-text-white"
          >
            Discover New Books
          </Text>
          <Flex
            borderRadius={{ base: '40px', md: '50px' }}
            height={{ base: '40px', md: '50px' }}
            width="100%"
            padding="5px 15px"
            margin="auto"
            backgroundColor="#777"
          >
            <Input
              id="book-search-bar"
              color="#000"
              style={{ color: '#FFF' }}
              placeholder="Search For Books"
              onChange={updateQuery}
              border="0"
              outline="0"
              fontSize={{ base: '12px', md: '16px' }}
              height={{ base: '30px', md: '40px' }}
              marginRight="10px"
              _focus={{ outline: '0', backgroundColor: '#666' }}
            />
            <Image
              onClick={() => { initSearch(); }}
              id="search-book-button"
              src={SearchIcon}
              className="search-image"
              alt="search icon button"
            />
          </Flex>
        </Box>
        <AllBooks />
      </Flex>
    </Box>
  );
}
