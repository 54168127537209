/* eslint-disable no-unused-vars */
import {
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import BookList from './BookList';
import Navbar from '../../components/Navbar/Navbar';
import './BookSeriesDetails.scss';

export default function BookSeriesDetails() {
  return (
    <Box>
      <Navbar />
      <Box
        as="main"
        className="series-detail-container"
      >
        <BookList />
      </Box>
    </Box>
  );
}
