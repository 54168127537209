import React from 'react';
import {
  Box,
  Link,
  ListItem,
  OrderedList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from '../Footer';
import TermsMenu from './TermsMenu';
import Header from './Header';

function Terms() {
  const bg = useColorModeValue('#EEE', '#EEE');
  const toggleColor = useColorModeValue('#222', '#222');
  return (
    <Box
      bg={bg}
      color={toggleColor}
    >
      <Header />
      <TermsMenu />
      <Box
        width={{
          base: '95%', sm: '90%', md: '80%', lg: '70%', xl: '50%',
        }}
        marginX="auto"
        paddingY="2em"
      >
        <Text
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Terms of Service
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          These Terms of Service govern your use of the website located at
          {' '}
          <Link
            href="/"
            color="blue"
            textDecor="underline"
          >
            https://www.theologianspen.com
          </Link>
          {' '}
          and any related services provided
          by Theologian&apos;s Pen.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          By accessing
          {' '}
          <Link
            href="/"
            color="blue"
            textDecor="underline"
          >
            https://www.theologianspen.com,
          </Link>
          {' '}
          you agree to abide by these Terms of Service and to comply
          with all applicable laws and regulations. If you do not
          agree with these Terms of Service, you are prohibited from
          using or accessing this website or using any other services
          provided by Theologian&apos;s Pen.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          We, Theologian&apos;s Pen, reserve the right to review and amend
          any of these Terms of Service at our sole discretion. Upon doing
          so, we will update this page. Any changes to these Terms of
          Service will take effect immediately from the date of publication.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          These Terms of Service were last updated on 16 May 2022.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Limitations of Use
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          By using this website, you warrant on behalf of yourself,
          your users, and other parties you represent that you will not:
        </Text>
        <OrderedList
          paddingLeft={{ base: '30px', md: '60px' }}
          fontSize={{ base: '18px', md: '22px' }}
          fontFamily="PTSerif"
          fontWeight="400"
        >
          <ListItem>
            Modify, copy, prepare derivative works of, decompile,
            or reverse engineer any materials and software contained on this website;
          </ListItem>
          <ListItem>
            remove any copyright or other proprietary notations from any materials
            and software on this website;
          </ListItem>
          <ListItem>
            transfer the materials to another person or “mirror” the materials on any other server;
          </ListItem>
          <ListItem>
            knowingly or negligently use this website or any of its associated services
            in a way that abuses or disrupts our networks or any other service
            Theologian&apos;s Penprovides;
          </ListItem>
          <ListItem>
            use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </ListItem>
          <ListItem>
            use this website or its associated services in violation of any
            applicable laws or regulations;
          </ListItem>
          <ListItem>
            use this website in conjunction with sending unauthorized advertising or spam;
          </ListItem>
          <ListItem>
            harvest, collect, or gather user data without the user&apos;s consent; or
          </ListItem>
          <ListItem>
            use this website or its associated services in
            such a way that may infringe the privacy, intellectual property rights,
            or other rights of third parties.
          </ListItem>
        </OrderedList>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Intellectual Property
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          The intellectual property in the materials contained in this
          website are owned by or licensed to Theologian&apos;s Pen and are
          protected by applicable copyright and trademark law.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          This constitutes the grant of a license, not a transfer of title.
          This license shall automatically terminate if you violate any of
          these restrictions or the Terms of Service, and may be terminated by
          Theologian&apos;s Pen at any time.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Liability
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          Our website and the materials on our website are
          provided on an &apos;as is&apos; basis. To the
          extent permitted by law, Theologian&apos;s Pen makes
          no warranties, expressed or implied, and hereby
          disclaims and negates all other warranties including,
          without limitation, implied warranties or conditions
          of merchantability, fitness for a particular purpose,
          or non-infringement of intellectual property, or other
          violation of rights.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          In no event shall Theologian&apos;s Pen or its suppliers
          be liable for any consequential loss suffered or
          incurred by you or any third party arising from
          the use or inability to use this website or the
          materials on this website, even if Theologian&apos;s Pen
          or an authorized representative has been notified,
          orally or in writing, of the possibility of such damage.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          In the context of this agreement, “consequential loss” includes
          any consequential loss, indirect loss, real or anticipated loss
          of profit, loss of benefit, loss of revenue, loss of business,
          loss of goodwill, loss of opportunity, loss of savings, loss of
          reputation, loss of use and/or loss or corruption of data, whether
          under statute, contract, equity, tort (including negligence),
          indemnity, or otherwise.
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
          marginTop="1em"
        >
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Accuracy of Materials
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          The materials appearing on our website are not comprehensive and
          are for general information purposes only. Theologian&apos;s Pen
          does not warrant or make any representations concerning the
          accuracy, likely results, or reliability of the use of
          the materials on this website, or otherwise relating to
          such materials or on any resources linked to this website.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Links
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          Theologian&apos;s Pen has not reviewed all of the sites linked
          to its website and is not responsible for the contents of
          any such linked site. The inclusion of any link does not
          imply endorsement, approval, or control by Theologian&apos;s
          Pen of the site. Use of any such linked site is at your own
          risk and we strongly advise you make your own investigations
          with respect to the suitability of those sites.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Right to Terminate
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          We may suspend or terminate your right to use our
          website and terminate these Terms of Service immediately
          upon written notice to you for any breach of these Terms of Service.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Severance
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          Any term of these Terms of Service which is wholly or partially
          void or unenforceable is severed to the extent that it is void
          or unenforceable. The validity of the remainder of these Terms
          of Service is not affected.
        </Text>
        <Text
          marginTop="1em"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="600"
          fontFamily="PTSerif"
        >
          Governing Law
        </Text>
        <Text
          fontSize={{ base: '18px', md: '22px' }}
          fontWeight="400"
          fontFamily="PTSerif"
        >
          These Terms of Service are governed by and construed in
          accordance with the laws of California. You irrevocably
          submit to the exclusive jurisdiction of the courts in
          that State or location.
        </Text>
      </Box>
    </Box>
  );
}

export default Terms;
