import {
  Box,
  Flex,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useLayoutEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useSelector, useDispatch } from 'react-redux';
import { updateBookContent } from '../../../_reducers/bookSlice';
import { Highlight, UserNote } from '../../../_interfaces/Notes';
import { setMyHighlights, setMyNotes } from '../../../_reducers/NoteSlice';
import { BookContent } from '../../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      currentPage: number,
      bookId: string,
      contentId: string,
    }
    bookContent: BookContent[],
  }
}
interface Notes {
  note: {
    userNotes: UserNote [],
    userHighlights: Highlight [],
  }
}
export default function Highlights() {
  const { bookContent } = useSelector((state: BookState) => state.book);
  const {
    currentPage,
  } = useSelector((state:BookState) => state.book.currentBook);
  const { userNotes, userHighlights } = useSelector((state: Notes) => state.note);
  const [selectedText, setSelectedText] = useState<string | null| undefined>();
  const [notesProcessed, setNotesProcessed] = useState<boolean>(true);
  const [notePosX, setNotePosX] = useState<number>(0);
  const [notePosY, setNotePosY] = useState<number>(0);
  const dispatch = useDispatch();
  const contextBg = useColorModeValue('#DDD', '#333');

  const {
    isOpen: isOpenHighlight,
    onOpen: onOpenHighlight,
    onClose: onCloseHighlight,
  } = useDisclosure();

  // check if selection is in the content of the book itself
  const checkValidSelection = (path:any) => {
    for (let i = 0; i < path.length; i += 1) {
      // console.log(doc.path[i]);
      if (path[i].id === 'book-view') {
        return true;
      }
    }
    return false;
  };

  // check if highlight already exists
  const checkHighlightExists = () => {
    const parentType = window?.getSelection()?.getRangeAt(0)
      .startContainer.parentNode?.nodeName.toLowerCase();
    // console.log(parentType);
    // passes when there is not highlight that already exists in text selection
    return parentType === 'mark';
  };

  // find mouse position to render the highlight menu above it
  const setMousePos = (event: any, desktopMode = true) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/pageY
    // get position and set it
    const pos = window?.getSelection()?.getRangeAt(0).getBoundingClientRect();
    if (desktopMode) {
      setNotePosX(pos?.x || 0);
      setNotePosY(event.pageY || 0);
    } else if (typeof (pos?.x) === 'number' && typeof (event.pageY) === 'number') {
      setNotePosX(pos.x - 150);
      setNotePosY(event.pageY);
    }
  };

  // check if only one paragraph selected max at a time
  const singleParagraphCheck = () => {
    const exactText = window?.getSelection()?.toString();
    const paragraphSplit = exactText?.split(/\r?\n/);
    // console.log(paragraphSplit);

    // prvents multiple paragraphs from being selected
    if (!paragraphSplit || paragraphSplit.length > 1 || paragraphSplit[0].length <= 1) {
      onCloseHighlight();
      return false;
    }
    return true;
  };

  // turn off and on the highlight menu use these two functions
  const highlightOff = () => {
    const highlightMenu = document?.getElementById('hlightMenu');
    if (highlightMenu) highlightMenu.style.display = 'none';
  };
  const highlightBlock = () => {
    const highlightMenu = document?.getElementById('hlightMenu');
    if (highlightMenu) highlightMenu.style.display = 'block';
  };

  // check if parent is already a highlight and return false it does exist
  const checkParentDom = () => {
    const paragraph = window?.getSelection()?.getRangeAt(0).cloneContents();
    // console.log(paragraph);
    const paragraphNodes:any = paragraph?.childNodes;
    // console.log(`current text id: ${window.getSelection().}`);
    if (paragraph?.parentElement?.id.match('highlight')) {
    // console.log(true);
      onCloseHighlight();
      highlightOff();
      return false;
    }
    if (paragraphNodes?.length) {
      for (let i = 0; i < paragraphNodes.length; i += 1) {
        const nodeLength = paragraphNodes[i]?.length;
        if (!nodeLength) {
          const elementType = paragraphNodes[i].nodeName.toLowerCase();
          if (elementType === 'mark') {
            onCloseHighlight();
            highlightOff();
            return false;
          }
        }
      }
    }
    return true;
  };

  const performChecks = (event: any, desktopMode = true) => {
    event.preventDefault();
    event.stopPropagation();
    let foundBook = false;
    const path = event.path || (event.composedPath && event.composedPath());
    foundBook = checkValidSelection(path);
    const highlightMenu = document.getElementById('hlightMenu');
    if (highlightMenu) {
      if (foundBook === false) {
        onCloseHighlight();
        highlightOff();
        return;
      }
      // if higlight already exists on parent node
      if (checkHighlightExists()) {
      // console.log(true);
        onCloseHighlight();
        highlightOff();
        return;
      }
      if (window?.getSelection()?.toString()?.length) {
        if (singleParagraphCheck() === false) {
          onCloseHighlight();
          highlightOff();
          return;
        }
        // check  if parent marks exists and returns false, do not allow higlight to render
        if (checkParentDom() === false) {
          onCloseHighlight();
          highlightOff();
          return;
        }
        // set mouse position and text
        const paragraph = window?.getSelection()?.getRangeAt(0).cloneContents();
        setMousePos(event, desktopMode);
        setSelectedText(paragraph?.textContent);
        highlightBlock();
      } else {
        highlightOff();
      }
    }
  };

  // https://stackoverflow.com/questions/55360736/how-do-i-window-removeeventlistener-using-react-useeffect
  useLayoutEffect(() => {
    document.addEventListener('mouseup', performChecks);
    return () => window.removeEventListener('mouseup', performChecks);
  }, [document]);

  useLayoutEffect(() => {
    document.addEventListener('touchstart', () => {
      window.oncontextmenu = (event: any) => {
        performChecks(event, false);
      };
    });
    return () => window.removeEventListener('touchstart', performChecks);
  }, [document]);

  const annotateHandler = (anotateOnly:any, selectedSpan:any) => {
    // const getText = window.getSelection().getRangeAt(0).toString();
    // console.log(getText);
    setNotesProcessed(false);
    const noteId = `note_${nanoid()}`;
    const notes = [...userNotes];
    notes.push({
      id: noteId,
      pageNo: currentPage,
      note: '',
      passage: anotateOnly ? selectedText : selectedSpan,
      currentPage,
    });
    dispatch(setMyNotes(notes));
  };
  const saveBookEdit = (
    startingParagraphId:any,
    updatedElement:any,
    updatedHighlights:any,
    selectedSpan:any,
    isNotes:any,
  ) => {
    // console.log(updatedElement);
    // setRetrievedBook(false);
    // console.log(`para id: ${startingParagraphId}`);
    const originalId = startingParagraphId;
    const paragraphNo = Number(startingParagraphId.replace(/^\D+/g, ''));
    // console.log(`${originalId}, ${paragraphNo}`);
    // console.log(updatedHighlights);
    // console.log(`paragraph No: ${paragraphNo}`);
    const copyBookContent = [...bookContent];
    const updatedObject = { ...copyBookContent[paragraphNo] };
    updatedObject.text = updatedElement;
    // console.log(updatedObject);
    copyBookContent[paragraphNo] = updatedObject;
    // updatedOjbect.text = updatedElement;
    // console.log(copyBookContent[paragraphNo].text);
    // getBookContent[paragraphNo] = updatedOjbect;
    dispatch(updateBookContent(copyBookContent));
    dispatch(setMyHighlights(updatedHighlights));
    if (isNotes) {
      annotateHandler(false, selectedSpan);
    }
  };

  const highlightMultiple = (color:any, colorTheme:any, isNotes = false) => {
    // const range:any = window?.getSelection()?.getRangeAt(0);
    // check if range has any mark tags
    // console.log(range.cloneContents());
    // an i tag may exist prompting a node search for one more level higher
    // const startingParagraphId = range.startContainer.parentNode.id
    //   ? range.startContainer.parentNode.id : range.startContainer.parentNode.parentNode.id;

    // const selectionContents = range.extractContents();

    const selection = window.getSelection();
    // console.log(selection);
    const count = selection?.rangeCount || 0;
    for (let i = 0; i < count; i += 1) {
      const range = selection?.getRangeAt(i);
      // console.log(range);
    }
  };

  const highlightText = (color:string, colorTheme:string, isNotes = false) => {
    const range:any = window?.getSelection()?.getRangeAt(0);
    // check if range has any mark tags
    // console.log(range.cloneContents());
    // an i tag may exist prompting a node search for one more level higher
    const startingParagraphId = range.startContainer.parentNode.id
      ? range.startContainer.parentNode.id : range.startContainer.parentNode.parentNode.id;

    const selectionContents = range.extractContents();
    /// / eslint-disable-next-line no-console
    let gethighlights:Highlight[] = [...userHighlights];

    let highlightNo;
    let currentHighlight = '';
    if (gethighlights.length === 0) {
      currentHighlight = 'highlight1';
      highlightNo = 1;
    } else {
      // console.log(highlightArr);
      const tempIndex = Number(gethighlights[gethighlights.length - 1].highlightNo) + 1;
      currentHighlight = `highlight${tempIndex}`;
      highlightNo = tempIndex;
    }
    // console.log(gethighlights);
    const newMark = document.createElement('mark');
    console.log(currentHighlight);
    newMark.id = currentHighlight;
    newMark.style.cursor = 'pointer';
    newMark.style.padding = '0';
    // span.onfocus = () => { span.style.backgroundColor = 'blue'; };
    // span.onClick(() => { openEditModal(); });
    //              pan.style.fontSize = '20px';
    // span.style.fontFamily = 'Garamond';
    // span.style.textAlign = ['justify'];
    // create new css class
    newMark.classList.add(`${colorTheme}`);
    newMark.appendChild(selectionContents);
    const stringNote = selectionContents.textContent;
    setSelectedText(stringNote);
    // span.innerHTML = exactText;
    // span.innerText = exactText;
    // console.log(span);
    range.insertNode(newMark);

    // removes extra br tags
    const updatedElement = range.commonAncestorContainer.parentElement.innerHTML.replace(/<br>/g, '');

    // total paragraph in string format
    const stringUpdatedElement = updatedElement.toString();
    const selectedSpan = newMark.innerHTML.toString();
    const highlightObj: Highlight = {
      id: currentHighlight,
      text: selectedSpan,
      highlightNo,
      pageNo: currentPage,
      color,
    };
    gethighlights = [...gethighlights, highlightObj];
    // eslint-disable-next-line max-len
    // console.log(selectedSpan);
    saveBookEdit(startingParagraphId, stringUpdatedElement, gethighlights, selectedSpan, isNotes);
  };

  const renderMenu = () => (
    <Box
      id="hlightMenu"
      display="none"
      backgroundColor={contextBg}
      zIndex="99999"
      borderRadius="10px"
      position={{ base: 'fixed', md: 'absolute' }}
      top={{ base: '50%', md: `${notePosY - 100}px` }}
      left={{
        base: '5%', sm: '15%', md: `${notePosX + 25}px`, lg: `${notePosX + 75}px`,
      }}
      width={{ base: '90%', sm: '70%', md: 'auto' }}
      paddingX="20px"
      paddingY="10px"
    >
      <Flex
        gap="10px"
        justifyContent="center"
      >
        <Button
          onClick={() => { highlightText('#F9E991', 'yellowTheme', true); }}
          cursor="pointer"
          fontFamily="Garamond"
          fontSize="16px"
        >
          Annotate
        </Button>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#F9E991', 'yellowTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#F9E991"
            _hover={{ backgroundColor: '#FAFAD2' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#A5C7F7', 'blueTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            // backgroundColor="#1E90FF"
            bgColor="#A5C7F7"
            _hover={{ backgroundColor: '#00BFFF' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#D1F3C5', 'greenTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#D1F3C5"
            _hover={{ backgroundColor: '#ADFF2F' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#FFB6C7', 'redTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#FFB6C7"
            _hover={{ backgroundColor: '#FFD4E3' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightOff(); }}
            width="24px"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
          >
            &#x2715;
          </Button>
        </Flex>
      </Flex>

    </Box>
  );

  return (
    <Box
      backgroundColor={contextBg}
    >
      {renderMenu()}
      <Popover
        isOpen={isOpenHighlight}
        onOpen={onOpenHighlight}
        onClose={onCloseHighlight}
        size="100%"
      >
        <PopoverContent
          padding="10px 15px"
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex
              gap="10px"
              justifyContent="flex-start"
            >
              <Button
                onClick={() => { highlightText('#F9E991', 'yellowTheme', true); }}
                cursor="pointer"
                fontFamily="Garamond"
                fontSize="16px"
              >
                Annotate
              </Button>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#F9E991', 'yellowTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  // backgroundColor="#FFD700"
                  backgroundColor="#F9E991"
                  _hover={{ backgroundColor: '#FAFAD2' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#A5C7F7', 'blueTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  // backgroundColor="#1E90FF"
                  backgroundColor="#A5C7F7"
                  _hover={{ backgroundColor: '#00BFFF' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#D1F3C5', 'greenTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#D1F3C5"
                  _hover={{ backgroundColor: '#ADFF2F' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#FFB6C7', 'redTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#FFB6C7"
                  _hover={{ backgroundColor: '#FFB6C1' }}
                />
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
