/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  useLocation, Navigate, Outlet, useNavigate,
} from 'react-router-dom';

import {
  Spinner,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../_reducers/userSlice';
import { axiosPrivate } from '../api/axios';

function RequireAuth(props: { allowedRoles: string[] }) {
  const { allowedRoles } = props;
  const [processing, setProcessing] = useState(true);
  const [user, setUser] = useState<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const theologianAccess: string = window.localStorage.getItem('theologianAccess') || '';
    const accessHeaders = {
      'Access-Control-Allow-Headers': 'theologianAccess',
      theologianAccess,
    };
    axiosPrivate({
      headers: accessHeaders,
      url: '/api/middleware/checkAccessToken',
      method: 'GET',
    })
      .then((res: any) => {
        // console.log(res)
        setUser(res.data);
        dispatch(updateUser(res.data));
        setProcessing(false);
      })
      .catch(() => {
        // console.log('run2')
        // setProcessing(false);
        const theologianRefresh: string = window.localStorage.getItem('theologianRefresh') || '';
        const deviceId:string = window.localStorage.getItem('deviceId') || '';
        const refreshHeaders = {
          'Access-Control-Allow-Headers': 'theologianAccess',
          theologianRefresh,
          deviceId,
        };
        axiosPrivate({
          headers: refreshHeaders,
          url: '/api/token/refreshToken',
          method: 'GET',
        })
          .then((res: any) => {
            // console.log(res); 1
            setUser(res.data);
            dispatch(updateUser(res.data));
            setProcessing(false);
          })
          .catch(() => {
            navigate('/login');
          });
      });
  }, []);
  return (
    <Box>
      {
       processing ? (
         <Flex
           flexDirection="column"
           justifyContent="center"
           alignItems="center"
           width="100%"
           height="100vh"
           bg="#111"
         >
           <Spinner
             thickness="4px"
             emptyColor="gray.200"
             color="#FF1A75"
             size="xl"
             mb="8px"
           />
           <Text>Loading...</Text>
         </Flex>
       )
         : (
           <Box>
             {
             user ? (
               <Box>
                 {
                  user?.roles?.find((role:any) => allowedRoles?.includes(role)) ? <Outlet />
                    : <Navigate to="/home" state={{ from: location }} replace />
                }
               </Box>
             ) : <Navigate to="/login" state={{ from: location }} replace />
           }
           </Box>
         )
     }
    </Box>
  );
}
export default RequireAuth;
