/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Button,
  Text,
  Textarea,
  useColorModeValue,
  Slide,
  useDisclosure,
  Accordion,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import MiniLoader from '../../../components/MiniLoader/MiniLoader';
import { UserBasic } from '../../../_interfaces/User';
import { BookContent, UserNote } from '../../../_interfaces/Books';
import updateBookPage from '../../../_helper/BookHelper';
import { Highlight } from '../../../_interfaces/Notes';
import { setMyNotes } from '../../../_reducers/NoteSlice';

interface BookState {
  book: {
    currentBook: {
      currentChapter: string,
      userBookId: string,
    }
    bookContent: BookContent[],
  }
}
interface Notes {
  note: {
    userNotes: UserNote [],
    userHighlights: Highlight [],
    higlightsProcessed: boolean,
    notesProcessed: boolean,
  }
}
export default function MyNotes() {
  const dispatch = useDispatch();
  const { bookContent } = useSelector((res: BookState) => res.book);
  const {
    userNotes,
    userHighlights,
  } = useSelector((state: Notes) => state.note);
  const [notesOpen, setNotesOpen] = useState<any>();
  const [highlightsOpen, setHighlightsOpen] = useState<any>(false);
  const [highlightsLoaded, setHighlightsLoaded] = useState<boolean>(false);
  const [notesLoading, setNotesLoading] = useState<any>(false);
  const [accordionIndex, setAccordionIndex] = useState<number>();
  const [currentNote, setCurrentNote] = useState<string>();
  const [currentNoteId, setCurrentNodeId] = useState<string>();
  const bg = useColorModeValue('#EEE', '#333');
  const bgHover = useColorModeValue('#DDD', '#444');
  const noteColor = useColorModeValue('#333', '#DDD');
  const borderColor = useColorModeValue('#CCC', '#444');
  const pageColor = useColorModeValue('#DDD', '#444');
  const pageColorHover = useColorModeValue('#CCC', '#555');
  const noteBorderColor = useColorModeValue('#555', '#CCC');
  const {
    isOpen: isOpenNotes,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();
  const removeNote = (noteId: any) => {
    // console.log(noteId);
    const notes = [...userNotes];
    const updatedNotes = notes.filter((note) => note.id !== noteId);
    dispatch(setMyNotes(updatedNotes));
  };
  const saveNote = () => {
    const notes = [...userNotes];
    const updatedNotes = notes.map((note) => {
      if (note.id === currentNoteId) {
        return {
          id: note.id,
          note: currentNote,
          passage: note.passage,
          pageNo: note.pageNo,
        };
      }
      return note;
    });
    dispatch(setMyNotes(updatedNotes));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (currentNote) {
        saveNote();
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [currentNote]);

  const updateNoteId = (id:any) => {
    // eslint-disable-next-line no-console
    console.log(id);
    setCurrentNodeId(id);
  };

  const hightlightHandler = () => {
    if (highlightsOpen) {
      setHighlightsOpen(false);
      setAccordionIndex(undefined);
    } else {
      setHighlightsOpen(true);
      setAccordionIndex(0);
    }
  };

  //   const getNotes = () => {
  //     const verticalElement = document.getElementById('note-slider-scroll');
  //     if (verticalElement?.scrollTop && verticalElement.scrollHeight) {
  //       verticalElement.scrollTop = verticalElement.scrollHeight;
  //     }
  //   };
  const notesHandler = () => {
    if (notesOpen) {
      setNotesOpen(false);
      setAccordionIndex(undefined);
    } else {
      setNotesOpen(true);
      setAccordionIndex(1);
    }
    // getNotes();
  };

  useEffect(() => {
    // console.log('true');
    if (highlightsLoaded) {
      setAccordionIndex(1);
      onOpenNotes();
      const verticalElement = document.getElementById('note-slider-scroll');
      if (verticalElement?.scrollTop && verticalElement.scrollHeight) {
        verticalElement.scrollTop = verticalElement.scrollHeight;
      }
    } else {
      setHighlightsLoaded(true);
    }
  }, [userNotes]);

  const openNoteHandler = () => {
    onOpenNotes();
    const element:any = document.getElementById('all-usernotes');
    if (element?.scrollTop && element.scrollHeight) {
      element.scrollTop = element.scrollHeight;
    }
  };

  return (
    <Box>
      <Box
        display={{ base: 'flex', md: 'block' }}
        justifyContent="center"
        paddingRight={{ base: '0', lg: '4em' }}
        flexDir="column"
        height="inherit"
      >
        <Button
          color={noteColor}
          bg={bg}
          _hover={{ bg: bgHover }}
          className="link-btn link-btn-small"
          onClick={() => { openNoteHandler(); }}
          marginTop="1rem"
          minW={{ base: '90vw', sm: '14rem' }}
          width={{ base: '90vw', sm: '14rem' }}
          marginX={{ base: 'auto', sm: 'none' }}
        >
          Open Notes

        </Button>
      </Box>
      <Slide
        direction="right"
        in={isOpenNotes}
        style={{
          height: '100vh', width: 'auto', zIndex: '9999', backgroundColor: `${bg}`,
        }}
      >
        <Box
          color={noteColor}
          bg={bg}
          rounded="md"
          w={{ base: '100vw', sm: '400px' }}
          padding="1.5rem"
        >
          <Flex
            justifyContent="space-between"
            marginX="auto"
          >
            <Heading fontSize={24} fontFamily="PTSerif">
              My Notes
            </Heading>
            <Button
              _focus={{ outline: 'none' }}
              aria-label="Close book Panel"
              onClick={onCloseNotes}
              fontSize="3rem"
              color={noteColor}
              bg={bg}
              _hover={{ bg: bgHover }}
            >
              &times;
            </Button>
          </Flex>
          <Box
            marginTop="1em"
            overflow="auto"
            maxHeight="85vh"
            id="note-slider-scroll"
          >

            <Accordion
              allowToggle
              allowMultiple
              index={accordionIndex}
            >
              <AccordionItem>
                <AccordionButton
                  id="hlight-button"
                  _focus={{ outline: 'none' }}
                  onClick={hightlightHandler}
                  border={`1px solid ${borderColor}`}
                  color={noteColor}
                  bg={pageColor}
                  _hover={{ bg: pageColorHover }}
                >
                  <Box fontSize="1.6rem" flex="1" textAlign="left">
                    Highlights
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  pb={4}
                >
                  <Box>
                    {
                      userHighlights?.map((highlight:Highlight) => (
                        <Box
                          p={2}
                          shadow="md"
                          borderWidth="1px"
                          color={noteColor}
                          key={highlight.pageNo}
                          border={`1px solid ${borderColor}`}
                          marginTop="1rem"
                        >
                          <Flex
                            justifyContent="space-between"
                          >
                            <Button
                              onClick={() => {
                                bookContent.forEach((para:BookContent) => {
                                  if (para.page === highlight.pageNo) {
                                    onCloseNotes();
                                    updateBookPage(para.page, para.chapter);
                                  }
                                });
                              }}
                              className="link-btn link-btn-small"
                              border={`1px solid ${borderColor}`}
                              color={noteColor}
                              bg={pageColor}
                              _hover={{ bg: pageColorHover }}
                            >
                              Page No:
                              {' '}
                              {highlight.pageNo}
                              -
                              {highlight.pageNo + 1}
                            </Button>
                          </Flex>
                          <Box>
                            <Box
                              marginY="1em"
                              backgroundColor={highlight.color}
                              paddingX="5px"
                            >
                              <Text fontFamily="Garamond" fontSize="16px" color="#000">
                                {parse(highlight.text)}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton
                  _focus={{ outline: 'none' }}
                  onClick={notesHandler}
                  border={`1px solid ${borderColor}`}
                  color={noteColor}
                  bg={pageColor}
                  _hover={{ bg: pageColorHover }}
                >
                  <Box fontSize="1.6rem" flex="1" textAlign="left">
                    Notes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {notesLoading ? <Box><MiniLoader /></Box>
                    : (
                      <Box>
                        {userNotes?.map((note:any) => (
                          <Box
                            p={5}
                            shadow="md"
                            borderWidth="1px"
                            border={`1px solid ${borderColor}`}
                            marginTop="1rem"
                          >
                            <Flex
                              justifyContent="space-between"
                            >
                              <Button
                                onClick={() => {
                                  bookContent.forEach((para:BookContent) => {
                                    if (para.page === note.pageNo) {
                                      onCloseNotes();
                                      updateBookPage(para.page, para.chapter);
                                    }
                                  });
                                }}
                                className="link-btn link-btn-small"
                                border={`1px solid ${borderColor}`}
                                color={noteColor}
                                bg={pageColor}
                                _hover={{ bg: pageColorHover }}
                              >
                                Page No:
                                {' '}
                                {note.pageNo}
                                -
                                {note.pageNo + 1}
                              </Button>
                              <Button
                                aria-label="delete note"
                                cursor="pointer"
                                onClick={() => { removeNote(note.id); }}
                                color={noteColor}
                                bg={bg}
                                _hover={{ bg: bgHover }}
                                fontSize="2rem"
                              >
                                &times;
                              </Button>
                            </Flex>
                            <Box>
                              <Box marginY="1em">
                                <Text fontFamily="Garamond" fontSize="16px">
                                  {parse(note.passage)}
                                </Text>
                              </Box>
                              <Text
                                className="small-text small-text-bold"
                              >
                                Notes:
                              </Text>
                              <Textarea
                                defaultValue={note.note}
                                onChange={(event) => {
                                  setCurrentNote(event.target.value);
                                }}
                                onFocus={() => { updateNoteId(note.id); }}
                                className="small-text"
                                rows={5}
                                border={`1px solid ${noteBorderColor}`}
                                _hover={{ border: `1px solid ${noteBorderColor}` }}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Box>
      </Slide>
    </Box>
  );
}
