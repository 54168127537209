import React from 'react';
import {
  Box,
  Text,
} from '@chakra-ui/react';
// import ContactForm from './ContactForm';
import FacebookImg from '../../../../assets/images/facebook.png';
import ContactForm from './ContactForm';
import SmallLogo from '../../../../components/Logos/SmallLogo/SmallLogo';
import './ContactUs.scss';
import Icon from '../../../../components/Icon/CustomIcon';

function ContactUs() {
  return (
    <Box
      as="section"
      className="contact-us"
    >
      <SmallLogo color="grey" />

      <Text
        as="h2"
        marginY="4rem"
        className="section-heading"
      >
        Contact Us
      </Text>

      <Text
        as="p"
        className="medium-text medium-text-black"
        marginBottom="1rem"
      >
        Have any thoughts, questions or feedback you would like to share with us?
      </Text>
      <Text
        as="p"
        className="medium-text medium-text-black"
        marginBottom="4rem"
      >
        We would love to hear from you!
      </Text>
      <ContactForm />
      <Text
        as="p"
        marginTop="4rem"
        marginBottom="1rem"
        className="medium-text medium-text-black medium-text-bold"
      >
        Follow Us on Facebook!
      </Text>
      <Icon
        href="https://www.facebook.com/Theologianspen"
        alt="Theologian's Pen Facebook Link"
        src={FacebookImg}
      />
    </Box>
  );
}

export default ContactUs;
