/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eol-last */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Text,
  Image,
  Heading,
  Box,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import UploadImg from '../../../assets/images/upload.png';
import axios from '../../../api/axios';

const baseDropzoneStyle = {
  height: '400px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#888',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#000',
};

function CreateSeries() {
  const [initStage, setInitStage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formStage, setFormStage] = useState(false);
  const [tempFile, setTempFile] = useState();
  const bg = useColorModeValue('#EEE', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const onDropAccepted = async () => {
    // console.log(files[0]);
    setInitStage(false);
    setFormStage(true);
  };
  const cancelPublish = () => {
    window.location.reload();
  };
  const onDropRejected = () => alert('Your file format cannot be accepted');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['.txt', '.docx'], // TODO: Accept other book types
    multiple: true,
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
  });
  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);
  const renderDropzone = () => (
    <Flex
      style={dropzoneStyle}
      onClick={onDropAccepted}
    >
      <Flex
        flexDirection="column"
        width="75vw"
        alignItems="center"
        justifyContent="center"
      >
        <Image marginX="auto" width="32px" objectFit="contain" src={UploadImg} />
        <Text
          fontFamily="PtSerif"
          fontSize={{ base: '1.2em', md: '1.5em' }}
          fontWeight="300"
          textAlign="center"
        >
          Click Here to create book series

        </Text>
      </Flex>
    </Flex>
  );
  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          name: '',
          author: '',
          description: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Must include a name'),
          author: Yup.string()
            .required('Must include an author'),
        })}
        onSubmit={(values) => {
          setUploading(true);
          // const bookData = {
          //   title: values.title,
          //   author: values.author,
          //   description: values.description,
          // };
          // console.log(values.releaseState);
          axios({
            method: 'POST',
            url: '/api/bookseries/createBookSeries',
            data: {
              name: values.name,
              author: values.author,
              description: values.description,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                alert('Updated Book metada successfully!');
                window.location.reload();
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props:any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >

              <Field name="name">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="title"
                      ml="4px"
                      fontWeight="450"
                    >
                      Series Name
                    </FormLabel>

                    <Input
                      id="name"
                      placeholder="Add a name to your book series"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="author">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="author"
                      ml="4px"
                      fontWeight="450"
                    >
                      Author
                    </FormLabel>

                    <Input
                      id="author"
                      placeholder="Add a author for your file"
                      value={values.author}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="description"
                      ml="4px"
                      fontWeight="450"
                    >
                      Description (Optional)
                    </FormLabel>

                    <Textarea
                      id="description"
                      placeholder="Add a album description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  type="button"
                  onClick={cancelPublish}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="10px"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="10px"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  useEffect(() => {
    setInitStage(true);
  }, []);
  return (
    <Box
      color={toggleColor}
      bg={bg}
    >
      <Flex
        flexDirection="column"
        display="Flex"
        alignItems="center"
        justifyContent="center"
        minH="80vh"
      >
        { /* <AlertBanner message={alertMessage} /> */ }
        <Heading fontWeight="500" fontSize={{ base: '1.3em', sm: '1.5em', lg: '2em' }} margin="2rem">Book Series</Heading>
        {initStage
          ? (
            <Box>
              {renderDropzone()}
            </Box>
          )
          : (
            <Box />
          )}
        {formStage
          ? (
            <Box>
              {renderDetailsForm()}
            </Box>
          )
          : (
            <Box />
          )}
      </Flex>
    </Box>
  );
}

export default CreateSeries;