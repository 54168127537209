import {
  Box,
  Flex,
  Button,
  IconButton,
  Spinner,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useSelector, useDispatch } from 'react-redux';
import { UserBasic } from '../../_interfaces/User';
import { axiosPrivate } from '../../api/axios';
import { updateBookContent } from '../../_reducers/bookSlice';
import { Highlight, UserNote } from '../../_interfaces/Notes';
import { setMyHighlights, setMyNotes } from '../../_reducers/NoteSlice';
import { BookContent } from '../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      currentPage: number,
      userBookId: string,
      bookId: string,
      contentId: string,
    }
    bookContent: BookContent[],
  }
}
interface Notes {
  note: {
    userNotes: UserNote [],
    userHighlights: Highlight [],
  }
}
export default function Highlights() {
  const { username, isPaid } = useSelector((state: UserBasic) => state.user.userData);
  const { bookContent } = useSelector((state: BookState) => state.book);
  const {
    currentPage,
    bookId,
    userBookId,
    contentId,
  } = useSelector((state:BookState) => state.book.currentBook);
  const { userNotes, userHighlights } = useSelector((state: Notes) => state.note);
  const [selectedText, setSelectedText] = useState<string | null| undefined>();
  const [notesProcessed, setNotesProcessed] = useState<boolean>(true);
  const [notePosX, setNotePosX] = useState<number>(0);
  const [notePosY, setNotePosY] = useState<number>(0);
  const dispatch = useDispatch();
  const contextBg = useColorModeValue('#DDD', '#333');

  const {
    isOpen: isOpenHighlight,
    onOpen: onOpenHighlight,
    onClose: onCloseHighlight,
  } = useDisclosure();

  const highlightOff = () => {
    const highlightMenu = document?.getElementById('hlightMenu');
    if (highlightMenu) highlightMenu.style.display = 'none';
  };
  const highlightBlock = () => {
    const highlightMenu = document?.getElementById('hlightMenu');
    if (highlightMenu) highlightMenu.style.display = 'block';
  };
  useEffect(() => {
    document.addEventListener('mouseup', (event: any) => {
      // event.preventDefault();
      // const currentTarget = event.target;
      event.preventDefault();
      let foundBook = false;
      const path = event.path || (event.composedPath && event.composedPath());
      for (let i = 0; i < path.length; i += 1) {
        // console.log(doc.path[i]);
        if (path[i].id === 'book-view') {
          foundBook = true;
        }
      }

      const highlightMenu = document.getElementById('hlightMenu');
      if (highlightMenu) {
        if (foundBook === false) {
          onCloseHighlight();
          highlightOff();
          return;
        }
        // get position
        const pos = window?.getSelection()?.getRangeAt(0).getBoundingClientRect();
        // const pos2 = highlightMenu.scrollHeight;

        // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/pageY
        // console.log(event.pageY);
        setNotePosX(pos?.x || 0);
        setNotePosY(event.pageY || 0);
        // if higlight already exists on parent node
        const parentType = window?.getSelection()?.getRangeAt(0)
          .startContainer.parentNode?.nodeName.toLowerCase();
        // console.log(parentType);
        if (parentType === 'mark') {
        // console.log(true);
          onCloseHighlight();
          highlightOff();
          return;
        }
        if (window?.getSelection()?.toString()?.length) {
          const exactText = window?.getSelection()?.toString();
          const paragraphSplit = exactText?.split(/\r?\n/);
          if (!paragraphSplit || paragraphSplit.length > 1 || paragraphSplit[0].length <= 1) {
            onCloseHighlight();
            highlightOff();
            return;
          }
          const paragraph = window?.getSelection()?.getRangeAt(0).cloneContents();
          // console.log(paragraph);
          const paragraphNodes:any = paragraph?.childNodes;
          // console.log(`current text id: ${window.getSelection().}`);
          if (paragraph?.parentElement?.id.match('highlight')) {
          // console.log(true);
            onCloseHighlight();
            highlightOff();
            return;
          }
          if (paragraphNodes?.length) {
            for (let i = 0; i < paragraphNodes.length; i += 1) {
              const nodeLength = paragraphNodes[i]?.length;
              if (!nodeLength) {
                const elementType = paragraphNodes[i].nodeName.toLowerCase();
                if (elementType === 'mark') {
                  onCloseHighlight();
                  highlightOff();
                  return;
                }
              }
            }
          }
          setSelectedText(paragraph?.textContent);
          highlightBlock();
        } else {
          highlightOff();
        }
      }
    });

    document.addEventListener('touchstart', () => {
      window.oncontextmenu = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        let foundBook = false;
        const path = event.path || (event.composedPath && event.composedPath());
        for (let i = 0; i < path.length; i += 1) {
        // console.log(doc.path[i]);
          if (path[i].id === 'book-view') {
            foundBook = true;
          }
        }
        if (foundBook === false) {
          onCloseHighlight();
          highlightOff();
          return;
        }
        const parentType = window?.getSelection()?.getRangeAt(0)
          .startContainer?.parentNode?.nodeName.toLowerCase();
        if (parentType === 'mark') {
          // console.log(true);
          onCloseHighlight();
          highlightOff();
          return;
        }
        if (window?.getSelection()?.toString()?.length) {
          const exactText:string = window?.getSelection()?.toString() || '';
          const paragraphSplit = exactText?.split(/\r?\n/);
          // console.log(paragraphSplit);
          if (paragraphSplit.length > 1 || paragraphSplit[0].length <= 1) {
            onCloseHighlight();
            highlightOff();
            return;
          }
          const paragraph = window?.getSelection()?.getRangeAt(0).cloneContents();
          // console.log(paragraph);
          const paragraphNodes:any = paragraph?.childNodes;
          // console.log(`current text id: ${paragraph.cloneNode().id}`);
          if (paragraph?.parentElement?.id.match('highlight')) {
          // console.log(true);
            onCloseHighlight();
            highlightOff();
            return;
          }

          for (let i = 0; i < paragraphNodes.length; i += 1) {
            const nodeLength = paragraphNodes[i].length;
            if (!nodeLength) {
              const elementType = paragraphNodes[i].nodeName.toLowerCase();
              if (elementType === 'mark') {
                onCloseHighlight();
                highlightOff();
                return;
              }
            }
          }
          /// get position
          const pos = window?.getSelection()?.getRangeAt(0).getBoundingClientRect();
          // const pos2 = highlightMenu.scrollHeight;
          // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/pageY
          // console.log(event.pageY);
          setNotePosX(pos?.x || 0);
          setNotePosY(event.pageY || 0);
          setSelectedText(paragraph?.textContent);
          // setIsHighlightOpen(true);
          highlightBlock();
        } else {
          highlightOff();
        }
      };
    });
  }, []);
  const annotateHandler = (anotateOnly:any, selectedSpan:any) => {
    // const getText = window.getSelection().getRangeAt(0).toString();
    // console.log(getText);
    setNotesProcessed(false);
    const noteId = `note_${nanoid()}`;
    const notes = [...userNotes];
    notes.push({
      id: noteId,
      pageNo: currentPage,
      note: '',
      passage: anotateOnly ? selectedText : selectedSpan,
      currentPage,
    });
    dispatch(setMyNotes(notes));
    if (isPaid) {
      // console.log(isPaid);
      axiosPrivate({
        method: 'POST',
        url: '/api/paidFeature/saveUserNote',
        data: {
          bookId: userBookId,
          noteId,
          username,
          pageNo: currentPage,
          passage: anotateOnly ? selectedText : selectedSpan,
          note: '',
        },
      })
        .then(() => {
          setNotesProcessed(true);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
        });
    }
  };
  const saveBookEdit = (
    startingParagraphId:any,
    updatedElement:any,
    updatedHighlights:any,
    selectedSpan:any,
    isNotes:any,
  ) => {
    // setRetrievedBook(false);
    if (isPaid) {
      // console.log(`para id: ${startingParagraphId}`);
      const originalId = startingParagraphId;
      const paragraphNo = Number(startingParagraphId.replace(/^\D+/g, ''));
      // console.log(updatedHighlights);
      // console.log(`paragraph No: ${paragraphNo}`);
      const copyBookContent = [...bookContent];
      const updatedObject = { ...copyBookContent[paragraphNo] };
      updatedObject.text = updatedElement;
      copyBookContent[paragraphNo] = updatedObject;
      // updatedOjbect.text = updatedElement;
      // console.log(updatedOjbect);
      // getBookContent[paragraphNo] = updatedOjbect;
      dispatch(updateBookContent(copyBookContent));
      dispatch(setMyHighlights(updatedHighlights));
      axiosPrivate({
        method: 'POST',
        url: '/api/paidFeature/saveBookEdit',
        data: {
          updatedElement,
          paragraphNo,
          bookId,
          userBookId,
          contentId,
          username,
          currentPageNumber: currentPage,
          isUserBook: true,
          highlights: updatedHighlights,
        },
      })
        .then((res) => {
          onCloseHighlight();
          // console.log(bookContent[paragraphNo]);
          // console.log(updatedElement);
          // const getBookContent:BookContent[] = [...bookContent];
          // setUserBookObject(res.data.book);
          if (isNotes) {
            annotateHandler(false, selectedSpan);
          }
        // setRetrievedBook(true);
        })
        .catch((bookerr) => {
        // eslint-disable-next-line no-console
          console.log(bookerr);
        });
    }
  };

  const highlightText = (color:any, colorTheme:any, isNotes = false) => {
    const range:any = window?.getSelection()?.getRangeAt(0);
    // check if range has any mark tags
    // console.log(range.cloneContents());
    // an i tag may exist prompting a node search for one more level higher
    const startingParagraphId = range.startContainer.parentNode.id
      ? range.startContainer.parentNode.id : range.startContainer.parentNode.parentNode.id;

    // console.log(startingParagraphId);
    const selectionContents = range.extractContents();
    /// / eslint-disable-next-line no-console
    // console.log(selectionContents);
    let gethighlights:Highlight[] = [...userHighlights];
    console.log(gethighlights);
    let highlightNo;
    let currentHighlight = '';
    if (gethighlights.length === 0) {
      currentHighlight = 'highlight1';
      highlightNo = 1;
    } else {
      // console.log(highlightArr);
      const tempIndex = Number(gethighlights[gethighlights.length - 1].highlightNo) + 1;
      currentHighlight = `highlight${tempIndex}`;
      highlightNo = tempIndex;
    }
    // console.log(gethighlights);
    const span = document.createElement('mark');
    span.id = currentHighlight;
    span.style.cursor = 'pointer';
    span.style.padding = '0';
    // span.onfocus = () => { span.style.backgroundColor = 'blue'; };
    // span.onClick(() => { openEditModal(); });
    //              pan.style.fontSize = '20px';
    // span.style.fontFamily = 'Garamond';
    // span.style.textAlign = ['justify'];
    // create new css class
    span.classList.add(`${colorTheme}`);
    span.appendChild(selectionContents);
    console.log(span);
    const stringNote = selectionContents.textContent;
    setSelectedText(stringNote);
    // span.innerHTML = exactText;
    // span.innerText = exactText;
    // console.log(span);
    range.insertNode(span);

    // removes extra br tags
    const updatedElement = range.commonAncestorContainer.parentElement.innerHTML.replace(/<br>/g, '');

    // total paragraph in string format
    const stringUpdatedElement = updatedElement.toString();
    const selectedSpan = span.innerHTML.toString();
    const highlightObj: Highlight = {
      id: currentHighlight,
      text: selectedSpan,
      highlightNo,
      pageNo: currentPage,
      color,
    };
    console.log(highlightObj);
    gethighlights = [...gethighlights, highlightObj];
    saveBookEdit(startingParagraphId, stringUpdatedElement, gethighlights, selectedSpan, isNotes);
  };

  const renderMenu = () => (
    <Box
      id="hlightMenu"
      display="none"
      backgroundColor={contextBg}
      zIndex="99999"
      borderRadius="10px"
      position={{ base: 'fixed', md: 'absolute' }}
      top={{ base: '50%', md: `${notePosY - 100}px` }}
      left={{
        base: '5%', sm: '15%', md: `${notePosX + 25}px`, lg: `${notePosX + 75}px`,
      }}
      width={{ base: '90%', sm: '70%', md: 'auto' }}
      paddingX="20px"
      paddingY="10px"
    >
      <Flex
        gap="10px"
        justifyContent="center"
      >
        <Button
          onClick={() => { highlightText('#F9E991', 'yellowTheme', true); }}
          cursor="pointer"
          fontFamily="Garamond"
          fontSize="16px"
        >
          Annotate
        </Button>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#F9E991', 'yellowTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#F9E991"
            _hover={{ backgroundColor: '#FAFAD2' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#A5C7F7', 'blueTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            // backgroundColor="#1E90FF"
            bgColor="#A5C7F7"
            _hover={{ backgroundColor: '#00BFFF' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#D1F3C5', 'greenTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#D1F3C5"
            _hover={{ backgroundColor: '#ADFF2F' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightText('#FFB6C7', 'redTheme'); }}
            width="24px"
            minW="0"
            padding="0"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
            backgroundColor="#FFB6C7"
            _hover={{ backgroundColor: '#FFD4E3' }}
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => { highlightOff(); }}
            width="24px"
            minW="0"
            paddingLeft="0"
            paddingRight="1px"
            paddingBottom="1px"
            height="24px"
            borderRadius="50%"
            cursor="pointer"
          >
            &#x2715;
          </Button>
        </Flex>
      </Flex>

    </Box>
  );

  return (
    <Box
      backgroundColor={contextBg}
    >
      {renderMenu()}
      <Popover
        isOpen={isOpenHighlight}
        onOpen={onOpenHighlight}
        onClose={onCloseHighlight}
        size="100%"
      >
        <PopoverContent
          padding="10px 15px"
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex
              gap="10px"
              justifyContent="flex-start"
            >
              <Button
                onClick={() => { highlightText('#F9E991', 'yellowTheme', true); }}
                cursor="pointer"
                fontFamily="Garamond"
                fontSize="16px"
              >
                Annotate
              </Button>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#F9E991', 'yellowTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  // backgroundColor="#FFD700"
                  backgroundColor="#F9E991"
                  _hover={{ backgroundColor: '#FAFAD2' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#A5C7F7', 'blueTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  // backgroundColor="#1E90FF"
                  backgroundColor="#A5C7F7"
                  _hover={{ backgroundColor: '#00BFFF' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#D1F3C5', 'greenTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#D1F3C5"
                  _hover={{ backgroundColor: '#ADFF2F' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightText('#FFB6C7', 'redTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#FFB6C7"
                  _hover={{ backgroundColor: '#FFB6C1' }}
                />
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
