import { Link } from '@chakra-ui/react';
import React from 'react';
import './MainLogo.scss';

export default function MainLogo(props: {
  theme: 'light'| 'dark'
}) {
  const { theme } = props;
  return (
    <Link
      href="/"
      id="main-logo"
      className={`main-logo main-logo-${theme}`}
    >
      Theologian&apos;s Pen
    </Link>
  );
}
