import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios, { axiosPrivate } from '../../api/axios';
import { UserBasic } from '../../_interfaces/User';
import './Subscription.scss';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';
import CustomButton from '../../components/CustomButton/CustomButton';

// eslint-disable-next-line no-unused-vars
const REDIRECT_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:3000/subscription'
  : 'https://theologianspen.herokuapp.com/subscription';
// eslint-disable-next-line no-unused-vars
const PAYMENT_URL = window.location.hostname === 'localhost'
  ? 'https://buy.stripe.com/test_9AQ3f66g80J5gCI6oo'
  : 'https://buy.stripe.com/6oEbMjbK06cy1LaaEG';

export default function StandardTier() {
  const { username, isPaid, userId } = useSelector((state: UserBasic) => state.user.userData);
  const {
    isOpen: isOpenUnSub,
    onOpen: onOpenUnSub,
    onClose: onCloseUnSub,
  } = useDisclosure();
  const [subPrice, setSubPrice] = useState<string>();
  const navigate = useNavigate();
  const bg = useColorModeValue('#EEE', '#222');
  const toggleColor = useColorModeValue('#000', '#FFF');

  const cancelSub = () => {
    // console.log(username);
    axios({
      method: 'PUT',
      url: '/api/stripe/cancelSub',
      data: {
        username,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          navigate('/subscription-cancel');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const checkoutSession = () => {
    // console.log(user);
    axiosPrivate({
      method: 'POST',
      url: '/api/stripe/checkoutSession',
      data: {
        username,
        userId,
        priceId: subPrice,
      },
    })
      .then((res) => {
        const { url } = res.data;
        window.location.href = url;
      })
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const retrieveProducts = () => {
    axios({
      method: 'GET',
      url: '/api/stripe/productList',
    })
      .then((res) => {
        const { products } = res.data;
        const defaultPrice = products[0].default_price;
        // console.log(defaultPrice);
        setSubPrice(defaultPrice);
      })
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  useEffect(() => {
    if (username) {
      // console.log(user);
      retrieveProducts();
    }
  }, [username]);
  return (
    <Box
      className="subscription-card"
      width={{ base: '85%', sm: '300px', md: '350px' }}
      height={{ base: '500px', sm: '600px', md: '650px' }}
      marginX={{ base: 'auto', md: '1rem' }}
    >
      <SmallLogo color="white" />
      <Text
        data-testid="standard-package-subheader"
        className="large-text large-text-white"
        marginTop="1.5rem"
      >
        Standard Package
      </Text>
      <Text
        data-testid="standard-package-subheader"
        className="medium-text medium-text-white"
        marginTop="1.5rem"
      >
        For Limited time - Early Adopters:
      </Text>
      <Text
        className="section-heading"
      >
        $6.95
      </Text>
      <Text
        maxW={{ base: '100%', md: '800px' }}
        textDecor="underline"
        className="medium-text medium-text-white"
        marginTop="1.5rem"
        textAlign="left"
      >
        Standard Package includes:
      </Text>

      <Text
        maxW={{ base: '100%', md: '800px' }}
        data-testid="standard-feature-one"
        className="x-small-text x-small-text-white"
        textAlign="left"
      >
        - Annotate and Highlight
        <br />
        - The ability to share with friends and groups your thoughts
        <br />
        - Ability to bookmark
      </Text>
      <Text
        maxW={{ base: '100%', md: '800px' }}
        className="x-small-text x-small-text-white"
        textAlign="left"
        fontStyle="italic"
      >
        - And many more to come
      </Text>
      { isPaid ? (
        <Box
          marginTop="2rem"
        >
          <CustomButton
            label="Cancel Subscription"
            testId="standard_cancel_button"
            buttonColor="night"
            buttonProps={{
              onClick: () => {
                onOpenUnSub();
              },
              id: 'standard_cancel_button',
            }}
            spinnerId={undefined}
            buttonType="submit"
            size="medium"
          />
        </Box>
      )
        : (
          <Box
            marginTop="2rem"
          >
            <CustomButton
              label="Subscribe Now"
              testId="standard_plan_button"
              buttonColor="night"
              buttonProps={{
                onClick: () => {
                  checkoutSession();
                },
                id: 'standard_plan_button',
              }}
              spinnerId={undefined}
              buttonType="submit"
              size="medium"
            />
          </Box>
        )}
      <Modal isOpen={isOpenUnSub} onClose={onCloseUnSub}>
        <ModalOverlay />
        <ModalContent
          backgroundColor="#222"
          color="#FFF"
        >
          <ModalHeader className="medium-text medium-text-white">Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to cancel your Subscription?</Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="No"
              testId="cancel_sub_deny"
              buttonColor="night"
              buttonProps={{
                onClick: () => {
                  onCloseUnSub();
                },
                id: 'cancel_sub_deny',
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Yes"
              testId="cancel_sub_accept"
              buttonColor="night"
              buttonProps={{
                onClick: () => {
                  cancelSub();
                },
                id: 'cancel_sub_accept',
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
