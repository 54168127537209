import React from 'react';
import {
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';

function LoadingSpinner() {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="80vh"
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      <Text>Loading...</Text>
    </Flex>
  );
}
export default LoadingSpinner;
