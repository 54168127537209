/* eslint-disable no-underscore-dangle */
import {
  Box,
  SimpleGrid,
  Text,
  Avatar,
  Button,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import { User, UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

export default function PendingRequests(props: {
  addToFriends: (arg: any) => void,
}) {
  const {
    addToFriends,
  } = props;
  const { userId } = useSelector((state: UserBasic) => state.user.userData);
  const [userRequests, setUserRequests] = useState([]);
  const cardBackground = useColorModeValue('#DDD', '#222');
  const bgButton = useColorModeValue('#CCC', '#333');
  const bgHover = useColorModeValue('#BBB', '#444');
  const textManual = useColorModeValue('#000', '#FFF');
  const removeRequest = (selectedUserId: string) => {
    // console.log(`id ${selectedUserId}`);
    axios({
      method: 'DELETE',
      url: '/api/connections/rejectFriendRequest',
      data: {
        userId,
        requesterId: selectedUserId,
      },
    })
      .then(() => {
        const updatedRequests = userRequests.filter(
          (user: User) => user?._id?.toString() !== selectedUserId.toString(),
        );
        setUserRequests(updatedRequests);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const acceptRequest = (selectedUserId: string) => {
    // console.log(`id ${selectedUserId}`);
    axios({
      method: 'POST',
      url: '/api/connections/acceptFriendRequest',
      data: {
        userId,
        requesterId: selectedUserId,
      },
    })
      .then(() => {
        setUserRequests(
          userRequests.filter((user: User) => user._id !== selectedUserId),
        );
        addToFriends(selectedUserId);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const retrieveFriendRequests = () => {
    axios({
      method: 'GET',
      url: `/api/connections/getPendingRequests/${userId}`,
    })
      .then((res) => {
        // console.log(res.data.pendingFriends);
        const { pendingFriends } = res.data;
        setUserRequests(pendingFriends);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    if (userId) {
      retrieveFriendRequests();
    }
  }, [userId]);
  const renderResults = userRequests?.map((user: User) => (
    <Box
      textAlign="center"
      paddingY="2rem"
      borderRadius="5px"
      backgroundColor="#111"
    >
      <Box>
        <Link
          href={`/profile/${user._id}`}
          _focus={{ outline: 'none' }}
        >
          <Avatar
            size="xl"
            src={user?.image}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
              transform: 'scale(1.08)',
              transition: 'transform 0.25s ease',
            }}
          />
        </Link>
      </Box>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          href={`/profile/${user?._id}`}
          className="small-text small-text-link"
        >
          {user.username}
        </Link>
      </Box>
      <CustomButton
        label="Accept"
        testId={`req_accept_${user._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            acceptRequest(user?._id || '');
          }),
          id: `req_accept_${user._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
      <Box marginY="1rem" />
      <CustomButton
        label="Ignore"
        testId={`req_remove_${user._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            removeRequest(user?._id || '');
          }),
          id: `req_remove_${user._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  return (
    <Box>
      { userRequests?.length > 0
        ? (
          <Box
            width={{ base: '90%', lg: '90%', xl: '85%' }}
            marginX="auto"
            textAlign="left"
          >
            <Text
              className="medium-text medium-text-white"
              marginTop="2rem"
            >
              Pending Requests
            </Text>
            <SimpleGrid
              marginTop="2em"
              gridTemplateColumns={{
                base: '1fr 1fr',
                md: '1fr 1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              }}
              gridGap={{ base: '10px', md: '20px' }}
              rowGap={{ base: '20px', md: '20px' }}
            >
              {renderResults}
            </SimpleGrid>
          </Box>
        ) : <Box />}
    </Box>
  );
}
