import React from 'react';
import { Image, Link } from '@chakra-ui/react';
import './CustomIcon.scss';

export default function CustomIcon(props: {
  src: string,
  alt: string,
  href: string,
}) {
  const { src, alt, href } = props;
  return (
    <Link
      href={href}
      target="_blank"
    >
      <Image
        className="icon-round"
        src={src}
        alt={alt}
      />
    </Link>
  );
}
