import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import './ColorModeBtn.scss';

export default function ColorModeBtn() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [currentColorMode, setCurrentColorMode] = useState<boolean>(colorMode !== 'light');
  const bg = useColorModeValue('#EEE', '#333');
  const bgHover = useColorModeValue('#DDD', '#444');
  const color = useColorModeValue('#333', '#DDD');
  const updateUserDarkMode = () => {
    setCurrentColorMode(!currentColorMode);
    toggleColorMode();
  };
  return (
    <Flex
      justifyContent="center"
      id="darkmode-btn"
      bg={bg}
      color={color}
      _hover={{ bg: bgHover }}
      width="14rem"
      height="4rem"
      paddingY="1rem"
      onClick={() => { updateUserDarkMode(); }}
      cursor="pointer"
      marginTop={{ base: '0', md: '2rem' }}
      gap="5px"
      role="button"
      aria-label="dark mode button"
      outline="blue"
      marginX={{ base: 'left', md: 'right' }}
      tabIndex={0}
    >
      <Text className="x-small-text x-small-text-bold" textTransform="uppercase" marginTop=".4rem">Dark Mode</Text>
      <Box className="switch">
        <input type="checkbox" className="checkbox" id="dark-mode-switch" checked={currentColorMode} />
        <span className="slider round" />
      </Box>
    </Flex>
  );
}
