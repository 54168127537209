/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Link,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { Navicon } from '@styled-icons/evil/Navicon';
import MainLogo from '../../../components/Logos/MainLogo/MainLogo';
import HamburgerNavIcon from '../../../components/HamburgerNavIcon/HamburgerNavIcon';

const NavButton = styled(Navicon)`
  color: #000;
  width: 42px;
`;

function Header() {
  return (
    <Flex
      as="section"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
    >
      <MainLogo theme="dark" />
      <Box
        display={{ base: 'none', md: 'block' }}
      >
        <Link
          className="link-btn link-btn-small link-btn-black"
          href="/login"
          data-testid="signin-button"
        >
          Sign In
        </Link>
      </Box>
      <Menu>
        <MenuButton
          display={{ base: 'block', md: 'none' }}
          aria-label="menu option"
          className="hamburger-menu"
        >
          <hr />
          <hr />
          <hr />
        </MenuButton>
        <MenuList
          backgroundColor="#EEE"
          color="#111"
        >
          <Link
            className="link-btn link-btn-small link-btn-white"
            href="/login"
            data-testid="signin-button"
          >
            Sign In
          </Link>
        </MenuList>
      </Menu>
    </Flex>
  );
}
export default Header;
