/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { UserNote } from '../_interfaces/Notes';
import { Highlight } from '../_interfaces/Books';

const initState = {
  userNotes: [] as UserNote[],
  friendNotes: [] as UserNote[],
  userHighlights: [] as Highlight [],
  friendHighlights: [] as Highlight [],
  higlightsProcessed: false,
  notesProcessed: false,
};

export const noteSlice = createSlice({
  name: 'notes',
  initialState: initState,
  reducers: {
    setMyNotes: (state, action) => {
      state.userNotes = action.payload;
    },
    postNote: (state, action) => {
      state.userNotes = [state.userNotes, ...action.payload];
      state.notesProcessed = true;
      state.notesProcessed = false;
    },
    putNote: (state, action) => {
      state.userNotes.forEach((note) => {
        if (note.id === action.payload.noteId) {
          note.note = action.payload.newNote;
        }
      });
    },
    deleteNote: (state, action) => {
      state.userNotes = state.userNotes.filter((note) => note.id !== action.payload);
    },
    setFriendNotes: (state, action) => {
      state.friendNotes = action.payload;
    },
    setMyHighlights: (state, action) => {
      state.userHighlights = action.payload;
    },
    postHighlight: (state, action) => {
      state.userHighlights = [state.userHighlights, ...action.payload];
      state.higlightsProcessed = true;
      // state.higlightsProcessed = false;
    },
    deleteHighlight: (state, action) => {
      state.userHighlights = state.userHighlights
        .filter((highlight) => highlight.id !== action.payload);
    },
    setFriendHighlights: (state, action) => {
      state.friendHighlights = action.payload;
    },
  },
});

export const {
  setMyNotes,
  postNote,
  putNote,
  deleteNote,
  setFriendNotes,
  setMyHighlights,
  postHighlight,
  deleteHighlight,
  setFriendHighlights,
} = noteSlice.actions;

export default noteSlice.reducer;
