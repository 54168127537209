/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState,
} from 'react';
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import './DesktopView.scss';
import { BookContent } from '../../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      currentPage:any,
    }
  }
}
interface Content {
  book: {
    bookContent: BookContent [],
  }
}

export default function DesktopBookView() {
  const {
    currentPage,
  } = useSelector((res: BookState) => res.book.currentBook);
  const [bookView, setBookView] = useState<BookContent[]>([]);
  const bookContent = useSelector((res: Content) => res.book.bookContent);
  const [desktopPage, setDesktopPage] = useState<number>(1);
  const bg = useColorModeValue('#FFF', '#111');
  const toggleColor = useColorModeValue('#000', '#FFF');

  useEffect(() => {
    if (currentPage) {
      if (currentPage % 2 === 0) {
        setDesktopPage(currentPage - 1);
      } else {
        setDesktopPage(currentPage);
      }
    }
    if (bookContent) {
      setBookView(bookContent);
    }
  }, [currentPage]);

  const renderLeftPage = () => bookView?.map((content:any) => (
    <Box
      id="left-book-render"
      key={content.paragraphNo}
    >
      { desktopPage === content.page
        ? (
          <Box
            textAlign="justify"
          >
            <Text
              as="span"
              id={content.paragraphNo}
              fontFamily="Garamond"
              fontSize={{ base: '18px', md: '20px' }}
            >
              {parse(content.text)}
            </Text>
            {
              content.linebreak
                ? (
                  <Box>
                    <br />
                  </Box>
                ) : <Box />
            }
          </Box>
        ) : <Box />}
    </Box>
  ));
  const renderRightPage = () => bookView?.map((content:any) => (
    <Box
      id="right-book-render"
      key={content.paragraphNo}
    >
      { content.page === desktopPage + 1
        ? (
          <Box
            textAlign="justify"
          >
            <Text
              as="span"
              id={content.paragraphNo}
              fontFamily="Garamond"
              fontSize={{ base: '18px', md: '20px' }}
            >
              {parse(content.text)}
            </Text>
            {
              content.linebreak
                ? (
                  <Box>
                    <br />
                  </Box>
                ) : <Box />
            }
          </Box>
        ) : <Box />}
    </Box>
  ));

  return (
    <Box
      id="book-view"
      bg={bg}
      color={toggleColor}
    >
      <Box
        id="text-view"
        as="section"
        className="desktop-book-view"
      >
        <Box
          width="45%"
          marginLeft="5%"
          paddingRight={{ base: '2em', xl: '5em' }}
          minHeight="100%"
          position="relative"
          borderColor={toggleColor}
          borderRight="1px"
          fontSize="1.6rem"
        >
          {renderLeftPage()}
          <br />
          <br />
          <Text
            fontFamily="Garamond"
            position="absolute"
            left={{ md: '45%', xl: '45%' }}
            bottom="0em"
            id="pagination"
          >
            {desktopPage}
          </Text>
        </Box>
        <Box
          width="45%"
          marginRight="5%"
          paddingX="5px"
          minHeight="100%"
          position="relative"
          marginLeft="5px"
          fontSize="1.6rem"
          paddingLeft={{ base: '2em', xl: '5em' }}
        >
          {renderRightPage()}
          <br />
          <br />
          <Text
            fontFamily="Garamond"
            position="absolute"
            left="55%"
            bottom="0em"
            id="pagination"
          >
            {desktopPage + 1}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
