/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import {
  Box,
  SimpleGrid,
  Text,
  Avatar,
  Button,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import { User, UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

export default function SentRequests(props: {
  newSentRequestId: string,
  addToRequests: (arg: any) => void,
}) {
  const {
    newSentRequestId,
    addToRequests,
  } = props;
  const { userId } = useSelector((state: UserBasic) => state.user.userData);
  const [userRequests, setUserRequests] = useState<any>([]);
  const [hasRequests, setHasRequests] = useState<boolean>();

  const removeRequest = (selectedUserId: string) => {
    // console.log(`id ${selectedUserId}`);
    axios({
      method: 'DELETE',
      url: '/api/connections/rejectFriendRequest',
      data: {
        userId: selectedUserId,
        requesterId: userId,
      },
    })
      .then(() => {
        const updatedRequests = userRequests.filter((user: User) => user._id !== selectedUserId);
        setUserRequests(updatedRequests);
        if (updatedRequests.length === 0) {
          setHasRequests(false);
          setUserRequests([]);
          addToRequests(undefined);
        }
        const buttonElement = document.getElementById(`req_button_${selectedUserId}`) as HTMLButtonElement;
        if (buttonElement) {
          buttonElement.innerText = 'Add +';
          buttonElement.disabled = false;
        }
        const buttonDiscElement = document.getElementById(`req_disc_button_${selectedUserId}`) as HTMLButtonElement;
        if (buttonDiscElement) {
          buttonDiscElement.innerText = 'Add +';
          buttonDiscElement.disabled = false;
        }
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    if (newSentRequestId) {
      setHasRequests(false);
      axios({
        method: 'GET',
        url: `/api/connections/getUserInfo/${newSentRequestId}`,
      })
        .then((res) => {
          // console.log(res.data.foundUser);
          const { foundUser } = res.data;
          const updatedRequests = [foundUser, ...userRequests];
          // console.log(updatedRequests);
          setUserRequests(updatedRequests);
          setHasRequests(true);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  }, [newSentRequestId]);
  const retrieveFriendRequests = () => {
    axios({
      method: 'GET',
      url: `/api/connections/getSentRequests/${userId}`,
    })
      .then((res) => {
        console.log(res.data.sentRequests);
        const { sentRequests } = res.data;
        if (sentRequests.length > 0) {
          console.log(`what ${sentRequests}`);
          setUserRequests(sentRequests);
          setHasRequests(true);
        } else {
          setUserRequests([]);
          setHasRequests(false);
        }
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    if (userId) {
      console.log('running');
      retrieveFriendRequests();
    }
  }, [userId]);
  const renderResults = userRequests?.map((user:User) => (
    <Box
      textAlign="center"
      paddingY="2rem"
      bg="#222"
      borderRadius="5px"
    >
      <Box>
        <Link
          href={`/profile/${user._id}`}
          _focus={{ outline: 'none' }}
        >
          <Avatar
            size="xl"
            src={user?.image}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
              transform: 'scale(1.08)',
              transition: 'transform 0.25s ease',
            }}
            outline="none"
          />
        </Link>
      </Box>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          href={`/profile/${user?._id}`}
          className="small-text small-text-link"
        >
          {user.username}
        </Link>
      </Box>
      <CustomButton
        label="Remove"
        testId={`req_remove_${user._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            removeRequest(user?._id || '');
          }),
          id: `req_remove_${user._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  return (
    <Box>
      { hasRequests
        ? (
          <Box
            width={{ base: '90%', lg: '90%', xl: '85%' }}
            marginX="auto"
            textAlign="left"
            marginTop="4rem"
          >
            <Text
              className="medium-text medium-text-white"
            >
              Sent Requests
            </Text>
            {userRequests.length > 0
              ? (
                <SimpleGrid
                  marginTop="2em"
                  gridTemplateColumns={{
                    base: '1fr 1fr',
                    md: '1fr 1fr 1fr 1fr',
                    xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                  }}
                  gridGap={{ base: '10px', md: '20px' }}
                  rowGap={{ base: '20px', md: '20px' }}
                >
                  {renderResults}
                </SimpleGrid>
              ) : <Box />}
          </Box>
        ) : <Box />}
    </Box>
  );
}
