import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { UserBasic } from '../../_interfaces/User';
import { axiosPrivate } from '../../api/axios';
import { postBook } from '../../_reducers/bookSlice';
import BookView from '../../components/BookSections/BookView';
import Navbar from '../../components/Navbar/Navbar';

export default function OriginalBookPage() {
  const { username } = useSelector((state: UserBasic) => state.user.userData);
  const [err, setErr] = useState<string | undefined>();
  const [retrievedBook, setRetrievedBook] = useState<boolean>(false);
  const { bookId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (username) {
      axiosPrivate({
        url: '/api/book/retrieveBookById',
        method: 'POST',
        data: {
          bookId,
          username,
          isPaid: false,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            const { book, bookContent: content } = res.data;
            //   bookData, bookContent, userBook, isPaid,
            // console.log(userBook);
            dispatch(postBook(
              {
                bookData: book,
                bookContent: content,
                userBook: false,
                isPaid: false,
              },
            ));
            setRetrievedBook(true);
          }
        })
        .catch((mongoErr: { message: string }) => {
          // console.log(mongoErr.message);
          setErr(mongoErr.message);
        });
    }
  }, [bookId, username]);

  return (
    <Box as="main">
      <Navbar />
      {
        retrievedBook ? (
          <BookView />
        ) : <Box />
      }
    </Box>
  );
}
