import {
  Box,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import './SmallLogo.scss';

export default function SmallLogo(props: {color: string}) {
  const { color } = props;
  return (
    <Box
      className="small-logo"
      color={color}
    >
      <Text
        as="p"
      >
        - Theologian&apos;s Pen -
      </Text>
    </Box>
  );
}
