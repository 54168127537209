/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  RefObject,
  createRef,
  ChangeEvent,
} from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  SimpleGrid,
  Text,
  Flex,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import './ContactUs.scss';

const CheckCircle = styled(CheckCircleFill)`
  color: #000;
  width: 75px;
  color: green;
`;

function ContactForm() {
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [emailErr, setEmailErr] = useState<undefined | string>();
  const [nameErr, setNameErr] = useState<undefined | string>();
  const [messageErr, setMessageErr] = useState<undefined | string>();
  const [errorMsg, setErrorMsg] = useState<undefined | string>();
  const [successMsg, setSuccessMsg] = useState<undefined | string>();
  const recaptchaRef:any = createRef();
  const [recaptchaErr, setRecaptchaErr] = useState<undefined | string>();
  const {
    isOpen: isOpenEmailModal,
    onOpen: onOpenEmailModal,
    onClose: onCloseEmailModal,
  } = useDisclosure();

  const encode = (data:any) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

  const validateEmail = (email:string | undefined) => {
    const errors:{ email: string | undefined } = { email: undefined };
    if (!email) {
      errors.email = ' Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      errors.email = ' Invalid email address';
    }
    return errors;
  };

  const handleChange = (
    event: ChangeEvent<{value:string}>,
    ref: RefObject<HTMLInputElement | HTMLTextAreaElement>,
    errorStateFunc: (value: React.SetStateAction<string | undefined>) => void,
  ) => {
    const elementRef = ref;
    if (elementRef.current) {
      elementRef.current.value = event.target.value;
      if (errorStateFunc) {
        errorStateFunc(undefined);
      }
    }
  };

  // const updateRecaptchaRef = (recaptcha: any) => {
  //   recaptchaRef = recaptcha;
  // }

  const handleSubmit = (event:InputEvent) => {
    event.preventDefault();
    let errorFound = false;
    const myEmail = emailRef?.current?.value;
    const firstName = firstNameRef?.current?.value;
    const myMessage = messageRef?.current?.value;
    if (!firstName) {
      setNameErr(' Name field cannot be blank!');
      errorFound = true;
    }
    if (!myMessage) {
      setMessageErr(' Message field cannot be blank!');
      errorFound = true;
    }
    const errors = validateEmail(myEmail);
    if (errors.email) {
      setEmailErr(errors.email);
      errorFound = true;
    }

    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue === '') {
      setRecaptchaErr(' Please verify that you are not a robot.');
      errorFound = true;
    }
    if (errorFound) return;
    const contactForm = {
      contactName: firstName,
      contactEmail: myEmail,
      contactMessage: myMessage,
    };
    // const myForm = document.getElementById("contact-form");
    // const formData = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      // body: new URLSearchParams(formData).toString(),
      body: encode({ 'form-name': 'contact-form', 'g-recaptcha-response': recaptchaValue, ...contactForm }),
    })
      .then(() => {
        setSuccessMsg('Thank you! Your submission has been received!');
        if (emailRef.current) emailRef.current.value = '';
        if (messageRef.current) messageRef.current.value = '';
        if (firstNameRef.current) firstNameRef.current.value = '';
      })
      .catch((error) => {
        setErrorMsg(error);
      });
  };
  const removeRecaptchaWarning = () => {
    setRecaptchaErr(undefined);
  };
  const renderSuccessForm = () => (
    <Flex
      justifyContent="center"
      flexDir="column"
      minHeight="400px"
      alignItems="center"
    >
      <CheckCircle />
      <Text
        marginY="10px"
        textAlign="center"
        className="medium-text medium-text-black"
      >
        Thank you for your input!
      </Text>
      <Text
        marginY="5px"
        textAlign="center"
        className="medium-text medium-text-black"
      >
        Your submission has been received!
      </Text>
    </Flex>
  );
  const renderDetailsForm = () => (
    <Box
      as="article"
      className="contact-form-container"
    >
      <Text
        as="h1"
        className="section-heading"
        marginY="20px"
        textAlign="center"
      >
        Contact Us
      </Text>
      <Text
        as="p"
        className="small-text small-text-black"
        textAlign="center"
      >
        <i>Fields marked with an asterisk (*) are required.</i>
      </Text>
      <Box
        as="form"
        marginX="auto"
        marginTop="2em"
        name="contact-form"
        id="contact-form"
        data-netlify="true"
        data-netlify-recaptcha="true"
        data-netlify-honeypot="bot-field"
        flexDir={{ base: 'column', md: 'row' }}
        method="Post"
        onSubmit={handleSubmit as any}
        paddingBottom="5rem"
      >
        <SimpleGrid
          gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap="1rem"
        >
          <Box
            as="article"
          >
            <Text
              className="small-text small-text-black small-text-bold"
              marginY="5px"
            >
              First name *
            </Text>
            <input
              ref={firstNameRef}
              aria-label="Your First Name"
              name="contactName"
              type="text"
              placeholder="First Name"
              className="input-custom"
              onChange={(e) => { handleChange(e, firstNameRef, setNameErr); }}
            />
            {
              nameErr
                ? (
                  <Text
                    marginTop=".5rem"
                    id="name-error"
                    className="small-text small-text-error"
                  >
                    Error:
                    {nameErr}
                  </Text>

                ) : <Box />
            }
          </Box>
          <Box
            as="article"
          >
            <Text
              className="small-text small-text-black small-text-bold"
              marginY="5px"
            >
              Email *
            </Text>
            <input
              ref={emailRef}
              className="input-custom"
              aria-label="Your email"
              name="contactEmail"
              type="email"
              placeholder="John.Owen@EMAIL.COM"
              onChange={(e) => { handleChange(e, emailRef, setEmailErr); }}
            />
            {
              emailErr
                ? (
                  <Text
                    marginTop=".5rem"
                    id="email-error"
                    className="small-text small-text-error"
                  >
                    Error:
                    {emailErr}
                  </Text>
                ) : <Box />
            }
          </Box>
        </SimpleGrid>
        <Box
          as="article"
          marginTop="10px"
        >
          <Text
            className="small-text small-text-black small-text-bold"
            marginY="5px"
          >
            Message *
          </Text>
          <textarea
            ref={messageRef}
            aria-label="Your Message"
            name="contactMessage"
            onChange={(e) => { handleChange(e, messageRef, setErrorMsg); }}
            className="input-custom"
          />
          {
            messageErr
              ? (
                <Text
                  marginTop=".5rem"
                  id="msg-error"
                  className="small-text small-text-error"
                >
                  Error:
                  {messageErr}
                </Text>
              ) : <Box />
          }
        </Box>
        {
            firstNameRef?.current?.value
            && emailRef?.current?.value
            && messageRef?.current?.value ? (
              <Box
                marginTop="1em"
                cursor="pointer"
              >
                <ReCAPTCHA
                  ref={recaptchaRef as any}
                  sitekey={(window as any).env.SITE_RECAPTCHA_KEY}
                  id="google-contact-recaptcha"
                  size="compact"
                  onChange={removeRecaptchaWarning}
                />
              </Box>
              ) : <Box />
          }
        {
          recaptchaErr
            ? (
              <Text
                marginY="1rem"
                id="recaptcha-error"
                className="small-text small-text-error"
              >
                Error:
                {recaptchaErr}
              </Text>
            ) : <Box />
        }
        <CustomButton
          buttonType="submit"
          buttonProps={{
            id: 'submit-contact-form',
            value: 'Submit',
            style: {
              position: 'absolute',
              bottom: '1rem',
              right: '2.5rem',
            },
          }}
          buttonColor="black"
          label="Submit"
          testId="submit-contract-form"
          spinnerId={undefined}
          size="medium"
        />
        {
          errorMsg
            ? (
              <Text
                marginY="1em"
                className="small-text small-text-error"
              >
                Error:
                {errorMsg}
              </Text>
            ) : <Box />
        }
      </Box>

    </Box>
  );
  return (
    <Box
      as="section"
    >
      <CustomButton
        label="Contact Us"
        testId="contact-us-button"
        buttonType="button"
        buttonColor="black"
        buttonProps={{
          onClick: onOpenEmailModal,
        }}
        spinnerId={undefined}
        size="medium"
      />
      <Box>
        <Modal
          isOpen={isOpenEmailModal}
          onClose={onCloseEmailModal}
          size={{ base: '95%', lg: '1023px' }}
        >
          <ModalOverlay />
          <ModalContent
            backgroundColor="#F1F1F1"
            width={{ base: '95%', lg: '1023px' }}
          >
            <ModalCloseButton />
            <ModalBody
              backgroundColor="#F1F1F1"
            >
              { successMsg
                ? (
                  <Box>
                    {renderSuccessForm()}
                  </Box>
                )
                : (
                  <Box>
                    {renderDetailsForm()}
                  </Box>
                )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}
export default ContactForm;
