import React, { useEffect } from 'react';
import {
  Box,
  Wrap,
  WrapItem,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function TermsMenu() {
  const navigate = useNavigate();
  useEffect(() => {
    const routeName = window.location.href.split('/')[3];
    const boxElement = document.getElementById(routeName);
    if (boxElement) {
      boxElement.style.border = '1px solid #000';
    }
  }, []);
  return (
    <Box
      width={{ base: '95%', md: '90%' }}
      margin="auto"
      paddingY="2em"
    >
      <Box
        fontWeight="500"
      >
        <Wrap justify="center">
          <WrapItem
            cursor="pointer"
            onClick={() => { navigate('/do-not-sell'); }}
          >
            <Center
              w={{ base: '100px', md: '175px' }}
              h={{ base: '50px', md: '80px' }}
              textAlign="center"
              fontSize={{ base: '12px', sm: '14px', md: '16px' }}
              color="#000"
              bg="red.300"
              _hover={{ bg: 'red.200' }}
              id="do-not-sell"
            >
              Do Not Sell My Info
            </Center>
          </WrapItem>
          <WrapItem
            cursor="pointer"
            onClick={() => { navigate('/privacy'); }}
          >
            <Center
              w={{ base: '100px', md: '175px' }}
              h={{ base: '50px', md: '80px' }}
              textAlign="center"
              fontSize={{ base: '12px', sm: '14px', md: '16px' }}
              color="#000"
              bg="green.300"
              _hover={{ bg: 'green.200' }}
              id="privacy"
            >
              Privacy
            </Center>
          </WrapItem>
          <WrapItem
            cursor="pointer"
            onClick={() => { navigate('/terms'); }}
          >
            <Center
              w={{ base: '100px', md: '175px' }}
              h={{ base: '50px', md: '80px' }}
              textAlign="center"
              fontSize={{ base: '12px', sm: '14px', md: '16px' }}
              color="#000"
              bg="orange.300"
              _hover={{ bg: 'orange.200' }}
              id="terms"
            >
              Terms of Service
            </Center>
          </WrapItem>
          <WrapItem
            cursor="pointer"
            onClick={() => { navigate('/cookies'); }}
          >
            <Center
              w={{ base: '100px', md: '175px' }}
              h={{ base: '50px', md: '80px' }}
              textAlign="center"
              fontSize={{ base: '12px', sm: '14px', md: '16px' }}
              color="#000"
              bg="blue.300"
              _hover={{ bg: 'blue.200' }}
              id="cookies"
            >
              Cookies
            </Center>
          </WrapItem>
          <WrapItem
            cursor="pointer"
            onClick={() => { navigate('/acceptable-use'); }}
          >
            <Center
              w={{ base: '100px', md: '175px' }}
              h={{ base: '50px', md: '80px' }}
              textAlign="center"
              fontSize={{ base: '12px', sm: '14px', md: '16px' }}
              color="#000"
              bg="yellow.300"
              _hover={{ bg: 'yellow.200' }}
              id="acceptable-use"
            >
              Acceptable Use
            </Center>
          </WrapItem>
        </Wrap>
      </Box>
    </Box>
  );
}

export default TermsMenu;
