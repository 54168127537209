import React from 'react';
import {
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import ImagePreview from '../../../../assets/images/preview.jpg';
// import BookOne from '../../../assets/images/book_1.png';
import BookTwo from '../../../../assets/images/book_2.jpg';
// import HighlightGif from '../../../assets/gifs/highlight.gif';
import HighlightGif from '../../../../assets/gifs/highlight2.gif';
import './HowItWorks.scss';

export default function HowItWorks() {
  return (
    <Box
      as="section"
      className="howitworks-container"
    >
      <Text
        as="h1"
        className="section-heading section-heading-white"
      >
        How It Works
      </Text>
      <Box
        as="article"
        display={{ base: 'block', lg: 'flex' }}
        width={{ base: '100%', xl: '1400px' }}
        justifyContent="space-between"
        marginX="auto"
        marginTop="6rem"
      >
        <Image
          alt="App Home Page Preview"
          data-testid="first-preview"
          id="first-preview"
          src={ImagePreview}
          width={{ base: '95%', lg: '49%' }}
          _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
          cursor="pointer"
          loading="lazy"
        />
        <Box
          width={{ base: '90%', xl: '49%' }}
          display="flex"
          flexDir="column"
          justifyContent="center"
        >
          <Text
            as="p"
            className="large-text large-text-white"
            height="inherit"
          >
            1. Choose the Works of Your Favorite Theologian
          </Text>
        </Box>
      </Box>
      <Box
        as="article"
        display={{ base: 'block', lg: 'flex' }}
        width={{ base: '100%', xl: '1400px' }}
        marginY="100px"
        justifyContent="center"
      >
        <Box
          width={{ base: '90%', lg: '49%' }}
          display={{ base: 'none', lg: 'flex' }}
          justifyContent="center"
          flexDir="column"
        >
          <Text
            as="p"
            className="large-text large-text-white"
            width="90%"
          >
            2. Enjoy the Works of Your Favorite Theologian in an Easy-to-Read Format
          </Text>
        </Box>
        <Image
          alt="book reader preview"
          id="book-one"
          src={HighlightGif}
          maxW={{ base: '95%', lg: '49%' }}
          marginX={{ base: 'auto', lg: 'none' }}
          _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
          cursor="pointer"
          loading="lazy"
        />
        <Box
          width="100%"
          textAlign="left"
          placeSelf="center"
          display={{ base: 'block', lg: 'none' }}
        >
          <Text
            as="p"
            width={{ base: '90%', lg: '400px' }}
            className="large-text large-text-white"
          >
            2. Enjoy the Works of Your Favorite Theologian in an Easy-to-Read Format
          </Text>
        </Box>
      </Box>
      <Box
        as="article"
        display={{ base: 'block', lg: 'flex' }}
        width={{ base: '100%', xl: '1400px' }}
        marginX="auto"
        marginTop="100px"
        justifyContent="spacebetween"
      >
        <Image
          alt="Another book reader preview"
          id="book-two"
          src={BookTwo}
          width={{ base: '95%', lg: '49%' }}
          _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
          cursor="pointer"
          loading="lazy"
        />
        <Box
          width={{ base: '90%', xl: '49%' }}
          display="flex"
          flexDir="column"
          justifyContent="center"
        >
          <Text
            as="p"
            className="large-text large-text-white"
          >
            3. View Others&apos; Thoughts and Share Your Own
          </Text>
        </Box>

      </Box>
    </Box>
  );
}
