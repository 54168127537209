import { configureStore } from '@reduxjs/toolkit';
import recaptchaReducer from '../_reducers/recaptchaSlice';
import bookReducer from '../_reducers/bookSlice';
import userReducer from '../_reducers/userSlice';
import noteReducer from '../_reducers/NoteSlice';
// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: {
    recaptcha: recaptchaReducer,
    book: bookReducer,
    user: userReducer,
    note: noteReducer,
  },
});
