import React from 'react';
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import MuseumGif from '../../../../assets/gifs/museum.gif';
import BookGif from '../../../../assets/gifs/book.gif';
import LayersGif from '../../../../assets/gifs/layers.gif';
import QuotationGif from '../../../../assets/gifs/quotation.gif';
import './WhoWeAre.scss';
import SmallLogo from '../../../../components/Logos/SmallLogo/SmallLogo';

function WhoWeAre() {
  return (
    <Box
      as="section"
      className="section-container"
      paddingX="1rem"
    >
      <Flex
        width="100%"
        height="300px"
        justifyContent="center"
        flexDir="column"
        alignItems="center"
        textAlign="center"
      >
        <SmallLogo color="grey" />
        <Text
          className="section-heading section-heading-black"
          marginY="2rem"
        >
          Who We Are
        </Text>
        <Text
          data-testid="subtext-WhoWeAre"
          as="p"
          className="medium-text medium-text-black"
          marginX="auto"
        >
          We provide a collection of history&apos;s finest
          works from the greatest theologians who ever lived.
          <br />
          Easily access and read your favorite theologian&apos;s works and more.
        </Text>
      </Flex>
      <SimpleGrid
        id="animated-features"
        marginX="auto"
        paddingBottom="120px"
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr 1fr' }}
        gridGap="2em"
        maxW={{ base: '90%', lg: '1200px' }}
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center', xl: 'start' }}
        >
          <Image
            width={{ base: '25%', xl: '50%' }}
            src={MuseumGif}
            alt="Gif of museum"
            loading="lazy"
          />
          <Text
            as="p"
            marginBottom="10px"
            className="medium-text medium-text-black"
          >
            Read Various Theological Works at the Click of a Button
          </Text>
          <Text
            as="p"
            className="small-text small-text-grey"
          >
            Choose the books that you want to read and instantly have it at your fingertips.
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center', xl: 'start' }}
        >
          <Image
            width={{ base: '25%', xl: '50%' }}
            src={LayersGif}
            alt="Gif of Stack of Books"
            loading="lazy"
          />
          <Text
            as="p"
            marginBottom="10px"
            className="medium-text medium-text-black"
          >
            Keep Your Next Book in Queue

          </Text>
          <Text
            as="p"
            className="small-text small-text-grey"
          >
            You can queue books for later reading so that you don&apos;t
            have to worry about what to read next.
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center', xl: 'start' }}
        >
          <Image
            width={{ base: '25%', xl: '50%' }}
            src={BookGif}
            alt="Gif of Book and its pages flipping"
            loading="lazy"
          />
          <Text
            as="p"
            marginBottom="10px"
            className="medium-text medium-text-black"
          >
            Theologians&apos; Works are Individualized

          </Text>
          <Text
            as="p"
            className="small-text small-text-grey"
          >
            Theologian&apos;s works are divided into individual books,
            allowing for easier reading.
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center', xl: 'start' }}
        >
          <Image
            width={{ base: '25%', xl: '50%' }}
            src={QuotationGif}
            alt="animated quotation marks"
            loading="lazy"
          />
          <Text
            as="p"
            marginBottom="10px"
            className="medium-text medium-text-black"
          >
            Share and Annotate Your Thoughts

          </Text>
          <Text
            as="p"
            className="small-text small-text-grey"
          >
            Share your thoughts and insights with others by adding annotations.
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default WhoWeAre;
