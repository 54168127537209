/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';
// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Heading,
  Image,
  Text,
  Link,
} from '@chakra-ui/react';

import { axiosPrivate } from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import { BookListItem } from '../../_interfaces/Books';
import HomeMenu from './sections/HomeMenu';
import BookList from '../../components/BookList/BookList';
import TrialMode from '../../components/TrialMode/TrialMode';
import './Homepage.scss';

function HomePage() {
  const [featuredBook, setFeaturedBook] = useState<BookListItem | undefined>();
  const [latestLiveBooks, setLatestLiveBooks] = useState<BookListItem[]>([]);
  const [comingSoonBooks, setComingSoonBooks] = useState<BookListItem[]>([]);
  const [myBooks, setMyBooks] = useState<BookListItem[]>([]);
  const [myBooksErr, setMyBooksErr] = useState<string | undefined>();
  const [FeaturedBookErr, setFeaturedBookErr] = useState<string | undefined>();
  const [liveBookErr, setLiveBookErr] = useState<string | undefined>();
  const [soonBookErr, setSoonbookErr] = useState<string | undefined>();
  const user = useSelector((state:any) => state.user.userData);

  const navigate = useNavigate();

  const awaitFeatureBook = async () => {
    const result = await axiosPrivate.get('/api/featuredBook/getBook');
    return result;
  };

  const asyncBookList = async (
    reqUrl: string,
  ) => {
    const result = await axiosPrivate.get(reqUrl);
    return result;
  };
  const asyncMyBooks = async () => {
    const result = await axiosPrivate.post('/api/userbook/userbooksbyUsername', { username: user.username });
    return result;
  };

  useEffect(() => {
    if (user.username) {
      const fetchData = async () => {
        const [
          allMyBooks,
          thisFeaturedBook,
          liveBooks,
          soonBooks,
        ] = await Promise.allSettled([
          asyncMyBooks(),
          awaitFeatureBook(),
          asyncBookList('/api/book/latestLiveBooks'),
          asyncBookList('/api/book/comingSoonBooks'),
        ]);
        if (thisFeaturedBook.status === 'rejected') {
          setFeaturedBookErr(thisFeaturedBook.reason.message);
        } else {
          setFeaturedBook(thisFeaturedBook.value.data.featuredBook);
        }
        if (liveBooks.status === 'rejected') {
          setLiveBookErr(liveBooks.reason.message);
        } else {
          setLatestLiveBooks(liveBooks.value.data.books);
        }
        if (allMyBooks.status === 'rejected') {
          setMyBooksErr(allMyBooks.reason.message);
        } else {
          setMyBooks(allMyBooks.value.data.books);
        }
        if (soonBooks.status === 'rejected') {
          setSoonbookErr(soonBooks.reason.message);
        } else {
          setComingSoonBooks(soonBooks.value.data.books);
        }
      };

      fetchData();
    }
  }, [user.username]);

  return (
    <Box
      className="homepage-container"
    >
      <Navbar />
      <TrialMode />
      {
        featuredBook ? (
          <Box
            display={{ base: 'block', lg: 'flex' }}
            marginX="auto"
            justifyContent="space-between"
            placeItems="center"
            paddingY="4em"
          >
            <Box
              maxW="75%"
              marginX="auto"
              paddingY="40px"
              display={{ base: 'block', lg: 'none' }}
            >
              <Image
                src={featuredBook.coverImage}
                cursor="pointer"
                width={{ base: '180px', md: '50%' }}
                marginX="auto"
                data-testid="home-feature-image-mobile"
                backgroundColor="#111"
                _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
                onClick={() => { navigate(`/book/${featuredBook?.bookId}`); }}
                loading="lazy"
              />
            </Box>
            <Box
              data-testid="home-feature-title"
              maxWidth={{
                base: '90%', lg: '45%',
              }}
              marginX="auto"
            >
              <Text
                as="h1"
                className="large-text large-text-white"
                fontFamily="PTSerif"
              >
                Featured Book
              </Text>
              <Heading
                fontSize={{
                  base: '28px', sm: '35px', md: '42px', lg: '50px', xl: '65px',
                }}
                textTransform="uppercase"
                fontFamily="PTSerif"
              >
                {featuredBook.title}
              </Heading>

              <Link
                borderRadius="25px"
                className="link-btn link-btn-medium link-btn-night"
                href={`/book/${featuredBook?.bookId}`}
              >
                Read Now
              </Link>

            </Box>
            <Box
              width="40%"
              margin="auto"
              display={{ base: 'none', lg: 'block' }}
            >
              <Image
                cursor="pointer"
                src={featuredBook?.coverImage}
                width={{ lg: '300px', xl: '350px' }}
                margin="auto"
                data-testid="home-feature-image"
                _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
                onClick={() => { navigate(`/book/${featuredBook.bookId}`); }}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            {
              FeaturedBookErr
                ? (
                  <Text
                    height="200px"
                    className="section-heading"
                  >
                    {FeaturedBookErr}
                  </Text>
                ) : <Box />
            }
          </Box>
        )
      }
      <HomeMenu />
      <BookList bookList={myBooks} type="mybooks" title="Continue Reading" bookErr={myBooksErr} />
      <BookList bookList={latestLiveBooks} type="live" title="Newest Books" bookErr={liveBookErr} />
      <BookList bookList={comingSoonBooks} type="coming_soon" title="Coming Soon" bookErr={soonBookErr} />
    </Box>
  );
}
export default HomePage;
