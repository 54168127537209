import React from 'react';
import {
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

interface BookState {
  book: {
    currentBook: {
      author: string,
      title: string,
    }
  }
}

export default function BookHeader() {
  // const { author, title } = props;
  const author = useSelector((res: BookState) => res.book.currentBook.author);
  const title = useSelector((res: BookState) => res.book.currentBook.title);
  const toggleColor = useColorModeValue('#111', '#FFF');
  return (
    <Box>
      <Box
        marginX="auto"
        display={{ base: 'none', md: 'block' }}
        paddingY="10px"
        justifyContent="right"
        paddingLeft={{ base: '0', lg: '4em' }}
      >
        <Text
          fontFamily="Garamond"
          color={toggleColor}
          fontSize="24px"
          fontWeight="400"
        >
          {title}
          {' '}
          /
          {' '}
          {author}
        </Text>
      </Box>
      <Box
        marginX="auto"
        display={{ base: 'block', md: 'none' }}
        paddingY="10px"
        justifyContent="right"
        paddingLeft={{ base: '0', lg: '4em' }}
      >
        <Text
          fontFamily="Garamond"
          color={toggleColor}
          fontSize="20px"
          fontWeight="400"
        >
          {title}
        </Text>
        <Text
          fontFamily="Garamond"
          color={toggleColor}
          fontSize="18px"
          fontWeight="400"
        >
          {author}
        </Text>
      </Box>
    </Box>
  );
}
