/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Spinner,
  Text,
  Stack,
  Button,
  Avatar,
  useColorModeValue,
  Link,
  Image,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,

} from '@chakra-ui/react';
import heic2any from 'heic2any';
import { AxiosRequestConfig } from 'axios';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import FriendList from '../../components/FriendsList/FriendsList';
import { UserBasic } from '../../_interfaces/User';
import { BookListItem } from '../../_interfaces/Books';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import CustomButton from '../../components/CustomButton/CustomButton';
import './Account.scss';

export default function BookList() {
  const [userLoading, setUserLoading] = useState(true);
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [avatarImg, setAvatarImg] = useState<File & { preview: string; }>();
  const [isPaidUser, setIsPaidUser] = useState<boolean>();
  const [startingPage, setStartingPage] = useState(0);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [bookIdWipe, setBookIdWipe] = useState<string>();
  const [pageCount, setPageCount] = useState<number>(0);
  const [numBooks, setNumBooks] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [myBooks, setMyBooks] = useState<BookListItem []>([]);
  const [contentIdWipe, setContentIdWipe] = useState<string |undefined>('');
  const user = useSelector((state: UserBasic) => state.user.userData);
  const itemsPerPage = 8;

  const {
    isOpen: isOpenWipeBook,
    onOpen: onOpenWipeBook,
    onClose: onCloseWipeBook,
  } = useDisclosure();

  const wipeBook = () => {
    axios({
      method: 'DELETE',
      url: '/api/userbook/deleteById',
      data: {
        userBookId: bookIdWipe,
        contentId: contentIdWipe,
      },
    })
      .then(() => {
        const updatedBookList = myBooks?.filter((book:BookListItem) => book._id !== bookIdWipe);
        setMyBooks([...updatedBookList]);
        onCloseWipeBook();
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const handleBookWipe = (userBookId:string, contentId:string | undefined) => {
    setBookIdWipe(userBookId);
    setContentIdWipe(contentId);
    onOpenWipeBook();
  };
  const handlePageClick = (event:any) => {
    if (myBooks?.length > 0) {
      const newOffset = (event.selected * itemsPerPage) % numBooks;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
      // );
      axios({
        method: 'POST',
        url: '/api/userbook/retrieveIndexBooks',
        data: {
          username: user.username,
          itemsPerPage,
          offsetIndex: newOffset,
        },
      })
        .then((res) => {
          // eslint-disable-next-line no-console
          // console.log(res.data.books);
          const { books } = res.data;
          // const endOffset = itemOffset + itemsPerPage;
          // console.log(`Loading items from ${itemOffset} to ${newOffset}`);
          // setBooksDisplayed(books.slice(itemOffset, endOffset));
          // setPageCount(Math.ceil(bookCount / itemsPerPage));
          setItemOffset(newOffset);
          setMyBooks(books);
          setLoadingBooks(false);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  };
  const geMyBooks = () => {
    axios({
      method: 'POST',
      url: '/api/userbook/initIndexBooks',
      data: {
        username: user.username,
        itemsPerPage,
      },
    })
      .then((res) => {
        // eslint-disable-next-line no-console
        // console.log(res.data.books);
        const { books, bookCount } = res.data;
        const endOffset = itemOffset + itemsPerPage;
        // setBooksDisplayed(books.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(bookCount / itemsPerPage));
        setNumBooks(bookCount);
        setItemOffset(endOffset);
        setMyBooks(books);
        console.log(books);
        setLoadingBooks(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const checkUser = () => {
    if (user?.roles?.includes('2023')
    || user?.roles?.includes('2024')
    || user?.roles?.includes('2025')
    ) {
      setIsPaidUser(true);
      return true;
    }
    setIsPaidUser(false);
    return false;
  };
  useEffect(() => {
    if (user.image) {
      checkUser();
      setUserLoading(false);
      geMyBooks();
    }
  }, []);
  const renderBookList = () => myBooks?.map((book:BookListItem) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      // width="180px"
      // height="285px"
      marginX="auto"
    >
      <Link
        href={`/book/${book.bookId}`}
      >
        {book?.coverImage
          ? (
            <Image
              cursor="pointer"
              borderRadius="5px"
              src={book.coverImage}
              // width="180px"
              // height="285px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
              loading="lazy"
            />
          ) : (
            <Flex
              backgroundColor="#1F1F1F"
              color="#FFF"
              // width="180px"
              // height="285px"
              borderRadius="5px"
              justifyContent="center"
              flexDir="column"
              textAlign="center"
              paddingX="5px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            >
              <Text
                fontSize="16px"
                fontFamily="PTSerif"
              >
                {book.title}
              </Text>
              <Text
                marginTop="10px"
                fontSize="14px"
                fontFamily="PTSerif"
              >
                {book.author}
              </Text>
            </Flex>
          )}
      </Link>
      <Box
        marginY="1em"
      >
        <Menu>
          <MenuButton
            className="link-btn link-btn-night link-btn-small"
          >
            Options
          </MenuButton>
          <MenuList
            bg="#222"
            outline="none"
            border="none"
          >
            <Link
              className="link-btn link-btn-night link-btn-small"
              href={`/book/${book.bookId}`}
            >
              Open Book
            </Link>
            <MenuItem
              onClick={() => { handleBookWipe(book._id, book.contentId); }}
              className="link-btn link-btn-night link-btn-small"
            >
              Wipe Book
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  ));
  return (
    <Box as="section">
      <SimpleGrid
        marginY="4rem"
        gridTemplateColumns={{
          base: '1fr 1fr',
          sm: '1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        width={{ base: '90%', lg: '90%', xl: '75%' }}
        gridGap={{ base: '10px', md: '20px' }}
        marginX="auto"
      >
        {renderBookList()}
      </SimpleGrid>
      <CustomPagination
        pageCount={pageCount}
        startingPage={startingPage}
        handlePageClick={handlePageClick}
      />
      <Modal isOpen={isOpenWipeBook} onClose={onCloseWipeBook}>
        <ModalOverlay />
        <ModalContent
          bg="#222"
        >
          <ModalHeader className="medium-text medium-text-white">Wipe Book</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              className="small-text small-text-white"
            >
              Are you sure you want to wipe this book clean removing all highlights and notes
              as well as removing it from your list?
            </Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="Cancel"
              testId={`wipe_cancel_${bookIdWipe}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseWipeBook();
                }),
                id: `wipe_cancel_${bookIdWipe}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Wipe"
              testId={`wipe_book_${bookIdWipe}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  wipeBook();
                }),
                id: `wipe_book_${bookIdWipe}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
