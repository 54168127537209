/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Link,
} from '@chakra-ui/react';
import Navbar from '../../components/Navbar/Navbar';
import ImagePreview from '../../assets/images/feature_sample.jpg';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';
import './SubSuccess.scss';

export default function SubSuccess() {
  return (
    <Box>
      <Navbar />
      <Box
        as="main"
        className="sub-success-container"
      >
        <SmallLogo color="white" />
        <Image
          data-testid="app-preview"
          src={ImagePreview}
          width="200px"
          marginX="auto"
        />
        <Text
          marginTop="1rem"
          className="large-text large-text-white"
        >
          Subscription Successful!
        </Text>
        <Box
          as="article"
          marginTop="2rem"
        >
          <Text
            maxW={{ base: '95%', md: '800px' }}
            marginX="auto"
            textAlign="left"
            data-testid="standard-feature-one"
            className="medium-text medium-text-white"
          >
            You Can Now:
          </Text>
          <Text
            maxW={{ base: '95%', md: '800px' }}
            marginX="auto"
            textAlign="left"
            data-testid="standard-feature-one"
            className="small-text small-text-white"
          >
            - Annotate the books as you like and save any highlight you make
            <br />
            - Automatically create a saved copy of the book you are reading to your account
            <br />
            - Access to premium books
          </Text>
          <Text
            maxW={{ base: '95%', md: '800px' }}
            marginX="auto"
            className="small-text small-text-white"
            textAlign="left"
            fontStyle="italic"
          >
            - And many more to come
          </Text>
        </Box>
        <Link
          href="/home"
          className="link-btn link-btn-small link-btn-night"
          marginTop="2rem"
        >
          Start Reading Today!
        </Link>
      </Box>
    </Box>
  );
}
