import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { UserBasic } from '../../_interfaces/User';
import { axiosPrivate } from '../../api/axios';
import { postBook } from '../../_reducers/bookSlice';
import { setMyHighlights, setMyNotes } from '../../_reducers/NoteSlice';
import BookView from '../../components/BookSections/BookView';
import HighlightEdit from '../../components/Highlights/HighlightEdit';
import Highlights from '../../components/Highlights/Highlights';
import Navbar from '../../components/Navbar/Navbar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

export default function DetailedBookPage() {
  const { username, isPaid, userId } = useSelector((state: UserBasic) => state.user.userData);
  const [err, setErr] = useState<string | undefined>();
  const [retrievedBook, setRetrievedBook] = useState<boolean>(false);
  const { bookId } = useParams();
  const dispatch = useDispatch();

  const createUserBook = (bookContentId: string) => {
    axiosPrivate({
      method: 'POST',
      url: '/api/userbook/createUserBook',
      data: {
        contentId: bookContentId,
        bookId,
        username,
        currentPageNumber: 1,
        userId,
      },
    })
      .then((res) => {
        const { bookContent, book } = res.data;
        dispatch(postBook(
          {
            bookData: book,
            bookContent,
            userBook: true,
            isPaid: true,
          },
        ));
        setRetrievedBook(true);
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        // console.log(bookErr);
        setErr(bookErr.message);
      });
  };

  useEffect(() => {
    if (username) {
      axiosPrivate({
        url: '/api/book/retrieveBookById',
        method: 'POST',
        data: {
          bookId,
          username,
          isPaid,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            const { book, userBook, bookContent: content } = res.data;
            //   bookData, bookContent, userBook, isPaid,
            // console.log(userBook);
            if (isPaid === true) {
              if (userBook) {
                dispatch(postBook(
                  {
                    bookData: book,
                    bookContent: content,
                    userBook,
                    isPaid,
                  },
                ));
                // console.log(book.highlights);
                dispatch(setMyNotes(book.userNotes));
                dispatch(setMyHighlights(book.highlights));
                setRetrievedBook(true);
              } else {
                createUserBook(book.contentId);
              }
            } else {
              dispatch(postBook(
                {
                  bookData: book,
                  bookContent: content,
                  userBook,
                  isPaid,
                },
              ));
              setRetrievedBook(true);
            }
          }
        })
        .catch((mongoErr: { message: string }) => {
          // console.log(mongoErr.message);
          setErr(mongoErr.message);
        });
    }
  }, [bookId, username]);

  return (
    <Box as="main">
      <Navbar />
      {
        retrievedBook ? (
          <>
            <BookView />
            <Highlights />
            <HighlightEdit />
          </>
        ) : <LoadingSpinner />
      }
    </Box>
  );
}
