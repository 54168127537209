/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  // SimpleGrid,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FreeTier from './FreeTier';
import StandardTier from './StandardTier';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Navbar from '../../components/Navbar/Navbar';
import { UserBasic } from '../../_interfaces/User';
import './Subscription.scss';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';

export default function Subscription() {
  const { isPaid } = useSelector((state: UserBasic) => state.user.userData);
  return (
    <Box>
      <Navbar />
      <Box
        // backgroundColor="#000"
        className="subscription-page-con"
        as="main"
      >
        <SmallLogo color="white" />
        <Box>
          {isPaid
            ? (
              <Box>
                <Text
                  textAlign="center"
                  marginTop="1rem"
                  className="medium-text medium-text-white medium-text-bold"
                >
                  You are currently subscribed!
                </Text>
                <Text
                  textAlign="center"
                  marginTop="1rem"
                  className="medium-text medium-text-white"
                >
                  Subscription: Standard Package
                </Text>
              </Box>
            ) : (
              <Box>
                <Text
                  textAlign="center"
                  // color="#FFF"
                  marginTop="1rem"
                  className="medium-text medium-text-white medium-text-bold"
                >
                  You are currently not Subscribed!
                </Text>
                <Text
                  textAlign="center"
                  // color="#FFF"
                  marginTop="1rem"
                  className="medium-text medium-text-white"
                >
                  Check out our subscription below!
                </Text>
              </Box>
            )}
        </Box>
        <Grid
          width={{ base: '100%', md: '750px' }}
          minH={{ base: '350px', md: '650px' }}
          marginX="auto"
          marginTop="2em"
          gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
          // backgroundColor="#000"
          paddingX="1rem"
          color="#FFF"
        >
          <FreeTier />
          <StandardTier />
        </Grid>
      </Box>
    </Box>
  );
}
