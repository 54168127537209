/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Input,
  Flex,
  Text,
  SimpleGrid,
  Image,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  WrapItem,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { axiosPrivate } from '../../api/axios';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { BookListItem } from '../../_interfaces/Books';
import SearchIcon from '../../_assets/images/search_icon.png';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';

export default function BookSearchRes() {
  const { paramSearchQuery } = useParams<string>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resultTerm, setResultTerm] = useState('');
  const [sortOption, setSortOption] = useState('Title (a-z)');
  const [bookResults, setBookResults] = useState([]);
  const [loadedSearchResults, setLoadedSearchResults] = useState(false);
  const [searchingBooks, setSearchingBooks] = useState(false);
  const [searchDisplayed, setSearchDisplayed] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [errMsg, setErrMsg] = useState();
  const [resultCount, setResultCount] = useState(0);
  const [sortByObject, setSortByObject] = useState({ title: 1 });
  const itemsPerPage = 24;
  const itemOffset = 0;
  const updateQuery = (event:any) => {
    // console.log(event.target.value);
    setSearchQuery(event.target.value);
  };

  const initSearch = (newQuery: string, sortBy = '') => {
    if (newQuery?.length) {
    // console.log(searchQuery);
      setLoadedSearchResults(false);
      setSearchingBooks(true);
      const query = newQuery.replace(/[^\w\s]/gi, '').trim();
      axiosPrivate({
        method: 'POST',
        url: '/api/book/searchBooks',
        data: {
          searchTerm: query,
          itemsPerPage,
          offSetIndex: itemOffset,
          sortByObject: sortBy || sortByObject,
        },
      })
        .then((res) => {
        // console.log(res.data.searchResults);
          const { books, bookCount } = res.data;
          if (books.length < 0) {
            setSearchDisplayed([]);
            setBookResults([]);
            setLoadedSearchResults(true);
            return;
          }
          setBookResults(books);
          setStartingPage(0);
          // setBookResults(books);
          // setSearchDisplayed(newData.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(bookCount / itemsPerPage));
          setResultCount(bookCount);
          setResultTerm(query);
          // setStartingPage(0);
          setLoadedSearchResults(true);
          setSearchingBooks(false);
        })
        .catch((err) => {
          setErrMsg(err);
        });
    }
    // console.log(query);
  };
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % resultCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    setLoadedSearchResults(false);
    setSearchingBooks(true);
    const query = searchQuery.replace(/[^\w\s]/gi, '').trim();
    axiosPrivate({
      method: 'POST',
      url: '/api/book/searchBooks',
      data: {
        searchTerm: query,
        itemsPerPage,
        offSetIndex: newOffset,
        sortByObject,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { books } = res.data;
        if (books.length < 0) {
          setSearchDisplayed([]);
          setBookResults([]);
          setLoadedSearchResults(true);
          return;
        }
        setBookResults(books);
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // console.log(event.target.value);
        setStartingPage(event.selected);
        setLoadedSearchResults(true);
        setSearchingBooks(false);
      })
      .catch((err) => {
        setErrMsg(err);
      });
  };
  const renderBookList = () => bookResults?.map((book:BookListItem) => (
    <WrapItem>
      <Flex
        alignItems="flex-start"
        // width={{ base: '80px', sm: '120px', md: '180px' }}
        // height={{ base: '130px', sm: '190px', md: '285px' }}
      >
        <Link
          href={`/book/${book?._id}`}
        >
          {book?.coverImage
            ? (
              <Image
                cursor="pointer"
                borderRadius="5px"
                src={book?.coverImage}
                // width={{ base: '80px', sm: '120px', md: '180px' }}
                // height={{ base: '130px', sm: '190px', md: '285px' }}
                _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
              />
            ) : (
              <Flex
                backgroundColor="#1F1F1F"
                color="#FFF"
                // width={{ base: '80px', sm: '120px', md: '180px' }}
                // height={{ base: '130px', sm: '190px', md: '285px' }}
                borderRadius="5px"
                justifyContent="center"
                flexDir="column"
                textAlign="center"
                paddingX="5px"
                _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
              >
                <Text
                  fontSize="16px"
                  fontFamily="PtSerif"
                >
                  {book?.title}
                </Text>
                <Text
                  marginTop="10px"
                  fontSize="14px"
                  fontFamily="PtSerif"
                >
                  {book?.author}
                </Text>
              </Flex>
            )}
        </Link>
      </Flex>
    </WrapItem>
  ));

  useEffect(() => {
    document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        // console.log(searchQuery);
        initSearch(searchQuery);
      }
    });
  });
  const handleSortMenu = (sortLabel:any, sortBy:any) => {
    setSortOption(sortLabel);
    setSortByObject(sortBy);
    initSearch(sortBy);
  };
  useEffect(() => {
    if (paramSearchQuery?.length) {
      initSearch(paramSearchQuery || '');
    }
  }, [paramSearchQuery]);
  return (
    <Box>
      <Navbar />
      <Flex
        justifyContent="center"
        flexDir="column"
        alignItems="center"
        minH="95vh"
        color="#FFF"
        bg="#111"
        paddingY="2em"
      >
        <Box
          width={{
            base: '90%', sm: '350px', md: '400px', lg: '500px',
          }}
        >
          <SmallLogo color="white" />
          {/*
          <Image
            data-testid="app-preview"
            src={Theologians}
            width={{ base: '50%', md: '300px' }}
            marginX="auto"
          />
          */}
          <Text
            textAlign="center"
            className="large-text large-text-white"
          >
            Discover New Books
          </Text>
          <Flex
            borderRadius={{ base: '40px', md: '50px' }}
            height={{ base: '40px', md: '50px' }}
            width="100%"
            padding="5px 15px"
            margin="auto"
            backgroundColor="#777"
          >
            <Input
              id="book-search-bar"
              color="#000"
              style={{ color: '#FFF' }}
              placeholder="Search For Books"
              onChange={updateQuery}
              border="0"
              outline="0"
              fontSize={{ base: '12px', md: '16px' }}
              height={{ base: '30px', md: '40px' }}
              marginRight="10px"
              _focus={{ outline: '0', backgroundColor: '#666' }}
            />
            <Image
              onClick={() => { initSearch(searchQuery); }}
              id="search-book-button"
              src={SearchIcon}
              className="search-image"
              alt="search icon button"
            />
          </Flex>
        </Box>
        { loadedSearchResults
          ? (
            <Box
              marginX="auto"
              width={{ base: '90%', lg: '90%', xl: '75%' }}
            >
              <Box
                display={{ base: 'block', md: 'flex' }}
                justifyContent="space-between"
                marginTop={{ base: '2em', md: '4em' }}
                paddingX={{ base: '0' }}
                marginX="auto"
              >
                <Box>
                  <Text
                    fontSize={{ base: '16px', sm: '20px', md: '28px' }}
                    fontWeight="700"
                    letterSpacing="1px"
                    fontFamily="PtSerif"
                  >
                    Results for
                    {' '}
                    &ldquo;
                    {resultTerm}
                    &rdquo;
                    {' '}
                    (
                    {resultCount}
                    )

                  </Text>
                </Box>
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      width={{ base: 'auto', lg: 'auto' }}
                      height={{ base: '30px', md: '40px' }}
                      marginX="auto"
                      fontSize={{ base: '12px', lg: '16px' }}
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflowX="hidden"
                      outline="none"
                      color="#FFF"
                      backgroundColor="#333"
                      _hover={{ backgroundColor: '#888' }}
                      _focus={{ backgroundColor: '#888', outline: 'none' }}
                    >
                      {sortOption}
                      {' '}
                      &#x25BC;
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        fontFamily="PtSerif"
                        fontSize="18px"
                        onClick={() => {
                          handleSortMenu('Title (a-z)', { title: 1 });
                        }}
                      >
                        Title (a-z)
                      </MenuItem>
                      <MenuItem
                        fontFamily="PtSerif"
                        fontSize="18px"
                        onClick={() => {
                          handleSortMenu('Title (z-a)', { title: -1 });
                        }}
                      >
                        Title (z-a)
                      </MenuItem>
                      <MenuItem
                        fontFamily="PtSerif"
                        fontSize="18px"
                        onClick={() => {
                          handleSortMenu('Author (a-z)', { author: 1 });
                        }}
                      >
                        Author (a-z)
                      </MenuItem>
                      <MenuItem
                        fontFamily="PtSerif"
                        fontSize="18px"
                        onClick={() => {
                          handleSortMenu('Author (z-a)', { author: -1 });
                        }}
                      >
                        Author (z-a)
                      </MenuItem>
                      {/*
              <Flex marginRight="20px" paddingY="10px" justifyContent="right">
                <Text>
                  Dark Mode
                </Text>
                <Switch marginX="10px" id="dark-mode" onChange={() => { toggleColorMode(); }} />
              </Flex>
              */
              }
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
              {
            bookResults.length > 0 ? (
              <Box>
                <SimpleGrid
                  marginTop="20px"
                  gridTemplateColumns={{
                    base: '1fr 1fr 1fr 1fr',
                    lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                    xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                  }}
                  gridGap={{ base: '10px', md: '20px' }}
                  marginX="auto"
                >
                  {renderBookList()}
                </SimpleGrid>
                <CustomPagination
                  pageCount={pageCount}
                  startingPage={startingPage}
                  handlePageClick={handlePageClick}
                />
              </Box>
            ) : <Box />
          }
            </Box>
          ) : (
            <Box />
          )}
        <Box>
          { searchingBooks
            ? (
              <Flex
                flexDirection="column"
                justifyContent="center"
                height="400px"
                alignItems="center"
              >
                <Spinner
                  thickness="4px"
                  emptyColor="gray.200"
                  color="#FF1A75"
                  size="xl"
                  mb="8px"
                />
                <Text>Searching...</Text>
              </Flex>
            ) : <Box />}
        </Box>
      </Flex>
    </Box>
  );
}
