import { Box } from '@chakra-ui/react';
import React from 'react';
import ReactPaginate from 'react-paginate';

export default function CustomPagination(props: {
  handlePageClick:any,
  startingPage:any,
  pageCount:any,
}) {
  const {
    handlePageClick,
    startingPage,
    pageCount,
  } = props;
  return (
    <Box>
      <Box
        justifyContent="center"
        paddingTop="4em"
        paddingBottom="2em"
        display={{ base: 'flex', md: 'none' }}
      >
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={0}
          marginPagesDisplayed={0}
          forcePage={startingPage}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </Box>
      <Box
        justifyContent="center"
        paddingTop="4em"
        paddingBottom="2em"
        display={{ base: 'none', md: 'flex' }}
      >
        <ReactPaginate
          nextLabel="next >"
          forcePage={startingPage}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </Box>
    </Box>
  );
}
