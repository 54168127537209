/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import ReactPaginate from 'react-paginate';
import { compressAccurately } from 'image-conversion';
import {
  Box,
  Flex,
  Spinner,
  Text,
  Avatar,
  useColorModeValue,
  Link,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { User, UserBasic } from '../../_interfaces/User';
import './DiscoverFriends.scss';
import CustomButton from '../../components/CustomButton/CustomButton';

export default function DiscoverFriends() {
  const [loadingBooks, setLoadingBooks] = useState(true);
  const [numBooks, setNumBooks] = useState(0);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [myresults, setMyResults] = useState<User []>();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const {
    userId,
    image,
  } = useSelector((state: UserBasic) => state.user.userData);
  const itemsPerPage = 16;

  const retireveResults = () => {
    axios({
      method: 'POST',
      url: '/api/connections/discoverFriendsByIndex',
      data: {
        userId,
        itemsPerPage,
        offsetIndex: 0,
      },
    })
      .then((res) => {
        // eslint-disable-next-line no-console
        // console.log(res.data.books);
        const { results, resultCount } = res.data;
        const endOffset = itemOffset + itemsPerPage;
        // setBooksDisplayed(books.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(resultCount / itemsPerPage));
        setNumBooks(resultCount);
        setItemOffset(endOffset);
        setMyResults(results);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    if (image) {
      retireveResults();
    }
  }, []);
  const sendFriendRequest = (targetUserId: string) => {
    // console.log(targetUserId);
    console.log('hello');
    const buttonDiscElement = document.getElementById(`add_request_${targetUserId}`) as HTMLButtonElement;
    if (buttonDiscElement) {
      buttonDiscElement.innerText = 'Added';
      buttonDiscElement.disabled = true;
    }
    axios({
      method: 'POST',
      url: '/api/connections/createFriendRequest',
      data: {
        userId,
        recipientId: targetUserId,
      },
    })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const renderResultList = () => myresults?.map((result) => (
    <Box
      textAlign="center"
      bg="#222"
      padding="1rem"
      borderRadius="5px"
    >
      <Link
        href={`/profile/${result?._id}`}
        outline="none"
        _focus={{ outline: 'none' }}
      >
        <Avatar
          size="xl"
          src={result?.image}
          filter="brightness(0.9)"
          _hover={{
            filter: 'brightness(1)',
            transform: 'scale(1.08)',
            transition: 'transform 0.25s ease',
          }}
        />
      </Link>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          href={`/profile/${result?._id}`}
          className="small-text small-text-link"
        >
          {result.username}
        </Link>
      </Box>
      <CustomButton
        label=" Add +"
        testId={`add_request_${result?._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            sendFriendRequest(result._id || '');
          }),
          id: `add_request_${result?._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  const handlePageClick = (event:any) => {
    if (myresults?.length) {
      const newOffset = (event.selected * itemsPerPage) % numBooks;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
      // );
      axios({
        method: 'POST',
        url: '/api/connections/discoverFriendsByIndex',
        data: {
          userId,
          itemsPerPage,
          offsetIndex: newOffset,
        },
      })
        .then((res) => {
          // eslint-disable-next-line no-console
          // console.log(res.data.books);
          const { results } = res.data;
          // const endOffset = itemOffset + itemsPerPage;
          // console.log(`Loading items from ${itemOffset} to ${newOffset}`);
          // setBooksDisplayed(books.slice(itemOffset, endOffset));
          // setPageCount(Math.ceil(bookCount / itemsPerPage));
          setItemOffset(newOffset);
          setMyResults(results);
          setLoadingBooks(false);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  };
  return (
    <Box>
      <Navbar />
      <Box className="discover-container" minHeight="90vh">
        {
            myresults?.length
              ? (
                <Box
                  width={{ base: '90%', lg: '90%', xl: '85%' }}
                  marginX="auto"
                >
                  <Text
                    className="large-text large-text-white"
                  >
                    Discover Friends
                  </Text>
                  <SimpleGrid
                    marginTop="20px"
                    gridTemplateColumns={{
                      base: '1fr 1fr',
                      md: '1fr 1fr 1fr 1fr',
                      xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                    }}
                    gridGap={{ base: '10px', md: '20px' }}
                    marginX="auto"
                  >
                    {renderResultList()}
                  </SimpleGrid>
                  <CustomPagination
                    pageCount={pageCount}
                    startingPage={startingPage}
                    handlePageClick={handlePageClick}
                  />
                </Box>
              ) : (
                <LoadingSpinner />
              )
          }
      </Box>
    </Box>
  );
}
