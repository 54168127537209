/* eslint-disable no-underscore-dangle */
import {
  Box,
  Text,
  Avatar,
  Button,
  SimpleGrid,
  Link,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import { User, UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

export default function DiscoverFriends(props: {
  addToRequests: (arg: any) => void,
}) {
  const {
    addToRequests,
  } = props;
  const { userId } = useSelector((state: UserBasic) => state.user.userData);
  const [results, setResults] = useState<User []>();
  useEffect(() => {
    if (userId) {
      axios({
        method: 'POST',
        url: '/api/connections/discoverFriends',
        data: {
          userId,
        },
      })
        .then((res) => {
        // console.log(res.data.books);
          const { resultList } = res.data;
          setResults(resultList);
        })
        .catch((resErr) => {
        // eslint-disable-next-line no-console
          console.log(resErr);
        // eslint-disable-next-line no-console
        });
    }
  }, [userId]);
  const sendFriendRequest = (targetUserId: string) => {
    // console.log(targetUserId);
    axios({
      method: 'POST',
      url: '/api/connections/createFriendRequest',
      data: {
        userId,
        recipientId: targetUserId,
      },
    })
      .then(() => {
        // console.log(res.data.success);
        // console.log(targetUserId);
        addToRequests(targetUserId);
        const buttonElement = document.getElementById(`req_button_${targetUserId}`) as HTMLButtonElement;
        if (buttonElement) {
          buttonElement.innerText = 'Added';
          buttonElement.disabled = true;
        }
        const buttonDiscElement = document.getElementById(`req_disc_button_${targetUserId}`) as HTMLButtonElement;
        if (buttonDiscElement) {
          buttonDiscElement.innerText = 'Added';
          buttonDiscElement.disabled = true;
        }
        // const { resultList } = res.data;
        // setResults(resultList);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const renderResults = results?.map((result: User) => (
    <Box
      textAlign="center"
      paddingY="2rem"
      borderRadius="5px"
      backgroundColor="#222"
    >
      <Box>
        <Link
          href={`/profile/${result?._id}`}
          _focus={{ outline: 'none' }}
        >
          <Avatar
            size="xl"
            src={result?.image}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
              transform: 'scale(1.08)',
              transition: 'transform 0.25s ease',
            }}
          />
        </Link>
      </Box>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          href={`/profile/${result?._id}`}
          className="small-text small-text-link"
        >
          {result.username}
        </Link>
      </Box>
      <CustomButton
        label="Add +"
        testId={`req_disc_button_${result._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            sendFriendRequest(result._id || '');
          }),
          id: `req_disc_button_${result._id}`,
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  return (
    <Box>
      <Flex
        justifyContent="space-between"
        width={{ base: '90%', lg: '90%', xl: '85%' }}
        marginX="auto"
        marginTop="4em"
      >
        <Text
          fontWeight="600"
          fontSize={{ base: '18px', sm: '24px' }}
          marginTop="10px"
          marginBottom="1em"
          textAlign="center"
          fontFamily="PtSerif"
        >
          Discover Friends
        </Text>
        <Link
          href="/discover-friends"
          className="link-btn link-btn-small link-btn-night"
        >
          See More
        </Link>
      </Flex>
      <SimpleGrid
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        width={{ base: '90%', lg: '90%', xl: '85%' }}
        gridGap={{ base: '10px', md: '20px' }}
        rowGap={{ base: '20px', md: '20px' }}
        marginX="auto"
      >
        {renderResults}
      </SimpleGrid>
    </Box>
  );
}
