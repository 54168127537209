import axios from '../api/axios';
import { updatePage } from '../_reducers/bookSlice';
import { store } from '../_reduxStore/store';

// https://stackoverflow.com/questions/71835965/usedispatch-in-a-function-outside-react-component-redux-tool-kit
const updateBookPage = (pageNo: number, chapter: string) => {
  const { userBookId } = store.getState().book.currentBook;
  const { username, isPaid } = store.getState().user.userData;
  store.dispatch(updatePage({
    currentChapter: chapter,
    currentPage: pageNo,
  }));
  if (isPaid) {
    axios({
      method: 'PUT',
      url: '/api/book/updatePageNo',
      data: {
        newPageNo: pageNo,
        bookId: userBookId,
        username,
        currentChapter: chapter,
      },
    })
      .catch((saveErr) => {
        // eslint-disable-next-line no-console
        console.log(saveErr.message);
      });
  }
};

export default updateBookPage;
