/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { axiosPrivate } from '../api/axios';

function RequireNoAuth() {
  const location = useLocation();
  const [processing, setProcessing] = useState(true);
  const [loggedIn, setLoggedIn] = useState<boolean>();

  useEffect(() => {
    const theologianAccess:string = window.localStorage.getItem('theologianAccess') || '';
    const accessHeaders = {
      'Access-Control-Allow-Headers': 'theologianAccess',
      theologianAccess,
    };
    axiosPrivate({
      headers: accessHeaders,
      url: '/api/middleware/checkAccessToken',
      method: 'GET',
    })
      .then(() => {
        setLoggedIn(true);
        setProcessing(false);
      })
      .catch(() => {
        // setProcessing(false);
        const theologianRefresh:string = window.localStorage.getItem('theologianRefresh') || '';
        const deviceId: string = window.localStorage.getItem('deviceId') || '';
        const refreshHeaders = {
          'Access-Control-Allow-Headers': 'theologianAccess',
          theologianRefresh,
          deviceId,
        };
        //  console.log(refreshHeaders);
        axiosPrivate({
          headers: refreshHeaders,
          url: '/api/token/refreshToken',
          method: 'GET',
        })
          .then((res:any) => {
            // console.log(res)
            setLoggedIn(true);
            setProcessing(false);
          })
          .catch(() => {
            // console.log('reached')
            setLoggedIn(false);
            setProcessing(false);
          });
      });
  }, []);

  return (
    // eslint-disable-next-line no-nested-ternary
    <Box>
      {processing === false
        ? (
          <Box>
            {
          loggedIn
            ? <Navigate to="/home" state={{ from: location }} replace />
            : <Outlet />
        }
          </Box>
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100vh"
            bg="#111"
          >
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading...</Text>
          </Flex>
        )}
    </Box>
  );
}
export default RequireNoAuth;
