import React from 'react';
import {
  Text,
  Flex,
  Image,
  // Wrap,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import HistoryPreview from '../../../assets/images/history_preview.jpg';
import TheologyPreview from '../../../assets/images/theology_preview.jpg';
import BiographyPreview from '../../../assets/images/biography_preview.jpg';
import SermonPreview from '../../../assets/images/sermon_preview.jpg';

function HomeMenu() {
  return (
    <Flex
      paddingY="4em"
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    >
      <SimpleGrid
        width={{ base: '96%', md: '90%', xl: '80%' }}
        gridTemplateColumns={{ base: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
        gridGap={{ base: '10px', md: '20px' }}
        margin="auto"
      >
        <Link
          _focus={{ outline: 'none' }}
          _hover={{ color: '#FFF' }}
          href="/category/Theology"
        >
          <Flex
            height={{ base: '75px', sm: '150px', xl: '200px' }}
            _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
          // width={{ base: '100px', sm: '200px', md: '350px' }}
            width="100%"
            marginX="auto"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            objectFit="cover"
          >
            <Image
              opacity="0.7"
              height="100%"
              width="100%"
              borderRadius="10px"
              objectFit="cover"
              cursor="pointer"
              src={TheologyPreview}
              loading="lazy"
            />
            <Text
              fontSize={{ base: '14px', md: '28px' }}
              cursor="pointer"
              fontWeight="700"
            // color="#FFF"
              letterSpacing="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily="PTSerif"
            >
              Theology
            </Text>
          </Flex>

        </Link>
        <Link
          _focus={{ outline: 'none' }}
          _hover={{ color: '#FFF' }}
          href="/category/Biography"
        >
          <Flex
            height={{ base: '75px', sm: '150px', xl: '200px' }}
            _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
          // width={{ base: '100px', sm: '200px', md: '350px' }}
            width="100%"
            marginX="auto"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Image
              opacity="0.7"
              height="100%"
              width="100%"
              borderRadius="10px"
              objectFit="cover"
              cursor="pointer"
              src={BiographyPreview}
              loading="lazy"
            />
            <Text
              fontSize={{ base: '14px', md: '28px' }}
              cursor="pointer"
              fontWeight="700"
            // color="#FFF"
              letterSpacing="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily="PTSerif"
            >
              Biography
            </Text>
          </Flex>

        </Link>
        <Link
          _focus={{ outline: 'none' }}
          _hover={{ color: '#FFF' }}
          href="/category/Sermons"
        >
          <Flex
            height={{ base: '75px', sm: '150px', xl: '200px' }}
            _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
          // width={{ base: '100px', sm: '200px', md: '350px' }}
            width="100%"
            marginX="auto"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Image
              opacity="0.7"
              height="100%"
              width="100%"
              borderRadius="10px"
              objectFit="cover"
              cursor="pointer"
              src={SermonPreview}
              loading="lazy"
            />
            <Text
              fontSize={{ base: '14px', md: '28px' }}
              cursor="pointer"
              fontWeight="700"
            // color="#FFF"
              letterSpacing="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily="PTSerif"
            >
              Sermons
            </Text>
          </Flex>

        </Link>
        <Link
          _focus={{ outline: 'none' }}
          _hover={{ color: '#FFF' }}
          href="/category/History"
        >
          <Flex
            height={{ base: '75px', sm: '150px', xl: '200px' }}
            _hover={{ transform: 'scale(1.08)', transition: 'transform 0.25s ease' }}
          // width={{ base: '100px', sm: '200px', md: '350px' }}
            width="100%"
            marginX="auto"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Image
              opacity="0.7"
              height="100%"
              width="100%"
              borderRadius="10px"
              objectFit="cover"
              cursor="pointer"
              src={HistoryPreview}
              loading="lazy"
            />
            <Text
              fontSize={{ base: '14px', md: '28px' }}
              cursor="pointer"
              fontWeight="700"
            // color="#FFF"
              letterSpacing="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily="PTSerif"
            >
              History
            </Text>
          </Flex>

        </Link>
      </SimpleGrid>
    </Flex>
  );
}

export default HomeMenu;
