/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, createRef, Ref,
} from 'react';
import Dropzone, { DropzoneRef, useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios, { axiosPrivate } from '../../../api/axios';
import BookEdit from './BookEdit';
import BlankImage from '../../../assets/images/blank_image.png';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import { BookListItem } from '../../../_interfaces/Books';

const baseDropzoneStyle = {
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  backgroundColor: '#333',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

function BookList() {
  const [myBookList, setMyBookList] = useState<BookListItem []>();
  const dropzoneRef = createRef();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [allBooksCount, setAllBooksCount] = useState(0);
  const itemsPerPage = 16;
  const itemOffset = 0;
  // const [selectedBook, setSelectedBook] = useState();
  const [err, setErr] = useState();
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % allBooksCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axiosPrivate({
      method: 'POST',
      url: '/api/book/booksByIndex',
      data: {
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { books } = res.data;
        if (books.length < 0) {
          setMyBookList([]);
          return;
        }
        setMyBookList(books);
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        document.getElementById('admin-book-list')?.scrollIntoView();
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  const getBooks = () => {
    axios({
      method: 'POST',
      url: '/api/book/booksByIndex',
      withCredentials: true,
      data: {
        offSetIndex: itemOffset,
        itemsPerPage,
      },
    })
      .then((res) => {
        const { books, bookCount } = res.data;
        setMyBookList(books);
        setPageCount(Math.ceil(bookCount / itemsPerPage));
        setAllBooksCount(bookCount);
      })
      .catch((resErr) => {
        setErr(resErr);
        // eslint-disable-next-line no-console
        navigate('/login');
      });
  };

  useEffect(() => {
    getBooks();
  }, []);

  const {
    isDragActive,
  } = useDropzone({
    accept: ['image/*', '.png', '.jpg', '.jpeg'],
    multiple: false,
  });
  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);
  const handleOnDrop = async (acceptedFile: any, book:BookListItem) => {
    // console.log(bookId);
    // console.log(acceptedFile[0]);
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      timeout: 5 * 60 * 60 * 1000,
    };
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    formData.append('bookId', book._id);
    if (book.coverFileName) formData.append('coverFileName', book.coverFileName);
    const res = await axiosPrivate.post('/api/book/addBookCover', formData, config);
    if (res.status === 200) {
      alert('image have been sucessfully uploaded!');
      window.location.reload();
    }
  };
  const renderBooksList = () => myBookList?.map((book) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    >
      <Dropzone
        onDropAccepted={(acceptedFile) => handleOnDrop(acceptedFile, book)}
        ref={dropzoneRef as Ref<DropzoneRef>}
        accept="image/jpeg,image/png"
        onDropRejected={() => { alert('File Format cannot be accepted!'); }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ style: dropzoneStyle })}>
            <input {...getInputProps()} />
            {book.coverImage
              ? (
                <Image
                  cursor="pointer"
                  borderRadius="5px"
                  src={book.coverImage}
                  _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
                />
              ) : (
                <Flex
                  color="#FFF"
                  // border="1px solid #AAA"
                  borderRadius="5px"
                  justifyContent="center"
                  flexDir="column"
                  textAlign="center"
                  paddingX="5px"
                  paddingTop="10rem"
                  // _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
                >
                  <Text
                    fontFamily="PtSerif"
                    fontSize="16px"
                  >
                    {book.title}
                  </Text>
                  <Text
                    fontFamily="PtSerif"
                    marginTop="10px"
                    fontSize="14px"
                  >
                    {book.author}
                  </Text>
                </Flex>
              )}
          </div>
        )}
      </Dropzone>
      <Box>
        <BookEdit
          bookId={book._id}
          contentId={book.contentId || ''}
          coverFileName={book.coverFileName || ''}
          title={book.title}
          author={book.author}
          description={book.description || ''}
          releaseState={book.releaseState || ''}
          tags={book.tags || []}
          category={book.category || ''}
          getBooks={getBooks}
        />
      </Box>
    </Flex>
  ));
  return (
    <Box
      paddingY="50px"
    >
      <Box>
        <Text
          fontFamily="PtSerif"
          fontSize={{ base: '24px', md: '28px' }}
          fontWeight="700"
          color="#FFF"
          letterSpacing="1px"
        >
          All Books
        </Text>
      </Box>
      <SimpleGrid
        id="admin-book-list"
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        gridGap={{ base: '10px', md: '20px' }}
        rowGap={{ base: '20px', md: '20px' }}
        marginX="auto"
      >
        {renderBooksList()}
      </SimpleGrid>
      <CustomPagination
        pageCount={pageCount}
        startingPage={startingPage}
        handlePageClick={handlePageClick}
      />
      <Box />
    </Box>
  );
}

export default BookList;
