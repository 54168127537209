import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Link,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import axios from '../../api/axios';
import CustomButton from '../CustomButton/CustomButton';

export default function TrialMode() {
  const user = useSelector((state:any) => state.user.userData);
  const {
    isOpen: isOpenTrialStart,
    onOpen: onOpenTrialStart,
    onClose: onCloseTrialStart,
  } = useDisclosure();
  const {
    isOpen: isOpenTrialEnd,
    onOpen: onOpenTrialEnd,
    onClose: onCloseTrialEnd,
  } = useDisclosure();

  const removeTrialStartMsg = async () => {
    try {
      await axios.put('/api/role/removeTrialStartMsg', { username: user.username });
      onCloseTrialStart();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
  const removeTrialEndMsg = async () => {
    try {
      await axios.put('/api/role/removeTrialEndMsg', { username: user.username });
      onCloseTrialEnd();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
  useEffect(() => {
    if (user.trialStartMsg && user.trialDays === 30) {
      onOpenTrialStart();
    }
    if (user.trialEndMSg && user.trialDays === 0) {
      onOpenTrialEnd();
    }
  }, [user]);
  return (
    <Box>
      <Modal isOpen={isOpenTrialStart} onClose={onCloseTrialStart}>
        <ModalOverlay />
        <ModalContent
          minW={{ base: '90%', lg: '500px' }}
        >
          <ModalHeader><Text as="h1" className="section-heading">Begin Trial</Text></ModalHeader>
          <ModalBody>
            <Text className="medium-text medium-text-black">
              You are about to begin a 30 day trial for
              {' '}
              the premium experience of Theologian&apos;s Pen. Please Enjoy!
            </Text>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              testId="trial-begin-btn"
              label="Continue"
              buttonColor="black"
              buttonProps={{
                onClick: removeTrialStartMsg,
              }}
              buttonType="button"
              spinnerId={undefined}
              size="medium"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenTrialEnd} onClose={onCloseTrialEnd}>
        <ModalOverlay />
        <ModalContent
          minW={{ base: '90%', lg: '500px' }}
        >
          <ModalHeader><Text as="h1" className="section-heading">End Trial</Text></ModalHeader>
          <ModalBody>
            <Text
              className="medium-text medium-text-black"
            >
              You are about to end your 30 day trial for
              {' '}
              the premium experience of Theologian&apos;s Pen.
              Please Subscribe
              {' '}
              <Link
                href="/subscription"
                textDecor="underline"
              >
                here
              </Link>
              {' '}
              to continue your premium experience
            </Text>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              testId="trial-end-btn"
              label="Continue"
              buttonColor="black"
              buttonProps={{
                onClick: removeTrialEndMsg,
              }}
              buttonType="button"
              spinnerId={undefined}
              size="medium"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
