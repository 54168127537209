/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Image,
  Text,
  Link,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import HistoryPreview from '../../assets/images/history_preview.jpg';
import TheologyPreview from '../../assets/images/theology_preview.jpg';
import BiographyPreview from '../../assets/images/biography_preview.jpg';
import SermonPreview from '../../assets/images/sermon_preview.jpg';
import axios from '../../api/axios';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { BookListItem } from '../../_interfaces/Books';

export default function BookCategory() {
  const [bookList, setBookList] = useState<BookListItem []>([]);
  const [loadedBooks, setBookLoaded] = useState<boolean>(false);
  const [numBooks, setNumBooks] = useState<number>(0);
  const [categoryImage, setCategoryImage] = useState<string>();
  const [pageCount, setPageCount] = useState<number>(0);
  const { categoryName } = useParams();
  const bg = useColorModeValue('#EEE', '#111');
  const bgButton = useColorModeValue('#DDD', '#222');
  const booksPerPage = 24;
  const offsetIndex = 0;
  const startingPage = 0;

  const getBooksByCategory = () => {
    axios({
      url: '/api/book/categoryByIndex',
      method: 'POST',
      data: {
        category: categoryName,
        booksPerPage,
        offsetIndex,
      },
    })
      .then((res) => {
        const { books, bookCount } = res.data;
        setBookList(books);
        setNumBooks(bookCount);
        setPageCount(Math.ceil(bookCount / booksPerPage));
        setBookLoaded(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const categoryImageUrl = () => {
    switch (categoryName) {
      case 'Theology':
        setCategoryImage(TheologyPreview);
        break;
      case 'Sermons':
        setCategoryImage(SermonPreview);
        break;
      case 'History':
        setCategoryImage(HistoryPreview);
        break;
      case 'Biography':
        setCategoryImage(BiographyPreview);
        break;
      default:
        setCategoryImage(TheologyPreview);
        return;
    }
    getBooksByCategory();
  };
  useEffect(() => {
    if (categoryName) {
      categoryImageUrl();
    }
  }, [categoryName]);
  const handlePageClick = (event:any) => {
    if (bookList?.length > 0) {
      const newOffset = (event.selected * booksPerPage) % numBooks;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
      // );
      axios({
        method: 'POST',
        url: '/api/book/categoryByIndex',
        data: {
          category: categoryName,
          booksPerPage,
          offsetIndex: newOffset,
        },
      })
        .then((res) => {
          // eslint-disable-next-line no-console
          // console.log(res.data.books);
          const { books } = res.data;
          // const endOffset = itemOffset + itemsPerPage;
          // console.log(`Loading items from ${itemOffset} to ${newOffset}`);
          // setBooksDisplayed(books.slice(itemOffset, endOffset));
          // setPageCount(Math.ceil(bookCount / itemsPerPage));
          setBookList(books);
          setBookLoaded(true);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  };
  const renderBookList = () => bookList?.map((book) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      // width="180px"
      // height="285px"
      marginX="auto"
    >
      <Link
        href={`/book/${book._id}`}
      >
        {book?.coverImage
          ? (
            <Image
              cursor="pointer"
              borderRadius="5px"
              src={book.coverImage}
              // width="180px"
              // height="285px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            />
          ) : (
            <Flex
              backgroundColor="#1F1F1F"
              color="#FFF"
              // width="180px"
              // height="285px"
              borderRadius="5px"
              justifyContent="center"
              flexDir="column"
              textAlign="center"
              paddingX="5px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            >
              <Text
                fontSize="16px"
                fontFamily="PTSerif"
              >
                {book.title}
              </Text>
              <Text
                marginTop="10px"
                fontSize="14px"
                fontFamily="PTSerif"
              >
                {book.author}
              </Text>
            </Flex>
          )}
      </Link>
    </Flex>
  ));
  return (
    <Box
      bg="#111"
    >
      <Navbar />
      <Flex
        minH="90vh"
        justifyContent="center"
        marginTop="4rem"
        flexDir="column"
        alignItems="center"
      >
        <Flex
          height={{ base: '75px', md: '150px' }}
          width={{ base: '200px', md: '300px' }}
          marginX="auto"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          objectFit="cover"
        >
          <Image
            opacity="0.7"
            height="100%"
            width="100%"
            borderRadius="10px"
            objectFit="cover"
            src={TheologyPreview}
          />
          <Text
            marginTop="1rem"
            fontSize={{ base: '14px', md: '28px' }}
            fontWeight="700"
            color="#FFF"
            letterSpacing="1px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontFamily="PTSerif"
          >
            {categoryName}
          </Text>
        </Flex>
        <Box
          marginY="4em"
        >
          {loadedBooks
            ? (
              <Box>
                {bookList.length > 0
                  ? (
                    <Box>
                      <SimpleGrid
                        gridTemplateColumns={{
                          base: '1fr 1fr 1fr 1fr',
                          lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                        }}
                        width={{ base: '90%', lg: '90%', xl: '75%' }}
                        gridGap={{ base: '10px', md: '20px' }}
                        marginX="auto"
                      >
                        {renderBookList()}
                      </SimpleGrid>
                    </Box>
                  ) : (
                    <Box>
                      <Flex
                        marginTop="2em"
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        height={{ base: '200px', md: '300px' }}
                      >
                        <Text
                          className="medium-text medium-text-white"
                        >
                          No books for this category yet. Check back Later!
                        </Text>
                      </Flex>
                    </Box>
                  )}
              </Box>
            ) : <LoadingSpinner />}
          <Box>
            {
            bookList.length > 0
              ? (
                <CustomPagination
                  pageCount={pageCount}
                  startingPage={startingPage}
                  handlePageClick={handlePageClick}
                />
              ) : <Box />
            }
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
