/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Textarea,
  Slide,
  Flex,
  Link,
  Avatar,
  Text,
  useDisclosure,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import MiniLoader from '../../MiniLoader/MiniLoader';
import { BookContent } from '../../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      currentChapter: string,
    }
    bookContent: BookContent[]
  }
}

export default function DetailedFriendNotes(props: {
  bookId:any,
  friendId:any,
  notesModalUse:any,
  username:any,
  image:any,
  updateBookPage: (newPage: number, newChapter: string) => void,
}) {
  const {
    bookId,
    friendId,
    notesModalUse,
    username,
    image,
    updateBookPage,
  } = props;
  const [notesOpen, setNotesOpen] = useState(false);
  const [highlightsOpen, setHighlightsOpen] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [highlightsLoading, setHighlightsLoading] = useState(false);
  const [highlightsArr, setHighlightsArr] = useState([]);
  const [notessArr, setNotesArr] = useState([]);
  const noteBg = useColorModeValue('#EEE', '#333');
  const noteColor = useColorModeValue('#444', '#DDD');
  const textManual = useColorModeValue('#000', '#FFF');
  const borderColor = useColorModeValue('#CCC', '#444');
  const pageColor = useColorModeValue('#DDD', '#444');
  const pageColorHover = useColorModeValue('#CCC', '#555');
  const noteBorderColor = useColorModeValue('#555', '#CCC');

  const navigate = useNavigate();
  const { bookContent } = useSelector((state:BookState) => state.book);
  const {
    isOpen: isOpenNotes,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();

  useEffect(() => {
    if (notesModalUse) {
      onOpenNotes();
    }
  }, [notesModalUse]);

  const hightlightHandler = () => {
    if (highlightsOpen) {
      setHighlightsOpen(false);
      return;
    }
    setHighlightsLoading(true);
    axios({
      method: 'POST',
      url: '/api/userbook/retrieveUserHighlights',
      data: {
        username,
        bookId,
      },
    })
      .then((res) => {
        const { highlights } = res.data;
        setHighlightsArr(highlights);
        setHighlightsOpen(true);
        setHighlightsLoading(false);
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };

  const notesHandler = () => {
    if (notesOpen) {
      setNotesOpen(false);
      return;
    }
    setNotesLoading(true);
    axios({
      method: 'POST',
      url: '/api/userbook/retrieveUserNotes',
      data: {
        username,
        bookId,
      },
    })
      .then((res) => {
        const { notes } = res.data;
        // console.log(notes);
        setNotesArr(notes);
        setNotesOpen(true);
        setNotesLoading(false);
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  return (
    <Box>
      <Box>
        <Flex
          justifyContent="space-between"
          marginY="10px"
        >
          <Box>
            <Flex
              gap="10px"
            >
              <Link
                href={`/profile/${friendId}`}
                _focus={{ outline: 'none' }}
              >
                <Avatar
                  size="sm"
                  src={image}
                  filter="brightness(0.9)"
                  _hover={{
                    filter: 'brightness(1.1)',
                  }}
                />
              </Link>
              <Link
                href={`/profile/${friendId}`}
                _focus={{ outline: 'none' }}
                className="small-text"
              >
                {username}
              </Link>
            </Flex>
          </Box>
          <Text
            color={textManual}
          >
            <Link
              href={`/userbooks/${username}/${bookId}`}
              _focus={{ outline: 'none' }}
              onClick={() => { navigate(`/userbooks/${username}/${bookId}`); }}
              color={textManual}
              className="small-text"
              _hover={{ color: `${textManual}`, textDecor: 'underline' }}
            >
              Full Book &#8594;
            </Link>
          </Text>
        </Flex>
      </Box>
      <Box>
        <Accordion
          allowToggle
          allowMultiple
        >
          <AccordionItem>
            <Box>
              <AccordionButton
                _focus={{ outline: 'none' }}
                onClick={hightlightHandler}
              >
                <Box className="small-text" flex="1" textAlign="left">
                  Highlights
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <Box>
                {
                  highlightsLoading ? <Box><MiniLoader /></Box> : (
                    <Box>
                      {
                      highlightsArr?.map((highlight:any) => (
                        <Box
                          p={2}
                          shadow="md"
                          borderWidth="1px"
                          bg={noteBg}
                          color={noteColor}
                          border={`1px solid ${borderColor}`}
                          marginTop="1rem"
                        >
                          <Flex
                            justifyContent="space-between"
                          >
                            <Button
                              onClick={() => {
                                bookContent.forEach((para:BookContent) => {
                                  if (para.page === highlight.pageNo) {
                                    updateBookPage(para.page, para.chapter);
                                  }
                                });
                              }}
                              className="link-btn link-btn-small"
                              border={`1px solid ${borderColor}`}
                              color={noteColor}
                              bg={pageColor}
                              _hover={{ bg: pageColorHover }}
                            >
                              Page No:
                              {' '}
                              {highlight.pageNo}
                              -
                              {highlight.pageNo + 1}
                            </Button>
                          </Flex>
                          <Box>
                            <Box
                              marginY="1em"
                              backgroundColor={highlight.color}
                              paddingX="5px"
                            >
                              <Text fontFamily="Garamond" className="small-text small-text-black">
                                {parse(highlight.text)}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                    </Box>
                  )
                }
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Box>
              <AccordionButton
                _focus={{ outline: 'none' }}
                onClick={notesHandler}
              >
                <Box className="small-text" flex="1" textAlign="left">
                  Notes
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              {notesLoading ? <MiniLoader />
                : (
                  <Box>
                    {notessArr?.map((note:any) => (
                      <Box
                        p={5}
                        shadow="md"
                        borderRadius="5px"
                        borderWidth="1px"
                        bg={noteBg}
                        color={noteColor}
                        border={`1px solid ${borderColor}`}
                        marginTop="1rem"
                      >
                        <Flex
                          justifyContent="space-between"
                        >
                          <Button
                            onClick={() => {
                              bookContent.forEach((para:BookContent) => {
                                if (para.page === note.pageNo) {
                                  updateBookPage(para.page, para.chapter);
                                }
                              });
                            }}
                            className="link-btn link-btn-small"
                            border={`1px solid ${borderColor}`}
                            color={noteColor}
                            bg={pageColor}
                            _hover={{ bg: pageColorHover }}
                          >
                            Page No:
                            {' '}
                            {note.pageNo}
                            -
                            {note.pageNo + 1}
                          </Button>
                        </Flex>
                        <Box>
                          <Box marginY="1em">
                            <Text fontFamily="Garamond" className="small-text">
                              {parse(note.passage)}
                            </Text>
                          </Box>
                          <Text
                            className="small-text"
                          >
                            Notes:
                          </Text>
                          <Textarea
                            defaultValue={note.note}
                            fontFamily="Garamond"
                            readOnly={true}
                            _focus={{ outline: 'none' }}
                            rows={5}
                            fontSize="1.6rem"
                          />
                          <Box>
                            {/* note.note ? (
                        <Button
                          onClick={() => { saveNote(note.id); }}
                        >
                          Edit Note
                        </Button>
                      )
                      : <Button onClick={() => { saveNote(note.id); }}>Add Note</Button> */}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
}
