/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { BookListItem, BookSeries } from '../../_interfaces/Books';

export default function BookList() {
  const [myBookList, setMyBookList] = useState<BookListItem []>([]);
  const [seriesObject, setSeriesObject] = useState<BookSeries>();
  const { seriesId } = useParams();
  // const [selectedBook, setSelectedBook] = useState();
  const getBooksFromSeries = () => {
    axios({
      method: 'POST',
      url: '/api/bookseries/booksBySeries',
      data: {
        seriesId,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { books } = res.data;
        if (books.length < 0) {
          setMyBookList([]);
          return;
        }
        setSeriesObject(res.data.seriesObject);
        setMyBookList(books);
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        // console.log(event.target.value);
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };

  useEffect(() => {
    getBooksFromSeries();
  }, []);

  const renderBooksList = () => myBookList?.map((book) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    // width="180px"
    // height="285px"
      marginX="auto"
    >
      <Link
        href={`/book/${book._id}`}
      >
        {book?.coverImage
          ? (
            <Image
              cursor="pointer"
              borderRadius="5px"
              src={book.coverImage}
            // width="180px"
            // height="285px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            />
          ) : (
            <Flex
              backgroundColor="#1F1F1F"
              color="#FFF"
            // width="180px"
            // height="285px"
              borderRadius="5px"
              justifyContent="center"
              flexDir="column"
              textAlign="center"
              paddingX="5px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            >
              <Text
                fontSize="16px"
                fontFamily="PTSerif"
              >
                {book.title}
              </Text>
              <Text
                marginTop="10px"
                fontSize="14px"
                fontFamily="PTSerif"
              >
                {book.author}
              </Text>
            </Flex>
          )}
      </Link>
    </Flex>
  ));
  return (
    <Box
      paddingY="2em"
    >
      <Box
        width={{ base: '90%', lg: '90%', xl: '75%' }}
        marginX="auto"
      >
        <Text
          className="medium-text medium-text-white medium-text-bold"
        >
          All Books in
          {' '}
          <i>
            {seriesObject?.name}
          </i>
        </Text>
      </Box>
      <SimpleGrid
        marginTop="20px"
        gridTemplateColumns={{
          base: '1fr 1fr 1fr 1fr',
          lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        width={{ base: '90%', lg: '90%', xl: '75%' }}
        gridGap={{ base: '10px', md: '20px' }}
        marginX="auto"
      >
        {renderBooksList()}
      </SimpleGrid>
      <Box />
    </Box>
  );
}
