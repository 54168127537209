import {
  Box,
  Flex,
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookContent } from '../../../_interfaces/Books';
import { updateBookContent } from '../../../_reducers/bookSlice';
import { setMyNotes, setMyHighlights } from '../../../_reducers/NoteSlice';
import { Highlight, UserNote } from '../../../_interfaces/Notes';

interface BookState {
  book: {
    currentBook: {
      currentPage: number,
      userBookId: string,
      bookId: string,
      contentId: string,
    },
    bookContent: BookContent[],
  }
}
interface Notes {
  note: {
    userNotes: UserNote [],
    userHighlights: Highlight [],
  }
}

export default function HighlightEdit() {
  const {
    currentPage,
  } = useSelector((state:BookState) => state.book.currentBook);
  const { bookContent } = useSelector((state: BookState) => state.book);
  const { userNotes, userHighlights } = useSelector((state:Notes) => state.note);
  const [highlightId, setHighlightId] = useState<string>();
  const [selectedText, setSelectedText] = useState<string | undefined>();
  const [notePosX, setNotePosX] = useState<number>(0);
  const [notePosY, setNotePosY] = useState<number>(0);
  const contextBg = useColorModeValue('#DDD', '#333');
  const dispatch = useDispatch();

  const {
    isOpen: isOpenEditHighlight,
    onOpen: onOpenEditHighlight,
    onClose: onCloseEditHighlight,
  } = useDisclosure();

  const handleHighlight = (event: MouseEvent) => {
    const currentTarget = event.target as HTMLElement;
    if (currentTarget.id.match('highlight') /* || currentTarget.parentNode?.id.match('highlight') */) {
      const pos = currentTarget.getBoundingClientRect();
      // const pos2 = highlightMenu.scrollHeight;
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/pageY
      // console.log(event.pageY);
      setNotePosX(pos?.x || 0);
      setNotePosY(event.pageY || 0);
      // console.log(currentTarget);
      setHighlightId(currentTarget.id);
      const getNoteText = currentTarget.innerText;
      // console.log(getNoteText);
      setSelectedText(getNoteText);

      onOpenEditHighlight();
      // onOpenMobileEditHighlight();
    }
    const highlightMenu = document.getElementById('hlightMenu');
    if (window?.getSelection()?.toString().length === 0 && highlightMenu) {
      // $('#hlightMenu').css('display', 'none');
      highlightMenu.style.display = 'none';
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('click', handleHighlight);
    return () => document.removeEventListener('click', handleHighlight);
  }, []);

  const annotateHandler = (anotateOnly:any, selectedSpan:any) => {
    // const getText = window.getSelection().getRangeAt(0).toString();
    // console.log(getText);
    const noteId = `note_${nanoid()}`;
    const notes = [...userNotes];
    notes.push({
      id: noteId,
      pageNo: currentPage,
      passage: anotateOnly ? selectedText : selectedSpan,
      note: '',
      currentPage,
    });
    dispatch(setMyNotes(notes));
  };

  const saveBookEdit = (
    startingParagraphId:any,
    updatedElement:any,
    updatedHighlights:any,
    isNotes: boolean,
  ) => {
    // setRetrievedBook(false);
    // console.log(`para id: ${startingParagraphId}`);
    const paragraphNo = Number(startingParagraphId.replace(/^\D+/g, ''));
    // console.log(updatedHighlights);
    const copyBookContent = [...bookContent];
    const updatedObject = { ...copyBookContent[paragraphNo] };
    updatedObject.text = updatedElement;
    copyBookContent[paragraphNo] = updatedObject;
    // updatedOjbect.text = updatedElement;
    // console.log(updatedOjbect);
    // getBookContent[paragraphNo] = updatedOjbect;
    dispatch(updateBookContent(copyBookContent));
    dispatch(setMyHighlights(updatedHighlights));
  };

  const highlightEditText = (color:string, colorClass:string, isNotes = false) => {
    // const tempArr = [];
    onCloseEditHighlight();
    // console.log(`id: ${highlightId}`);
    const highlightElement:HTMLElement | null = document.getElementById(highlightId || '')
      || document.getElementById(`m_${highlightId}`);
    // eslint-disable-next-line no-console

    const parentId:string | undefined = highlightElement?.parentElement?.id
      || highlightElement?.parentElement?.parentElement?.id;

    highlightElement?.removeAttribute('class');
    // console.log(getText);
    let tempHighlights = [...userHighlights];
    // console.log(highlightId);
    // setting up color highlight to be removed
    if (colorClass === 'None') {
      // uses the parent node to remove the mark so that it is now gone from dom
      tempHighlights = tempHighlights.filter((highlightItem) => highlightId !== highlightItem.id);

      // removed parent node
      // https://stackoverflow.com/questions/4232961/remove-a-html-tag-but-keep-the-innerhtml
      if (highlightElement) {
        highlightElement.outerHTML = highlightElement.innerHTML;
      }
    } else {
      for (let i = 0; i < tempHighlights.length; i += 1) {
        // console.log(highlightId);
        // console.log(highlightArr[i].id);
        if (highlightId === tempHighlights[i].id) {
          // console.log(true);
          const tempObj = { ...tempHighlights[i] };
          tempObj.color = color;
          tempHighlights[i] = tempObj;
        }
      }
      highlightElement?.classList.add(colorClass);
    }
    // console.log(highlightElement);
    const updatedElement = document?.getElementById(parentId || '')?.innerHTML.toString();
    // console.log(updatedElement);
    // console.log(gethighlights);
    if (updatedElement) {
      saveBookEdit(parentId, updatedElement, tempHighlights, isNotes);
    }
  };

  return (
    <Box
      position={{ base: 'fixed', md: 'absolute' }}
      top={{ base: '50%', md: `${notePosY - 100}px` }}
      left={{
        base: '5%', sm: '15%', md: `${notePosX + 25}px`, lg: `${notePosX + 75}px`,
      }}
      width={{ base: '90%', sm: '70%', md: 'auto' }}
    >
      <Popover
        isOpen={isOpenEditHighlight}
        onOpen={onOpenEditHighlight}
        onClose={onCloseEditHighlight}
        size="100%"
      >
        <PopoverContent
          width="fit-content"
          padding="1rem 2rem"
          border="none"
          backgroundColor={contextBg}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Flex
              gap="10px"
              justifyContent="center"
            >
              <Button
                onClick={() => { annotateHandler(true, selectedText); }}
                cursor="pointer"
                fontFamily="Garamond"
                fontSize="16px"
              >
                Annotate
              </Button>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightEditText('#F9E991', 'yellowTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#F9E991"
                  _hover={{ backgroundColor: '#FAFAD2' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightEditText('#A5C7F7', 'blueTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                // backgroundColor="#1E90FF"
                  bgColor="#A5C7F7"
                  _hover={{ backgroundColor: '#00BFFF' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightEditText('#D1F3C5', 'greenTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#D1F3C5"
                  _hover={{ backgroundColor: '#ADFF2F' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightEditText('#FFB6C7', 'redTheme'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  cursor="pointer"
                  backgroundColor="#FFB6C7"
                  _hover={{ backgroundColor: '#FFB6C1' }}
                />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => { highlightEditText('none', 'None'); }}
                  width="24px"
                  minW="0"
                  padding="0"
                  height="24px"
                  borderRadius="50%"
                  border="1px solid #333"
                  cursor="pointer"
                >
                  &#x2715;
                </Button>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
