import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { postBook } from '../../_reducers/bookSlice';
import BookView from './sections/BookView';
import HighlightEdit from './sections/HighlightEdits';
import Highlights from './sections/Highlights';
import Navbar from './sections/Navbar/Navbar';
import { BookListItem, DetailedBook, FootNote } from '../../_interfaces/Books';
import sampleBook from './sections/SampleBook';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const maxCharPerLine = 55;
const maxLinesPerPage = 22;

export default function TestBook() {
  const [retrievedBook, setRetrievedBook] = useState<boolean>(false);

  const dispatch = useDispatch();

  const createBook = () => {
    const chapterArr = [];
    const completeBook = [];
    const paragraphSplit = sampleBook.split(/\r?\n\r?\n/);
    let currentPage = 1;
    let currentChapterName = 'CHAPTER: INTRO';
    let reachedLines = 1;
    let spacesPerLineUsed = 0;
    let wordLength = 0;
    let wordInParagraph = '';
    let updatedParagraph = '';
    const desktopPages = [];
    const mobilePages = [];
    let paragraphNo = 0;
    let setFootNoteSection = false;
    const footNotes = [];
    let footNoteId = 1;

    for (let i = 0; i < paragraphSplit.length; i += 1) {
      let splitOccured = false;
      const currentParagraph = paragraphSplit[i];
      let floor = 0;
      // iterate through each paragraph
      if (setFootNoteSection) {
        footNotes.push({
          note: paragraphSplit[i],
          id: footNoteId,
        });
        footNoteId += 1;
      }
      for (let j = 0; j < currentParagraph.length; j += 1) {
        // if a new chapter is found
        // updatedParagraph = updatedParagraph.concat(currentParagraph[j]);
        if (currentParagraph.match('CHAPTER')) {
          if (currentPage % 2 !== 0) desktopPages.push(currentPage);
          mobilePages.push(currentPage);
          currentPage += 1;
          // start chapter in a new page
          completeBook.push({
            paragraphNo,
            page: currentPage,
            text: currentParagraph,
            chapterTitle: currentParagraph,
            chapter: currentParagraph,
            linebreak: true,
          });
          // update chapter name and add it to list of chapter names
          currentChapterName = currentParagraph;
          chapterArr.push(currentParagraph);
          reachedLines = 2;
          spacesPerLineUsed = 0;
          updatedParagraph = '';
          paragraphNo += 1;
          break;
        }
        if (currentParagraph.match('FOOTNOTES')) {
          if (currentPage % 2 !== 0) desktopPages.push(currentPage);
          mobilePages.push(currentPage);
          currentPage += 1;
          // start chapter in a new page
          completeBook.push({
            page: currentPage,
            text: currentParagraph,
            chapterTitle: currentParagraph,
            chapter: currentParagraph,
            linebreak: true,
          });
          // update chapter name and add it to list of chapter names
          currentChapterName = currentParagraph;
          chapterArr.push(currentParagraph);
          reachedLines = 2;
          spacesPerLineUsed = 0;
          updatedParagraph = '';
          setFootNoteSection = true;
          break;
        }
        // test if char is white space or non white space char
        if (/\S/.test(currentParagraph[j])) {
          // if its a word concat it with current string
          wordInParagraph = wordInParagraph.concat(currentParagraph[j]);
          // console.log(wordInParagraph);
          // or its a white space\
        } else {
          // length of word
          // let currentSpace = wordInParagraph.length;
          // maximum lines completed
          wordLength = wordInParagraph.length + 1;
          if (maxLinesPerPage <= reachedLines) {
            const totalLines = spacesPerLineUsed + wordLength;
            // if (totalLines > 40) console.log(totalLines);
            // reachedLines = 0;
            // no more room in the l
            if (maxCharPerLine < totalLines || maxLinesPerPage < reachedLines) {
              // split string and start new page
              // slice the remaining string
              const finalString = updatedParagraph;

              // add it to the book
              completeBook.push({
                page: maxLinesPerPage < reachedLines ? currentPage + 1 : currentPage,
                text: finalString,
                paragraphNo,
                chapterTitle: '',
                chapter: currentChapterName,
                linebreak: false,
              });
              updatedParagraph = `${wordInParagraph} `;
              // update page number to next page
              if (currentPage % 2 !== 0) desktopPages.push(currentPage);
              mobilePages.push(currentPage);
              currentPage += 1;
              // reset the lines used
              reachedLines = 0;
              // reset space per line as well and add word + white space to it
              spacesPerLineUsed = wordLength + 1;
              // indicate that a split has happened
              splitOccured = true;
              // update the floor
              floor = j + 1;
              wordInParagraph = '';
              wordLength = 0;
              paragraphNo += 1;
            } else {
              // on the last line but still room left and add one to account for white space
              spacesPerLineUsed += (wordLength + 1);
              updatedParagraph = updatedParagraph.concat(`${wordInParagraph} `);
              wordInParagraph = '';
              wordLength = 0;
            }
          } else {
            const newSpaceUsed = spacesPerLineUsed + wordLength;
            if (maxCharPerLine < newSpaceUsed) {
              reachedLines += 1;
              spacesPerLineUsed = 0;
              spacesPerLineUsed += wordInParagraph.length + 1;
              updatedParagraph = updatedParagraph.concat(`${wordInParagraph} `);
              wordInParagraph = '';
              wordLength = 0;
            } else {
              // console.log(spacesPerLine);
              spacesPerLineUsed += wordInParagraph.length + 1;
              updatedParagraph = updatedParagraph.concat(`${wordInParagraph} `);
              // console.log(updatedParagraph);
              // console.log(reachedLines);
              wordInParagraph = '';
              wordLength = 0;
            }
          }
        }
        if (j === currentParagraph.length - 1) {
          // const finalString = currentParagraph.slice(floor);
          const finalString = updatedParagraph + wordInParagraph;
          // console.log(finalString);
          completeBook.push({
            page: currentPage,
            text: finalString,
            chapterTitle: '',
            chapter: currentChapterName,
            linebreak: maxLinesPerPage > reachedLines,
            paragraphNo,
          });
          paragraphNo += 1;
          updatedParagraph = '';
          wordInParagraph = '';
          wordLength = 0;
          spacesPerLineUsed = 0;
          reachedLines += 2;
        }
      }
    }
    // add a final page if page ends evenly for desktop version
    if (currentPage % 2 !== 0) desktopPages.push(currentPage);
    mobilePages.push(currentPage);
    // console.log(footNotes);
    // console.log(complete Book);
    const tempBook = {
      desktopPagination: desktopPages,
      mobilePagination: mobilePages,
      chapterList: chapterArr,
      totalPages: currentPage,
      content: completeBook,
      currentPage: 1,
      footNotes,
    };
    return tempBook;
  };

  useEffect(() => {
    const newBook = createBook();
    console.log(newBook);
    const allbookNotes:any = newBook.footNotes;

    const book:DetailedBook = {
      title: 'Alice’s Adventures in Wonderland',
      bookId: nanoid(),
      _id: nanoid(),
      author: 'Lewis Carroll',
      currentPage: newBook.currentPage,
      desktopPagination: newBook.desktopPagination,
      mobilePagination: newBook.mobilePagination,
      totalPages: newBook.totalPages,
      footNotes: allbookNotes,
      chapterList: newBook.chapterList,
      currentChapter: 'CHAPTER: INTRO',
    };
    dispatch(postBook(
      {
        bookData: book,
        bookContent: newBook.content,
        userBook: true,
        isPaid: true,
      },
    ));
    setRetrievedBook(true);
  }, [sampleBook]);

  return (
    <Box as="main">
      <Navbar />
      {
        retrievedBook ? (
          <>
            <BookView />
            <Highlights />
            <HighlightEdit />
          </>
        ) : <LoadingSpinner />
      }
    </Box>
  );
}
