/* eslint-disable no-underscore-dangle */
import {
  Box,
  Text,
  Avatar,
  SimpleGrid,
  useColorModeValue,
  Flex,
  Link,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/axios';
import { Friend, UserBasic } from '../../_interfaces/User';
import CustomButton from '../CustomButton/CustomButton';
import CustomPagination from '../CustomPagination/CustomPagination';
import './FriendsList.scss';

interface FriendState {
  user: {
    friendList: Friend [],
  }
}

export default function FriendList() {
  const {
    userId,
    username,
  } = useSelector((state: UserBasic) => state.user.userData);
  const { friendList } = useSelector((state: FriendState) => state.user);
  const [selectedFriend, setSelectedFriend] = useState<string>();
  const [numFriends, setNumFriends] = useState<number>(0);
  const [myFriends, setMyFriends] = useState<Friend []>();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [hasFriends, setHasFriends] = useState(false);
  const itemsPerPage = 8;
  const {
    isOpen: isOpenRemoveFriend,
    onOpen: onOpenRemoveFriend,
    onClose: onCloseRemoveFriend,
  } = useDisclosure();

  const handlePageClick = (event: any) => {
    if (myFriends?.length) {
      const newOffset = (event.selected * itemsPerPage) % numFriends;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
      // );
      axios({
        method: 'POST',
        url: '/api/connections/friendsByIndex',
        data: {
          userId,
          itemsPerPage,
          offsetIndex: newOffset,
        },
      })
        .then((res) => {
          // eslint-disable-next-line no-console
          // console.log(res.data.books);
          const { friends } = res.data;
          console.log(friends);
          setItemOffset(newOffset);
          setMyFriends(friends);
        })
        .catch((resErr) => {
          // eslint-disable-next-line no-console
          console.log(resErr);
          // eslint-disable-next-line no-console
        });
    }
  };

  const retireveFriends = () => {
    axios({
      method: 'POST',
      url: '/api/connections/friendsByIndex',
      data: {
        userId,
        itemsPerPage,
        offsetIndex: 0,
      },
    })
      .then((res) => {
        // eslint-disable-next-line no-console
        // console.log(res.data.books);
        const { friends, friendCount } = res.data;
        const endOffset = itemOffset + itemsPerPage;
        // setBooksDisplayed(books.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(friendCount / itemsPerPage));
        setNumFriends(friendCount);
        if (friendCount > 0) setHasFriends(true);
        setItemOffset(endOffset);
        setMyFriends(friends);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };

  const removeFriend = () => {
    // console.log(`id ${selectedFriend}`);
    axios({
      method: 'DELETE',
      url: '/api/connections/rejectFriendRequest',
      data: {
        userId: selectedFriend,
        requesterId: userId,
      },
    })
      .then(() => {
        retireveFriends();
        onCloseRemoveFriend();
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };

  useEffect(() => {
    if (userId) {
      retireveFriends();
    }
  }, [userId]);
  const renderFriendList = () => myFriends?.map((friend) => (
    <Box
      textAlign="center"
      paddingY="2em"
      borderRadius="5px"
      bg="#222"
    >
      <Box>
        <Link
          href={`/profile/${friend._id}`}
          _focus={{ outline: 'none' }}
        >
          <Avatar
            aria-label={`User ${friend.username} Profile image`}
            size="xl"
            src={friend?.image}
            filter="brightness(0.9)"
            _hover={{
              filter: 'brightness(1)',
              transform: 'scale(1.08)',
              transition: 'transform 0.25s ease',
            }}
          />
        </Link>
      </Box>
      <Box
        marginY="5px"
        width="120px"
        marginX="auto"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Link
          aria-label={`link to ${friend.username} profile`}
          href={`/profile/${friend?._id}`}
          className="small-text small-text-link"
        >
          {friend.username}
        </Link>
      </Box>
      <CustomButton
        label="Remove"
        testId={`remove-friend-${friend._id}`}
        buttonColor="night"
        buttonProps={{
          onClick: (() => {
            onOpenRemoveFriend();
            setSelectedFriend(friend._id);
          }),
        }}
        spinnerId={undefined}
        buttonType="button"
        size="small"
      />
    </Box>
  ));
  return (
    <Box
      className="friendslist-container"
    >
      {hasFriends
        ? (
          <Box>
            <Modal isOpen={isOpenRemoveFriend} onClose={onCloseRemoveFriend}>
              <ModalOverlay />
              <ModalContent
                backgroundColor="#333"
              >
                <ModalHeader className="medium-text medium-text-white">Remove Friend</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text
                    className="small-text small-text-white"
                  >
                    Are you sure you want to remove this user from your friend list?
                  </Text>
                </ModalBody>
                <ModalFooter
                  gap="1rem"
                >
                  <CustomButton
                    label="Cancel"
                    testId="remove-friend-cancel"
                    buttonColor="black"
                    buttonProps={{
                      onClick: (() => {
                        onCloseRemoveFriend();
                      }),
                    }}
                    spinnerId={undefined}
                    buttonType="button"
                    size="small"
                  />
                  <CustomButton
                    label="Remove"
                    testId="remove-friend-submit"
                    buttonColor="black"
                    buttonProps={{
                      onClick: (() => {
                        removeFriend();
                      }),
                    }}
                    spinnerId={undefined}
                    buttonType="button"
                    size="small"
                  />
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Box>
              {
              myFriends?.length
                ? (
                  <Box>
                    <Box
                      width={{ base: '90%', lg: '90%', xl: '85%' }}
                      marginX="auto"
                      textAlign="left"
                      marginTop="2rem"
                    >
                      <Text
                        className="medium-text medium-text-white"
                      >
                        My Friends
                      </Text>
                    </Box>
                    <SimpleGrid
                      marginTop="20px"
                      gridTemplateColumns={{
                        base: '1fr 1fr',
                        md: '1fr 1fr 1fr 1fr',
                        xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                      }}
                      width={{ base: '90%', lg: '90%', xl: '85%' }}
                      gridGap={{ base: '10px', md: '20px' }}
                      marginX="auto"
                    >
                      {renderFriendList()}
                    </SimpleGrid>
                    <CustomPagination
                      pageCount={pageCount}
                      startingPage={startingPage}
                      handlePageClick={handlePageClick}
                    />
                  </Box>
                )
                : <Box />
              }
            </Box>
          </Box>
        ) : <Box />}
    </Box>
  );
}
