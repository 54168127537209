/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { UserBasic } from '../../_interfaces/User';
import './Subscription.scss';
import SmallLogo from '../../components/Logos/SmallLogo/SmallLogo';
import CustomButton from '../../components/CustomButton/CustomButton';

export default function FreeTier() {
  const { isPaid } = useSelector((state: UserBasic) => state.user.userData);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // if (hasSubscription === false) return;
    // console.log('continue');
  };
  return (
    <Box
      className="subscription-card"
      width={{ base: '85%', sm: '300px', md: '350px' }}
      height={{ base: '500px', sm: '600px', md: '650px' }}
      marginX={{ base: 'auto', md: '1rem' }}
    >
      <SmallLogo color="white" />
      <Text
        className="large-text large-text-white"
        marginTop="1.5rem"
      >
        Starter
      </Text>
      <Box>
        <Text
          className="section-heading"
          marginTop="1.5rem"
        >
          Free
        </Text>
      </Box>
      <Box>
        <Text
          className="small-text small-text-white"
          data-testid="live-tester"
        >
          Read any of our books free of charge and
          enjoy the richest theological writings
          made easily accessible and readable.
        </Text>
      </Box>
      <Box>
        <Text
          className="large-text large-text-white"
          marginTop="3rem"
        >
          Start Reading Today!
        </Text>
      </Box>
      <Box
        width={{ base: '95%', sm: '350px' }}
        padding={{ base: '10px', sm: '31px' }}
      >
        { isPaid
          ? <Box />
          : (
            <CustomButton
              label="Current Plan"
              testId="current_plan_button"
              buttonColor="night"
              buttonProps={{
                onClick: handleSubmit,
                id: 'current_plan_button',
              }}
              spinnerId={undefined}
              buttonType="submit"
              size="medium"
            />
          )}
      </Box>
    </Box>
  );
}
