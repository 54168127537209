import React from 'react';
import {
  Box,
  Text,
  Flex,
  Link,
} from '@chakra-ui/react';
import FacebookImg from '../../assets/images/facebook.png';
import TwitterImg from '../../assets/images/twitter.png';
import CustomIcon from '../Icon/CustomIcon';
import MainLogo from '../Logos/MainLogo/MainLogo';
import './Footer.scss';

function Footer() {
  return (
    <Box
      as="section"
      className="app-footer"
    >
      <MainLogo theme="light" />
      <Flex
        marginY="2rem"
        width="100%"
        marginX="auto"
        justifyContent="center"
        gap="1rem"
      >
        <CustomIcon
          src={FacebookImg}
          alt="Theologian's Pen Facebook Page"
          href="https://www.facebook.com/Theologianspen"
        />
        <CustomIcon
          src={TwitterImg}
          alt="Theologian's Pen Twitter Page"
          href="https://twitter.com/theologianspen"
        />
      </Flex>
      <Box
        id="footer-links"
      >
        <Link
          href="/privacy"
          target="_blank"
          className="small-text small-text-footer-links"
        >
          Do Not Sell My Personal Info
        </Link>
        <Link
          href="/privacy"
          target="_blank"
          className="small-text small-text-footer-links"
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms"
          target="_blank"
          className="small-text small-text-footer-links"
        >
          Terms of Service
        </Link>
        <Link
          href="/cookies"
          target="_blank"
          className="small-text small-text-footer-links"
        >
          Cookies
        </Link>
        <Link
          href="/acceptable-use"
          target="_blank"
          className="small-text small-text-footer-links"
        >
          Acceptable Use
        </Link>
      </Box>
      <Text
        className="small-text small-text-white"
        marginTop="1em"
      >
        Copyright
        {' '}
        {new Date().getFullYear()}
        . All Rights Reserved. Theologian&apos;s Pen.
      </Text>
    </Box>
  );
}

export default Footer;
