/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Link,
  useColorMode,
  Switch,
  useColorModeValue,
  AvatarBadge,
  Badge,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import axios, { axiosPrivate } from '../../api/axios';

function Navbar() {
  const [darkMode, setDarkMode] = useState();
  const [socket, setSocket] = useState<any>();
  const [friendNotifications, setFriendNotifications] = useState<any>([]);
  const user = useSelector((state:any) => state.user.userData);
  const { toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const textManual = useColorModeValue('#000', '#FFF');
  const BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:5000' : 'https://theologianspen.herokuapp.com/';

  const logout = () => {
    const deviceId = window.localStorage.getItem('theologianDeviceId');
    const theologianAccess: string = window.localStorage.getItem('theologianAccess') || '';
    axiosPrivate({
      method: 'PUT',
      url: '/api/user/logoutUser',
      withCredentials: true,
      data: {
        deviceId,
        username: user.username,
      },
      headers: {
        theologianAccess,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          window.localStorage.removeItem('theologianAccess');
          window.localStorage.removeItem('theologianRefresh');
          navigate('/login', { replace: true });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    if (user.userId) {
      if (location.pathname === '/friends') {
        axios({
          method: 'DELETE',
          url: '/api/connections/delNotifications',
          data: {
            userId: user.userId,
          },
        })
          .catch((resErr) => {
            // eslint-disable-next-line no-console
            console.log(resErr);
            // eslint-disable-next-line no-console
          });
      } else {
        axios({
          method: 'GET',
          url: `/api/connections/getNotifications/${user.userId}`,
        })
          .then((res) => {
            // console.log(res.data.books);
            const { notifications } = res.data;
            setFriendNotifications(notifications);
          })
          .catch((resErr) => {
            // eslint-disable-next-line no-console
            console.log(resErr);
            // eslint-disable-next-line no-console
          });
      }
    }
  }, [location.pathname, user]);

  useEffect(() => {
    if (user.username) {
      // console.log(`dark mode: ${user.trialMode}`);
      // setDarkMode(user.darkMode);
      if (!socket) {
        const tempSocket = io(BASE_URL, { withCredentials: true });
        tempSocket.emit('globalroom', user.userId);
        setSocket(tempSocket);
      }
      if (socket) {
        socket.on('friendRequestSent', async (friendRequest:any) => {
          // console.log(friendRequest);
          const updateNotifcations = friendNotifications;
          updateNotifcations.push(friendRequest);
          setFriendNotifications(updateNotifcations);
          await socket.disconnect();
          setSocket(null);
        });
      }
    }
  }, [user, socket, BASE_URL, friendNotifications]);

  const updateUserDarkMode = () => {
    axiosPrivate({
      method: 'PUT',
      url: '/api/user/toggleDarkMode',
      data: {
        username: user.username,
      },
    })
      .then((res) => {
        const { toggleValue } = res.data;
        setDarkMode(toggleValue);
        toggleColorMode();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  return (
    <Box
      as="section"
      backgroundColor="#000"
    >
      <Flex
        justifyContent="space-between"
        width="95%"
        marginX="auto"
        height="60px"
      >
        <Box
          color="#FFF"
          display={{ base: 'none', md: 'block' }}
          data-testid="logged-nav-container"
        >
          <Flex>
            <Flex
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              height="60px"
              width="100px"
              data-testid="logged-nav-home"
            >
              <Link
                className="medium-text medium-text-link"
                href="/home"
                _focus={{ outline: 'none' }}
                _hover={{ color: `${textManual}`, textDecor: 'underline' }}
              >
                Home
              </Link>
            </Flex>
            <Flex
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              height="60px"
              width="100px"
              data-testid="logged-nav-browse"
            >
              <Link
                className="medium-text medium-text-link"
                href="/search"
                _focus={{ outline: 'none' }}
                _hover={{ color: `${textManual}`, textDecor: 'underline' }}
              >

                Browse
              </Link>
            </Flex>
            <Flex
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              height="60px"
              width="100px"
              data-testid="logged-nav-series"
            >
              <Link
                className="medium-text medium-text-link"
                href="/series"
                _focus={{ outline: 'none' }}
                _hover={{ color: `${textManual}`, textDecor: 'underline' }}
              >

                Series
              </Link>
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Flex
            gap="10px"
          >
            <Box
              display={{ base: 'none', md: 'flex' }}
              justifyContent="center"
              flexDir="column"
              height="60px"
            >
              { user.trialMode
                ? (
                  <Text
                    as="h4"
                    className="small-text small-text-white"
                  >
                    Trial:
                    {' '}
                    {user.trialDays}
                    {' '}
                    Day(s) Left
                  </Text>
                ) : <Box />}
            </Box>
            <Menu>
              <MenuButton
                opacity="0.8"
                _hover={{ opacity: '1.0' }}
              >
                <Flex
                  height="60px"
                  justifyContent="center"
                  flexDir="column"
                  data-testid="logged-nav-profile"
                >
                  <Avatar
                    src={user.image}
                  // size="md"
                    width="42px"
                    height="42px"
                  >
                    {friendNotifications.length > 0
                      ? (
                        <AvatarBadge boxSize="1.25em" bg="red.400">
                          {friendNotifications.length}
                        </AvatarBadge>
                      ) : <Box />}
                  </Avatar>
                </Flex>
              </MenuButton>
              <Box
                display={{ base: 'flex', md: 'none' }}
                alignSelf="right"
                justifyContent="center"
                flexDir="column"
              >
                { user.trialMode
                  ? (

                    <Text
                      className="small-text small-text-white"
                    >
                      Trial:
                      {' '}
                      {user.trialDays}
                      {' '}
                      Days Left
                    </Text>
                  ) : <Box />}
              </Box>
              <MenuList
                id="logged-nav-menu"
                zIndex={10}
                backgroundColor="#333"
                borderColor="#333"
              >
                <Box
                  display={{ base: 'block', md: 'none' }}
                >
                  <Link
                    href="/home"
                    className="link-btn link-btn-night"
                  >
                    Home
                  </Link>
                  <Link
                    id="browse-btn"
                    href="/search"
                    className="link-btn link-btn-night"
                  >

                    Browse
                  </Link>
                  <Link
                    id="series-btn"
                    href="/series"
                    className="link-btn link-btn-night"
                  >
                    Series
                  </Link>
                </Box>
                <Link
                  href="/myAccount"
                  id="account-btn"
                  className="link-btn link-btn-night"
                >
                  Account
                </Link>
                <Link
                  id="friends-btn"
                  href="/friends"
                  className="link-btn link-btn-night"
                >
                  Friends
                  {' '}
                  { friendNotifications.length > 0 ? (
                    <Badge
                      as="span"
                      ml="10px"
                      colorScheme="red"
                    >
                      {friendNotifications.length}
                    </Badge>
                  ) : <Box />}
                </Link>
                <Link
                  id="subscribe-btn"
                  href="/subscription"
                  className="link-btn link-btn-night"
                >
                  Subscription
                </Link>
                {
                user.roles?.includes('2023')
                  ? (
                    <Box>
                      <Link
                        id="upload-btn"
                        href="/uploadBook"
                        className="link-btn link-btn-night"

                      >
                        Upload
                      </Link>
                      <Link
                        id="admin-btn"
                        href="/adminPage"
                        className="link-btn link-btn-night"
                      >
                        Admin
                      </Link>
                    </Box>
                  ) : <Box />
              }
                <Link
                  href="/settings"
                  id="settings-btn"
                  className="link-btn link-btn-night"
                >
                  Settings
                </Link>
                <Text
                  as="button"
                  id="logout-btn"
                  width="100%"
                  className="link-btn link-btn-night"
                  onClick={logout}
                >
                  Logout
                </Text>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
export default Navbar;
