import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import pageImg from '../../../assets/images/pagination_theology.png';
import { BookContent } from '../../../_interfaces/Books';
import updateBookPage from '../../../_helper/BookHelper';
import './MobilePagination.scss';

interface BookState {
  book: {
    currentBook: {
      mobilePagination: number[],
      currentPage: number,
    }
  }
}
interface Content {
  book: {
    bookContent: BookContent [],
  }
}

export default function MobilePagination() {
  const {
    mobilePagination,
    currentPage,
  } = useSelector((state:BookState) => state.book.currentBook);
  const { bookContent } = useSelector((state:Content) => state.book);
  const toggleBorder = useColorModeValue('#777', '#FFF');

  const goToPage = (newPageNo:any) => {
    // console.log(newPageNo);
    let getChapter = '';
    if (newPageNo === 1) {
      getChapter = 'CHAPTER: INTRO';
    } else {
      bookContent.forEach((paragraph:BookContent) => {
        if (paragraph.page === newPageNo) {
          getChapter = paragraph.chapter;
        }
      });
    }
    updateBookPage(newPageNo, getChapter);
    window.scrollTo(0, 0);
  };
  const checkPageRange = (pageNo:any) => {
    if (pageNo + 1 === currentPage || pageNo + 2 === currentPage
          || pageNo - 1 === currentPage || pageNo - 2 === currentPage
          || pageNo === currentPage
    ) {
      return true;
    }
    return false;
  };
  const renderFirstPagePagination = () => mobilePagination?.map((pageNo:any) => (
    <Box
      height="inherit"
    >
      {pageNo < 6
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {currentPage === pageNo
              ? (
                <Box
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              ) : (
                <Box
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));
  const renderPagePagination = () => mobilePagination?.map((pageNo:any) => (
    <Box>
      { checkPageRange(pageNo)
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {currentPage === pageNo
              ? (
                <Box
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              ) : (
                <Box
                  position="relative"
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));
  const renderLastPagePagination = () => mobilePagination?.map((pageNo:any) => (
    <Box>
      {pageNo >= mobilePagination[mobilePagination.length - 5]
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {currentPage === pageNo
              ? (
                <Box
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              ) : (
                <Box
                  className="mobile-book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));
  return (
    <Box
      marginX="auto"
      paddingTop="20px"
      paddingBottom="60px"
      width={{ base: '90vw', sm: '400px', md: 'auto' }}
    >
      {currentPage < 4
        ? (
          <Flex>
            {renderFirstPagePagination()}
          </Flex>
        ) : (
          <Box>
            {
                currentPage >= mobilePagination[mobilePagination.length - 2]
                  ? (
                    <Flex>
                      {renderLastPagePagination()}
                    </Flex>
                  ) : (
                    <Flex>
                      {renderPagePagination()}
                    </Flex>
                  )
              }
          </Box>
        )}
    </Box>
  );
}
