/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Box,
  Link,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import axios, { axiosPrivate } from '../../api/axios';
import Header from './sections/Header';
import SermonPreview from '../../assets/images/sermon_preview.jpg';
import './RegisterPage.scss';
import CustomButton from '../../components/CustomButton/CustomButton';

// TODO: Add form fields for name
function RegisterPage() {
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState<string>();

  const debouncedUsernameCheck = useCallback(async (username: string | undefined) => {
    if (!username) {
      return true;
    }
    const res = await axios.get(`/api/user/username/${username.trim()}`);
    return !res.data.user;
  }, []);

  const debouncedEmailCheck = useCallback(async (email: string | undefined) => {
    if (!email) {
      return true;
    }
    const res = await axios.get(`/api/user/email/${email.trim()}`);
    return !res.data.user;
  }, []);

  const handleSpinner = (type: 'add' | 'remove') => {
    const spinnerElement = document.getElementById('sign-up-spinner')?.classList;
    spinnerElement?.add(type === 'add' ? 'spinner-on' : 'spinner-off');
    spinnerElement?.remove(type === 'add' ? 'spinner-off' : 'spinner-on');
  };

  return (
    <Box
      className="register-page"
    >
      <Header />
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
          confirmPassword: '',
          termsCheck: false,
        }}
      // TODO: Evaluate username / email check only when those inputs are changed
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .required('Name is required')
            .test('usernameAvailable', 'This username is already taken', debouncedUsernameCheck),
          email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
            .test('emailAvailable', 'An account with this email already exists', debouncedEmailCheck),
          password: Yup.string()
            .required('Password is required')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-+(){}[\]|;:"'=,.<>~`_/\\])[A-Za-z\d@$!%*#?&-+(){}[\]|:;"'=,.<>~`_/\\]{8,}$/,
              'Must Contain at least 8 Characters, One Uppercase, One Number and One Special Character',
            ),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Confirm Password is required'),
          termsCheck: Yup.boolean()
            .oneOf([true], 'You must click on checkbox to agree with Kloak\'s terms and services'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // console.log('sta')
            handleSpinner('add');
            const dataToSubmit = {
              email: values.email,
              password: values.password,
              username: values.username,
              image: `http://gravatar.com/avatar/${moment().unix()}?d=identicon`,
            };
            axiosPrivate({
              method: 'POST',
              url: '/api/user/registerUser',
              data: dataToSubmit,
            })
              .then((res) => {
                if (res.status === 200) {
                  setSuccessMsg(`Thank you for registering with us!
                    Please login to enjoy the works of your favorite theologians.`);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                setFormErrorMessage(err.message);
                setSubmitting(false);
                handleSpinner('remove');
              });
          }, 500);
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box as="form">
              <Box className="register-form-container">
                <Box
                  className="register-left-side"
                >
                  <Image
                    alt="Bible Opened"
                    width={{ base: '25rem', md: '40rem' }}
                    objectFit="contain"
                    src={SermonPreview}
                  />
                </Box>
                <Box
                  className="register-right-side"
                >
                  { successMsg
                    ? (
                      <Flex
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        marginTop="2em"
                        height="100%"
                      >
                        <Text
                          textAlign="center"
                          color="#000"
                          lineHeight="24px"
                          fontSize="22px"
                          fontWeight="500"
                          marginY="1em"
                          width="500px"
                          fontFamily="PTSerif"
                        >
                          {successMsg}
                        </Text>
                        <Button
                          role="link"
                          borderRadius="0"
                          width="140px"
                          height="44px"
                          bg="#000"
                          color="#FFF"
                          textTransform="lowercase"
                          fontFamily="PTSerif"
                          fontSize="16px"
                          fontWeight="600"
                          letterSpacing="1px"
                          cursor="pointer"
                          _hover={{ bg: '#333' }}
                          onClick={() => { navigate('/login'); }}
                        >
                          Login Here
                        </Button>
                      </Flex>
                    ) : (
                      <>
                        <Text
                          as="h1"
                          className="section-heading"
                        >
                          Register
                        </Text>
                        <Field name="username">
                          {() => (
                            <FormControl
                              isRequired
                              maxW="500px"
                            >
                              <label
                                htmlFor="username"
                                className="small-text small-text-black"
                              >
                                Username
                                {' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                id="username"
                                className="input-custom"
                                aria-label="Enter Desired Username"
                                type="text"
                                placeholder="Enter a username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Text
                                as="p"
                                className="small-text small-text-error"
                                marginTop="5px"
                              >
                                {touched.username && errors.username}
                              </Text>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="email">
                          {() => (
                            <FormControl
                              isRequired
                              mb="4px"
                              maxW="500px"
                            >
                              <label
                                htmlFor="email"
                                className="small-text small-text-black"
                              >
                                Email
                                {' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                id="email"
                                className="input-custom"
                                aria-label="Enter your Email"
                                type="email"
                                placeholder="John.Owen@EMAIL.COM"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Text
                                as="p"
                                className="small-text small-text-error"
                                marginTop="5px"
                              >
                                {touched.email && errors.email}
                              </Text>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="password">
                          {() => (
                            <FormControl
                              isRequired
                              mb="4px"
                              maxW="500px"
                            >
                              <label
                                htmlFor="password"
                                className="small-text small-text-black"
                              >
                                Password
                                {' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                id="password"
                                className="input-custom"
                                aria-label="Enter Password"
                                type="password"
                                placeholder="Enter a Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Text
                                as="p"
                                className="small-text small-text-error"
                                marginTop="5px"
                              >
                                {touched.password && errors.password}
                              </Text>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="confirmPassword">
                          {() => (
                            <FormControl
                              isRequired
                              mb="4px"
                              maxW="500px"
                            >
                              <label
                                htmlFor="confirmPassword"
                                className="small-text small-text-black"
                              >
                                Confirm Password
                                {' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                id="confirmPassword"
                                className="input-custom"
                                aria-label="Re enter your Password"
                                type="password"
                                placeholder="Confirm Your Password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Text
                                as="p"
                                className="small-text small-text-error"
                                marginTop="5px"
                              >
                                {touched.confirmPassword && errors.confirmPassword}
                              </Text>
                            </FormControl>
                          )}
                        </Field>
                        <Flex marginTop={{ base: '0.5rem' }}>
                          <Box>
                            <Field roles="checkbox" name="termsCheck" type="checkbox" style={{ marginTop: '7px', outerWidth: '2rem' }} />
                          </Box>
                          <FormControl
                            isRequired
                            mb="4px"
                          >
                            <FormLabel
                              htmlFor="termsCheck"
                              ml="4px"
                              fontWeight="400"
                              paddingLeft={{ base: '0.5rem' }}
                              fontSize="1.6rem"
                            >
                              I agree to the Theologian&apos;s Pen&apos;s
                              {' '}
                              <Link role="link" href="/terms" color="#1890ff" target="_blank">Terms of Service</Link>
                              {' '}
                              and
                              <Link role="link" href="/privacy" color="#1890ff" target="_blank"> Privacy Policy</Link>
                            </FormLabel>
                            <Box
                              color="red"
                              fontSize="16px"
                              minHeight="21px"
                              paddingLeft={{ base: '0.5rem' }}
                              fontFamily="PTSerif"
                              marginBottom="1rem"
                            >
                              {touched.termsCheck && errors.termsCheck}
                            </Box>
                          </FormControl>
                        </Flex>
                        <CustomButton
                          buttonType="submit"
                          buttonColor="black"
                          testId="create-account-button"
                          buttonProps={{
                            id: 'create-account-button',
                            onClick: handleSubmit,
                            onLoad: isSubmitting,
                          }}
                          label="Create Account"
                          spinnerId="sign-up-spinner"
                          size="medium"
                        />
                        {formErrorMessage && (
                        <Text
                          as="p"
                          className="small-text small-text-error"
                          marginTop="5px"
                        >
                          {formErrorMessage}
                        </Text>
                        )}
                      </>
                    )}
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}

export default RegisterPage;
