/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { compressAccurately } from 'image-conversion';
import {
  Box,
  Flex,
  Spinner,
  Text,
  Heading,
  Stack,
  Button,
  Avatar,
  useColorModeValue,
  Link,
  Image,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertDescription,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { Friend, UserBasic } from '../../_interfaces/User';
import { BookListItem } from '../../_interfaces/Books';
import CustomButton from '../../components/CustomButton/CustomButton';
import './FriendProfile.scss';

function FriendProfile() {
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [loadingBooks, setLoadingBooks] = useState<boolean>(true);
  const [connectStatus, setConnectStatus] = useState<string>();
  const [isPaidUser, setIsPaidUser] = useState<boolean>();
  const [friendProfile, setFriendProfile] = useState<Friend>();
  const { userId } = useParams();
  const [friendStatus, setFriendStatus] = useState<boolean>();
  const [friendMsg, setFriendMsg] = useState<string>();
  const [subFriendMsg, setSubFriendMsg] = useState<string>();
  const [pageCount, setPageCount] = useState(0);
  const [myBooks, setMyBooks] = useState<BookListItem []>();
  const [allBooksCount, setAllBooksCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const user = useSelector((state: UserBasic) => state.user.userData);
  const bgButton = useColorModeValue('#DDD', '#222');
  const bgHover = useColorModeValue('#CCC', '#333');
  const itemsPerPage = 24;
  const itemOffset = 0;
  const navigate = useNavigate();
  const {
    isOpen: isOpenRemoveFriend,
    onOpen: onOpenRemoveFriend,
    onClose: onCloseRemoveFriend,
  } = useDisclosure();

  const retrieveUserBooks = (friendUsername: string) => {
    axios({
      method: 'POST',
      url: '/api/userbook/friendbooksIndex',
      data: {
        offsetIndex: itemOffset,
        itemsPerPage,
        friendUsername,
      },
    })
      .then((res) => {
        // eslint-disable-next-line no-console
        // console.log(res.data.books);
        const { books, bookCount } = res.data;
        setMyBooks(books);
        setPageCount(Math.ceil(bookCount / itemsPerPage));
        setAllBooksCount(bookCount);
        setLoadingBooks(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };

  const getUser = (friendId: string) => {
    axios({
      method: 'POST',
      url: '/api/connections/checkFriendship',
      data: {
        userId: user.userId,
        friendId,
      },
    })
      .then((res) => {
        // console.log(res.data.books);
        const {
          foundUser,
          isFriends,
          userMessage,
          subMessage,
          status,
        } = res.data;
        setFriendProfile(foundUser);
        setFriendMsg(userMessage);
        setSubFriendMsg(subMessage);
        setConnectStatus(status);
        setFriendStatus(isFriends);
        if (isFriends) {
          retrieveUserBooks(foundUser.username);
        } else {
          setLoadingBooks(false);
        }
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  useEffect(() => {
    if (user.userId === userId) {
      navigate('/myAccount');
    } else if (user.image) {
      getUser(userId || '');
      setUserLoading(false);
      // geMyBooks();
    }
  }, [userId]);
  const createRequest = () => {
    axios({
      method: 'POST',
      url: '/api/connections/createFriendRequest',
      data: {
        userId: user.userId,
        recipientId: friendProfile?._id,
      },
    })
      .then(() => {
        // console.log(res.data.success);
        // console.log(targetUserId);
        setConnectStatus('requested');
        // const { resultList } = res.data;
        // setResults(resultList);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const deleteRequest = () => {
    axios({
      method: 'DELETE',
      url: '/api/connections/rejectFriendRequest',
      data: {
        userId: user.userId,
        requesterId: friendProfile?._id,
      },
    })
      .then(() => {
        setConnectStatus('none');
        onCloseRemoveFriend();
        setFriendMsg('This Account is Friends Only.');
        setSubFriendMsg('Add to see their books and notes.');
        setFriendStatus(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const acceptRequest = () => {
    axios({
      method: 'POST',
      url: '/api/connections/acceptFriendRequest',
      data: {
        userId: user.userId,
        requesterId: friendProfile?._id,
      },
    })
      .then(() => {
        setConnectStatus('friends');
        setFriendStatus(true);
        retrieveUserBooks(friendProfile?.username || '');
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
        // eslint-disable-next-line no-console
      });
  };
  const renderBookList = () => myBooks?.map((book) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      // width="180px"
      // height="285px"
      marginX="auto"
    >
      <Link
        href={`/userbooks/${friendProfile?.username}/${book.bookId}`}
      >
        {book?.coverImage
          ? (
            <Image
              cursor="pointer"
              borderRadius="5px"
              src={book.coverImage}
              // width="180px"
              // height="285px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            />
          ) : (
            <Flex
              backgroundColor="#1F1F1F"
              color="#FFF"
              // width="180px"
              // height="285px"
              borderRadius="5px"
              justifyContent="center"
              flexDir="column"
              textAlign="center"
              paddingX="5px"
              _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
            >
              <Text
                fontSize="16px"
                fontFamily="PTSerif"
              >
                {book.title}
              </Text>
              <Text
                marginTop="10px"
                fontSize="14px"
                fontFamily="PTSerif"
              >
                {book.author}
              </Text>
            </Flex>
          )}
      </Link>
    </Flex>
  ));
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allBooksCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axios({
      method: 'POST',
      url: '/api/userbook/friendbooksIndex',
      data: {
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { books } = res.data;
        if (books.length) {
          setMyBooks(books);
        }
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        document.getElementById('admin-book-list')?.scrollIntoView();
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  return (
    <Box>
      <Navbar />
      <Box as="main" className="friend-profile-container">
        {userLoading
          ? (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text>Updating</Text>
            </Flex>
          )
          : (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Box
                display={{ base: 'none', lg: 'block' }}
              >
                <Avatar
                  size="2xl"
                  src={friendProfile?.image}
                />
              </Box>
              <Box
                display={{ base: 'block', lg: 'none' }}
              >
                <Avatar
                  size="xl"
                  src={friendProfile?.image}
                />
              </Box>
              <Text
                fontFamily="PtSerif"
                fontWeight="300"
                marginY="5px"
                fontSize={{ base: '22px', lg: '28px' }}
              >
                {friendProfile?.username}
              </Text>
            </Flex>
          )}
        <Box>
          {
            isPaidUser === false
              ? (
                <Box
                  width={{ base: '95%', md: '50%' }}
                  marginX="auto"
                  marginTop="2em"
                >
                  <Alert status="info" borderRadius="5px" display="flex" justifyContent="center">
                    <AlertIcon />
                    <AlertDescription fontSize={{ base: '0.9em', md: '1.2em' }}>
                      Please subscribe
                      {' '}
                      <Link
                        href="/subscription"
                        textDecor="underline"
                      >
                        here
                      </Link>
                      {' '}
                      if you would like to access your saved books, highlights and notes
                    </AlertDescription>
                  </Alert>
                </Box>
              ) : <Box />
            }
        </Box>
        <Box>
          <Box>
            {
                connectStatus === 'none'
                  ? (
                    <Flex
                      justifyContent="center"
                    >
                      <CustomButton
                        label="Add +"
                        testId={`new_req_${friendProfile?.username}`}
                        buttonColor="night"
                        buttonProps={{
                          onClick: (() => {
                            createRequest();
                          }),
                          id: `new_req_${friendProfile?.username}`,
                        }}
                        spinnerId={undefined}
                        buttonType="button"
                        size="small"
                      />
                    </Flex>
                  ) : <Box />
              }
          </Box>
          <Box>
            {
                connectStatus === 'requested'
                  ? (
                    <Flex
                      justifyContent="center"
                    >
                      <CustomButton
                        label="Cancel Request"
                        testId={`unrequest_${friendProfile?.username}`}
                        buttonColor="night"
                        buttonProps={{
                          onClick: (() => {
                            deleteRequest();
                          }),
                          id: `unrequest_${friendProfile?.username}`,
                        }}
                        spinnerId={undefined}
                        buttonType="button"
                        size="small"
                      />
                    </Flex>
                  ) : <Box />
              }
          </Box>
          <Box>
            {
                connectStatus === 'pending'
                  ? (
                    <Flex
                      justifyContent="center"
                      gap="1em"
                    >
                      <CustomButton
                        label="Accept"
                        testId={`accept_${friendProfile?.username}`}
                        buttonColor="night"
                        buttonProps={{
                          onClick: (() => {
                            acceptRequest();
                          }),
                          id: `accept_${friendProfile?.username}`,
                        }}
                        spinnerId={undefined}
                        buttonType="button"
                        size="small"
                      />
                      <CustomButton
                        label="Ignore"
                        testId={`ignore_${friendProfile?.username}`}
                        buttonColor="night"
                        buttonProps={{
                          onClick: (() => {
                            deleteRequest();
                          }),
                          id: `ignore_${friendProfile?.username}`,
                        }}
                        spinnerId={undefined}
                        buttonType="button"
                        size="small"
                      />
                    </Flex>
                  ) : <Box />
              }
          </Box>
          <Box>
            {
                connectStatus === 'friends'
                  ? (
                    <Flex
                      justifyContent="center"
                      gap="1em"
                    >
                      <CustomButton
                        label="Unfriend"
                        testId={`Unfriend_${friendProfile?.username}`}
                        buttonColor="night"
                        buttonProps={{
                          onClick: (() => {
                            onOpenRemoveFriend();
                          }),
                          id: `Unfriend_${friendProfile?.username}`,
                        }}
                        spinnerId={undefined}
                        buttonType="button"
                        size="small"
                      />
                    </Flex>
                  ) : <Box />
              }
          </Box>
        </Box>
        <Box>
          { !loadingBooks
            ? (
              <Box>
                {
            friendStatus === false ? (
              <Box>
                <Flex
                  justifyContent="center"
                  flexDir="column"
                  alignItems="center"
                  height="300px"
                  marginTop="2em"
                  backgroundColor="#222"
                  width="75vw"
                >
                  <Text
                    fontFamily="PTSerif"
                    fontSize="20px"
                  >
                    {friendMsg}
                  </Text>
                  <Text
                    fontFamily="PTSerif"
                    fontSize="20px"
                  >
                    {subFriendMsg}
                  </Text>
                </Flex>
              </Box>
            )
              : (
                <Box>
                  {
                   myBooks?.length
                     ? (
                       <Box
                         paddingY="50px"
                       >
                         <Flex
                           width={{ base: '90%', lg: '90%', xl: '75%' }}
                           marginX="auto"
                           justifyContent="flex-start"
                         >
                           <Text
                             fontSize={{ base: '20px', md: '28px' }}
                             fontWeight="500"
                             letterSpacing="1px"
                             fontFamily="PTSerif"
                           >
                             Books
                           </Text>
                         </Flex>
                         <SimpleGrid
                           marginTop="20px"
                           gridTemplateColumns={{
                             base: '1fr 1fr 1fr 1fr',
                             lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                             xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                           }}
                           width={{ base: '90%', lg: '90%', xl: '75%' }}
                           gridGap={{ base: '10px', md: '20px' }}
                           marginX="auto"
                         >
                           {renderBookList()}
                         </SimpleGrid>
                         <CustomPagination
                           pageCount={pageCount}
                           startingPage={startingPage}
                           handlePageClick={handlePageClick}
                         />
                         <Box />
                       </Box>
                     ) : (
                       <Flex
                         justifyContent="center"
                         flexDir="column"
                         alignItems="center"
                         height="300px"
                         marginTop="2em"
                         backgroundColor={bgButton}
                       >
                         <Text
                           fontWeight="600"
                           fontFamily="PtSerif"
                           fontSize={{ base: '18px' }}
                         >
                           User does not have any saved books on their account.
                         </Text>
                       </Flex>
                     )
                   }
                </Box>
              )
            }
              </Box>
            ) : <LoadingSpinner />}
        </Box>
      </Box>
      <Modal isOpen={isOpenRemoveFriend} onClose={onCloseRemoveFriend}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="medium-text medium-text-white">Remove Friend</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to remove this user from your friend list?</Text>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              label="Cancel"
              testId={`remove_cancel_${friendProfile?.username}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseRemoveFriend();
                }),
                id: `remove_cancel_${friendProfile?.username}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Remove"
              testId={`remove_${friendProfile?.username}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  deleteRequest();
                }),
                id: `remove_${friendProfile?.username}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default FriendProfile;
