/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, createRef, Ref,
} from 'react';
import Dropzone, { DropzoneRef, useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios, { axiosPrivate } from '../../../api/axios';
import BookEdit from './BookEdit';
import BlankImage from '../../../assets/images/blank_image.png';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';
import SeriesEdit from './SeriesEdit';
import { BookSeries } from '../../../_interfaces/Books';

const baseDropzoneStyle = {
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  backgroundColor: '#333',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

function SeriesList() {
  const [mySeriesList, setMySeriesList] = useState<BookSeries []>();
  const dropzoneRef = createRef();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [allSeriesCount, setAllSeriesCount] = useState(0);
  const itemsPerPage = 24;
  const itemOffset = 0;
  // const [selectedBook, setSelectedBook] = useState();
  const [err, setErr] = useState();
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % allSeriesCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axiosPrivate({
      method: 'POST',
      url: '/api/bookseries/seriesByIndex',
      data: {
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { seriesList, seriesCount } = res.data;
        if (seriesList.length < 0) {
          setMySeriesList([]);
          return;
        }
        setMySeriesList(seriesList);
        // setBookResults(books);
        // setSearchDisplayed(newData.slice(itemOffset, endOffset));
        // setItemOffset(newOffset);
        // console.log(event.target.value);
        setStartingPage(event.selected);
        document.getElementById('admin-book-list')?.scrollIntoView();
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  const getSeries = () => {
    axios({
      method: 'POST',
      url: '/api/bookseries/seriesByIndex',
      withCredentials: true,
      data: {
        offSetIndex: itemOffset,
        itemsPerPage,
      },
    })
      .then((res) => {
        const { seriesList, seriesCount } = res.data;
        setMySeriesList(seriesList);
        setPageCount(Math.ceil(seriesCount / itemsPerPage));
        setAllSeriesCount(seriesCount);
      })
      .catch((resErr) => {
        setErr(resErr);
        // eslint-disable-next-line no-console
        navigate('/login');
      });
  };

  useEffect(() => {
    getSeries();
  }, []);

  const {
    isDragActive,
  } = useDropzone({
    accept: ['image/*', '.png', '.jpg', '.jpeg'],
    multiple: false,
  });
  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);
  const handleOnDrop = async (acceptedFile:any, series:BookSeries) => {
    // console.log(bookId);
    // console.log(acceptedFile[0]);
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      timeout: 5 * 60 * 60 * 1000,
    };
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    formData.append('seriesId', series._id);
    if (series.coverFileName) formData.append('coverFileName', series.coverFileName);
    const res = await axiosPrivate.post('/api/bookseries/addSeriesCover', formData, config);
    if (res.status === 200) {
      alert('image have been sucessfully uploaded!');
      window.location.reload();
    }
  };
  const renderBooksList = () => mySeriesList?.map((series) => (
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    >
      <Dropzone
        onDropAccepted={(acceptedFile) => handleOnDrop(acceptedFile, series)}
        ref={dropzoneRef as Ref<DropzoneRef>}
        accept="image/jpeg,image/png"
        onDropRejected={() => { alert('File Format cannot be accepted!'); }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ style: dropzoneStyle })}>
            <input {...getInputProps()} />
            {series.imageUrl
              ? (
                <Image
                  cursor="pointer"
                  borderRadius="5px"
                  src={series.imageUrl}
                  _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
                />
              ) : (
                <Flex
                  backgroundColor="#1F1F1F"
                  color="#FFF"
                    // border="1px solid #AAA"
                  borderRadius="5px"
                  justifyContent="center"
                  flexDir="column"
                  textAlign="center"
                  paddingX="5px"
                >
                  <Text
                    fontFamily="PtSerif"
                    fontSize="16px"
                  >
                    {series.name}
                  </Text>
                  <Text
                    fontFamily="PtSerif"
                    marginTop="10px"
                    fontSize="14px"
                  >
                    {series.author}
                  </Text>
                </Flex>
              )}
          </div>
        )}
      </Dropzone>
      <Box>
        <SeriesEdit
          seriesId={series._id}
          coverFileName={series.coverFileName}
          name={series.name}
          author={series.author}
          description={series.description}
          getSeries={getSeries}
        />
      </Box>
    </Flex>
  ));
  return (
    <Box
      paddingY="50px"
    >
      <Box>
        <Text
          fontFamily="PtSerif"
          fontSize={{ base: '24px', md: '28px' }}
          fontWeight="700"
          color="#FFF"
          letterSpacing="1px"
        >
          All Series
        </Text>
      </Box>
      <SimpleGrid
        id="admin-book-list"
        marginTop="20px"
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        gridGap={{ base: '10px', md: '20px' }}
        rowGap={{ base: '20px', md: '20px' }}
        marginX="auto"
      >
        {renderBooksList()}
      </SimpleGrid>
      <CustomPagination
        pageCount={pageCount}
        startingPage={startingPage}
        handlePageClick={handlePageClick}
      />
      <Box />
    </Box>
  );
}

export default SeriesList;
