/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Text,
  Box,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import NavbarLanding from '../../components/NavbarLanding/NabarLanding';
import CustomButton from '../../components/CustomButton/CustomButton';

function PasswordReset() {
  const { token } = useParams();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [hasValidToken, setHasValidToken] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!token) {
  //     return;
  //   }
  //   axios.get(`/api/password/isValidResetToken/${token}`)
  //     .then((res) => {
  //       setHasValidToken(res.data.success && res.data.isValidToken);
  //       setLoading(false);
  //     }).catch(() => {
  //       // TODO: Handle error
  //       setLoading(false);
  //     });
  // }, [token]);

  if (loading) {
    return (
      <Flex className="app">
        <Skeleton height="32px" width="100%" my="8px" />
        <Skeleton height="32px" width="100%" my="8px" />
        <Skeleton height="32px" width="100%" my="8px" />
      </Flex>
    );
  }

  if (!hasValidToken) {
    return (
      <Box
        backgroundColor="#EEE"
        color="#111"
      >
        <NavbarLanding />
        <Flex
          justifyContent="center"
          flexDir="column"
          alignItems="center"
          width="100%"
          minHeight="90vh"
        >
          <Text
            fontWeight="500"
            fontSize={{
              base: '24px', md: ' 28px',
            }}
            marginY="1em"
            textAlign="center"
          >
            This password reset link is not valid.
          </Text>
          <Text
            fontWeight="400"
            fontSize={{
              base: '15px', sm: '17px', md: ' 22px',
            }}
            marginY="1em"
            textAlign="center"
          >
            It may have already expired, but you can
            {' '}
            <a href="/forgot-password">request another password change</a>
            .
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      backgroundColor="#EEE"
      color="#111"
    >
      <NavbarLanding />
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required('Password is required')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-+(){}[\]|;:"'=,.<>~`_/\\])[A-Za-z\d@$!%*#?&-+(){}[\]|:;"'=,.<>~`_/\\]{8,}$/,
              'Must Contain 8 Characters, One Uppercase, One Number and One Special Character',
            ),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            try {
              const dataToSubmit = {
                token,
                password: values.password,
              };
              const res = await axios.post('/api/password/resetPassword', dataToSubmit);
              if (res.data.success) {
                // eslint-disable-next-line no-alert
                alert('You have successfully resetted your password. You will now be redirected to Login Page.');
                navigate('/login');
              }
            } catch (err) {
              setFormErrorMessage('Something went wrong');
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            }
            setSubmitting(false);
          }, 500);
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Flex
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              minHeight="90vh"
            >
              {
              loading
                ? (
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                    mt="128px"
                  >
                    <Spinner
                      thickness="4px"
                      emptyColor="gray.200"
                      color="#FF1A75"
                      size="xl"
                    />
                  </Flex>
                )
                : (
                  <Box as="form" minW={{ base: '90%', md: '400px' }}>
                    <Text
                      className="large-text large-text-black"
                      marginY="1em"
                    >
                      Reset Your Password
                    </Text>
                    <Field name="password">
                      {() => (
                        <FormControl
                          isRequired
                        >
                          <FormLabel
                            htmlFor="password"
                            ml="4px"
                            className="small-text small-text-black"
                          >
                            Password
                          </FormLabel>
                          <input
                            id="password"
                            className="input-custom"
                            aria-label="Enter a new password"
                            type="password"
                            placeholder="Enter new Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Box
                            color="red"
                            fontSize="14px"
                            minHeight="21px"
                          >
                            {touched.password && errors.password}
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="confirmPassword">
                      {() => (
                        <FormControl
                          isRequired
                        >
                          <FormLabel
                            htmlFor="confirmPassword"
                            className="small-text small-text-black"
                            marginTop="1rem"
                          >
                            Confirm Password
                          </FormLabel>
                          <input
                            id="confirmPassword"
                            className="input-custom"
                            aria-label="Enter Password Again"
                            type="password"
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Box
                            color="red"
                            fontSize="14px"
                            minHeight="21px"
                          >
                            {touched.confirmPassword && errors.confirmPassword}
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                    {formErrorMessage && (
                    <label>
                      <p
                        style={{
                          color: '#ff0000bf',
                          fontSize: '0.7rem',
                          border: '1px solid',
                          padding: '1rem',
                          borderRadius: '10px',
                        }}
                      >
                        {formErrorMessage}
                      </p>
                    </label>
                    )}
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      loadingText="Submitting..."
                      onClick={handleSubmit}
                      className="round-btn"
                    >
                      Update Password
                    </Button>
                  </Box>
                )
}
            </Flex>
          );
        }}
      </Formik>
    </Box>
  );
}

export default PasswordReset;
