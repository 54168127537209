/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  Avatar,
  useColorModeValue,
  SimpleGrid,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import axios from '../../../../api/axios';
import ReplyReply from './ReplyReply';
import CustomPagination from '../../../CustomPagination/CustomPagination';

const HeartButton = styled(SuitHeartFill)`
    background-color: inherit;
    width: 24px;
    margin-right: 10px;
    color: inherit;
    cursor: pointer;
    @media (max-width: 500px) {
      width: 16px;
    }
  `;
const SeeMoreButton = styled(EyeFill)`
    color: #BBB;
    cursor: pointer;
    @media (max-width: 500px) {
      width: 16px;
    }
  `;
const HeartActive = styled(SuitHeartFill)`
    color: #FF0000; 
    right: 12px;
    top: 12px;
    position: relative;
    width: 16px;
    @media (max-width: 500px) {
      width: 10px;
    }
  `;

export default function ReplyComments(props:{
  commentId:any,
  userId:any,
  username:any,
  userImage:any,
  bookId:any,
}) {
  const {
    commentId,
    userId,
    username,
    userImage,
    bookId,
  } = props;
  const [commentReplies, setCommentReplies] = useState<any>([]);
  const [replyCount, setReplyCount] = useState<any>(0);
  const [repliesExists, setRepliesExists] = useState<any>(false);
  const [replyBox, setReplyBox] = useState<any>(false);
  const [replyContent, setReplyContent] = useState<any>();
  const [viewReplies, setViewReplies] = useState<any>();
  const bgColor = useColorModeValue('#DDD', '#222');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const textManual = useColorModeValue('#000', '#FFF');
  const [heartActive, setHeartActive] = useState<any>(false);
  const [commentReactions, setCommentReactions] = useState<any>([]);
  const [viewReactions, setViewReactions] = useState<any>([]);
  const [hasReactions, setHasReactions] = useState<any>(false);
  const [reachedEnd, setReachedEnd] = useState<any>(true);
  const [numReplies, setNumReplies] = useState<any>();
  const [pageCount, setPageCount] = useState<any>();
  const [itemOffset, setItemOffset] = useState<any>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const forceUpdate = React.useReducer((bool) => !bool)[1];
  const itemsPerPage = 7;
  const startingPage = 0;
  const getCommentReactions = () => {
    axios({
      method: 'POST',
      url: '/api/comments/getCommentReactions',
      data: {
        myUserId: userId,
        commentId,
        itemsPerPage,
        offSetIndex: 0,
      },
    })
      .then((res) => {
        const {
          allReactions, myReaction, reactionCount,
        } = res.data;
        // console.log(myReaction);
        if (allReactions.length > 0) setHasReactions(true);
        setCommentReactions(allReactions);
        // console.log(commentReactions.length);
        setViewReactions(allReactions?.slice(0, 4));
        setNumReplies(reactionCount);
        setPageCount(Math.ceil(reactionCount / itemsPerPage));
        setHeartActive(myReaction);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const getCommentReplies = () => {
    axios({
      method: 'POST',
      url: '/api/comments/getRepliesOriginal',
      data: {
        commentId,
        bookId,
        itemsPerPage,
        offSetIndex: itemOffset,
      },
    })
      .then((res) => {
        const { replies, repliesCount, endReached } = res.data;
        const updatedReplies = commentReplies.concat(replies);
        setCommentReplies(updatedReplies);
        setReplyCount(repliesCount);
        if (repliesCount > 0) setRepliesExists(true);
        getCommentReactions();
        setReachedEnd(endReached);
        setItemOffset(itemOffset + replies.length);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const handleViewReplies = () => {
    const buttonElement = document?.getElementById(`${commentId}_view_button`);
    if (buttonElement?.innerHTML) {
      if (viewReplies) {
        buttonElement.innerHTML = 'View Replies &#9660;';
        setViewReplies(false);
      } else {
        buttonElement.innerHTML = 'Hide Replies &#9650;';
        setViewReplies(true);
      }
    }
  };
  const updateCommentReplies = (replyObject:any, replyId:any) => {
    setReplyContent('');
    const updatedReplies = commentReplies;
    updatedReplies.push(replyObject);
    // console.log(`updated: ${updatedReplies}`);
    setCommentReplies(updatedReplies);
    setReplyContent(undefined);
    const replyElement:any = document.getElementById(`${replyId}_reply`);
    if (replyElement?.value) {
      replyElement.value = '';
    }
    setRepliesExists(true);
    const viewButtonElement = document.getElementById(`${commentId}_view_button`);
    if (viewButtonElement?.innerHTML) viewButtonElement.innerHTML = 'Hide Replies &#9650;';
    setViewReplies(true);
    // forceUpdate();
  };
  const submitReply = () => {
    if (!replyContent) return;
    // setUpdatingComments(true);
    axios({
      method: 'POST',
      url: '/api/comments/saveCommentOriginal',
      data: {
        userId,
        content: replyContent,
        bookId,
        responseToId: commentId,
      },
    })
      .then((res) => {
        const { newDate } = res.data;
        // console.log(savedComment);
        // console.log(commentReplies);
        const updateObject = {
          _id: res.data.commentId,
          writer: {
            username,
            image: userImage,
          },
          bookId,
          content: replyContent,
          createdAt: newDate,
          responseTo: commentId,
        };
        updateCommentReplies(updateObject, commentId);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
      });
  };
  useEffect(() => {
    if (commentId) {
      getCommentReplies();
    }
  }, [commentId]);
  const getDate = (dateString:any) => {
    const newDate:Date = new Date(dateString);
    return newDate.toDateString();
  };
  const reactionHandler = async () => {
    try {
      setHasReactions(false);
      if (heartActive) {
        const response = await axios.delete(
          '/api/comments/deleteCommentReaction',
          {
            data: {
              myUserId: userId, commentId,
            },
          },
        );
        if (response.status === 200) {
          setHeartActive(false);
          const updatedReactions = commentReactions.filter(
            (reaction:any) => reaction.user.username !== username,
          );
          // console.log(updatedReactions);
          setCommentReactions(updatedReactions || []);
          setViewReactions(updatedReactions.length > 0 ? updatedReactions.slice(0, 4) : []);
          setHasReactions(true);
        }
      } else {
        const response = await axios.post(
          '/api/comments/addCommentReaction',
          { myUserId: userId, commentId },
        );
        if (response.status === 200) {
          setHeartActive(true);
          const newReaction = {
            user: {
              username,
              image: userImage,
            },
            comment: commentId,
          };
          const updatedReactions = commentReactions;
          updatedReactions.unshift(newReaction);
          // console.log(commentReactions);
          setCommentReactions(updatedReactions);
          // console.log(updatedReactions);
          setViewReactions(updatedReactions.slice(0, 4));
          setHasReactions(true);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % numReplies;
    axios({
      method: 'POST',
      url: '/api/comments/getCommentReactions',
      data: {
        myUserId: userId,
        commentId,
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        const { allReactions, myReaction, reactionCount } = res.data;
        // console.log(myReaction);
        if (allReactions.length > 0) setHasReactions(true);
        setCommentReactions(allReactions);
        setPageCount(Math.ceil(reactionCount / itemsPerPage));
        setHeartActive(myReaction);
        const scrollElement = document.getElementById('scroll-comments');
        if (scrollElement?.scrollTop) scrollElement.scrollTop = 0;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const renderReplies = () => commentReplies?.map((comment:any) => (
    <Box
      marginTop="20px"
    >
      <Box paddingTop="1px">
        <Flex
          gap="10px"
        >
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Avatar
              size="sm"
              src={comment.writer.image}
            />
          </Link>
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Text
              fontSize="1.6rem"
              fontWeight="500"
              color={toggleColor}
            >
              {comment.writer.username}
            </Text>
          </Link>
          <Text
            marginTop="3px"
            fontSize={{ base: '10px', md: '12px' }}
            fontWeight="200"
            color={toggleColor}
          >
            {getDate(comment.createdAt)}
          </Text>
        </Flex>
        <Box
          paddingLeft="44px"
        >
          <Text
            fontSize="1.6rem"
          >
            {parse(comment.content)}
          </Text>
        </Box>
        <Box
          paddingLeft="44px"
        >
          <ReplyReply
            headCommentId={commentId}
            commentId={comment._id}
            userId={userId}
            username={username}
            userImage={userImage}
            bookId={bookId}
            replyToUsername={comment.writer.username}
            replyToUserImage={comment.writer.image}
            replyToId={comment.writer._id}
            updateCommentReplies={updateCommentReplies}
          />
        </Box>
        {
          /*
          <Box
            paddingLeft="44px"
          >
            <ReplyComments
              commentId={comment._id}
              userId={userId}
              username={username}
              userImage={userImage}
              userbookId={userbookId}
            />
          </Box>
          */
          }
      </Box>
    </Box>
  ));
  return (
    <Box>
      <Box>
        <Flex>
          <Box marginY="8px" color={heartActive === false ? '#AAA' : '#FF0000'}>
            <HeartButton id="heart-button" onClick={() => reactionHandler()} />
          </Box>
          {hasReactions
            ? (
              <Flex>
                {viewReactions?.map((reaction:any) => (
                  <Link
                    href={`/profile/${reaction.user._id}`}
                    _focus={{ outline: 'none' }}
                  >
                    <Flex marginY="8px" height="24px">
                      <Box
                        as="img"
                        src={reaction.user.image}
                        width="24px"
                        height="24px"
                        objectFit="cover"
                        borderRadius="50%"
                        verticalAlign="middle"
                        cursor="pointer"
                        filter="brightness(0.9)"
                        _hover={{
                          filter: 'brightness(1.1)',
                        }}
                      />
                      <HeartActive />
                    </Flex>
                  </Link>
                ))}
              </Flex>
            ) : <Box />}
          <Flex marginY="8px" height="24px">
            <SeeMoreButton onClick={() => { onOpen(); }} />
          </Flex>
          <Box>
            { commentReactions.length > 0
              ? (
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                      <Box
                        marginY="3rem"
                        id="scroll-comments"
                        overflowY="scroll"
                        maxHeight="400px"
                      >
                        {commentReactions?.map((reaction:any, i:any) => (
                          <Flex marginY="1rem">
                            <Link
                              href={`/profile/${reaction.user._id}`}
                              _focus={{ outline: 'none' }}
                            >
                              <Box
                                as="img"
                                src={reaction.user.image}
                                width={{ base: '20px', md: '32px' }}
                                height={{ base: '20px', md: '32px' }}
                                objectFit="cover"
                                borderRadius="50%"
                                verticalAlign="middle"
                                cursor="pointer"
                                filter="brightness(0.9)"
                                _hover={{
                                  filter: 'brightness(1.1)',
                                }}
                              />
                            </Link>
                            <HeartActive />
                            <Link
                              href={`/profile/${reaction.user._id}`}
                              _focus={{ outline: 'none' }}
                            >
                              <Text
                                marginTop="5px"
                                color={textManual}
                                fontWeight="500"
                              >
                                {reaction.user.username}

                              </Text>
                            </Link>
                          </Flex>
                        ))}
                        <CustomPagination
                          pageCount={pageCount}
                          startingPage={startingPage}
                          handlePageClick={handlePageClick}
                        />
                      </Box>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              ) : <Box />}
          </Box>
        </Flex>
      </Box>
      <Flex
        gap="10px"
        marginY="10px"
      >
        <Button
          fontSize={{ base: '10px', md: '12px' }}
          paddingY="5px"
          _focus={{ outline: 'none' }}
          onClick={() => { setReplyBox(true); }}
        >
          Reply
        </Button>
      </Flex>
      <Box>
        {replyBox
          ? (
            <Box>
              <Textarea
                id={`${commentId}_reply`}
                borderRadius="5px"
                width={{ base: '100%' }}
                marginX="auto"
                _focus={{ outlineColor: '#DDD' }}
                onChange={(event) => {
                  setReplyContent(event.target.value);
                }}
                  // value={Comment}
                placeholder="write reply"
                fontSize="1.6rem"
              />
              <Flex
                justifyContent="flex-end"
                gap="10px"
                marginTop="10px"
              >
                <Button
                  onClick={() => {
                    setReplyContent('');
                    setReplyBox(false);
                  }}
                  fontSize="1.6rem"
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitReply}
                  fontSize="1.6rem"
                >
                  Reply
                </Button>
              </Flex>
            </Box>
          ) : <Box />}
      </Box>
      <Box>
        {repliesExists ? (
          <Box>
            <Text
              id={`${commentId}_view_button`}
              as="span"
              color="blue.500"
              cursor="pointer"
              fontSize="1.6rem"
              _hover={{ color: 'blue.400' }}
              onClick={handleViewReplies}
            >
              View Replies &#9660;
            </Text>
          </Box>
        ) : <Box />}
      </Box>
      <Box>
        {viewReplies
          ? (
            <Box>
              {renderReplies()}
              <Box>
                {reachedEnd === false ? (
                  <Box
                    paddingLeft="44px"
                  >
                    <Text
                      as="span"
                      color="blue.500"
                      cursor="pointer"
                      fontSize="1.6rem"
                      _hover={{ color: 'blue.400' }}
                      onClick={getCommentReplies}
                    >
                      View More Replies &#9660;
                    </Text>
                  </Box>
                ) : <Box />}
              </Box>
            </Box>
          ) : <Box />}
      </Box>
    </Box>
  );
}
