/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  Avatar,
  useColorModeValue,
  SimpleGrid,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import axios from '../../../../api/axios';
import CustomPagination from '../../../CustomPagination/CustomPagination';

const HeartButton = styled(SuitHeartFill)`
    background-color: inherit;
    width: 24px;
    margin-right: 10px;
    color: inherit;
    cursor: pointer;
    @media (max-width: 500px) {
      width: 16px;
    }
  `;
const SeeMoreButton = styled(EyeFill)`
    color: #BBB;
    cursor: pointer;
    @media (max-width: 500px) {
      width: 16px;
    }
  `;
const HeartActive = styled(SuitHeartFill)`
    color: #FF0000; 
    right: 12px;
    top: 12px;
    position: relative;
    width: 16px;
    @media (max-width: 500px) {
      width: 10px;
    }
  `;
export default function ReplyReply(props: {
  headCommentId:any,
  commentId:any,
  userId:any,
  username:any,
  userImage:any,
  bookId:any,
  replyToUsername:any,
  replyToId:any,
  replyToUserImage:any,
  updateCommentReplies:any,
}) {
  const {
    headCommentId,
    commentId,
    userId,
    username,
    userImage,
    bookId,
    replyToUsername,
    replyToId,
    replyToUserImage,
    updateCommentReplies,
  } = props;

  const [commentReplies, setCommentReplies] = useState<any>([]);
  const [replyCount, setReplyCount] = useState<any>(0);
  const [repliesExists, setRepliesExists] = useState<any>(false);
  const [replyBox, setReplyBox] = useState<any>(false);
  const [replyContent, setReplyContent] = useState<any>();
  const [viewReplies, setViewReplies] = useState<any>();
  const bgColor = useColorModeValue('#DDD', '#222');
  const toggleColor = useColorModeValue('#000', '#FFF');
  const [heartActive, setHeartActive] = useState<any>(false);
  const [testText, setTestText] = useState<any>();
  const [commentReactions, setCommentReactions] = useState<any>([]);
  const [viewReactions, setViewReactions] = useState<any>([]);
  const [hasReactions, setHasReactions] = useState<any>(false);
  const [loadingReactions, setLoadingReactions] = useState<any>(false);
  const [windowOpen, setWindowOpen] = useState<any>(false);
  const [pageCount, setPageCount] = useState<any>(0);
  const [numReplies, setNumReplies] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lastReactionRef = useRef(null);
  const itemsPerPage = 24;
  const startingPage = 0;

  const getCommentReactions = () => {
    setLoadingReactions(false);
    axios({
      method: 'POST',
      url: '/api/comments/getCommentReactions',
      data: {
        myUserId: userId,
        commentId,
        itemsPerPage,
        offSetIndex: 0,
      },
    })
      .then((res) => {
        const { allReactions, myReaction, reactionCount } = res.data;
        // console.log(myReaction);
        if (allReactions.length > 0) setHasReactions(true);
        setCommentReactions(allReactions);
        // console.log(commentReactions.length);
        setViewReactions(allReactions?.slice(0, 4));
        setNumReplies(reactionCount);
        setPageCount(Math.ceil(reactionCount / itemsPerPage));
        setHeartActive(myReaction);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % numReplies;
    axios({
      method: 'POST',
      url: '/api/comments/getCommentReactions',
      data: {
        myUserId: userId,
        commentId,
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        const { allReactions, myReaction, reactionCount } = res.data;
        // console.log(myReaction);
        if (allReactions.length > 0) setHasReactions(true);
        setCommentReactions(allReactions);
        setPageCount(Math.ceil(reactionCount / itemsPerPage));
        setHeartActive(myReaction);
        const scrollElement = document.getElementById('scroll-replies');
        if (scrollElement?.scrollTop) scrollElement.scrollTop = 0;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  /*
    const getCommentReplies = () => {
      axios({
        method: 'POST',
        url: '/api/comments/getRepliesOriginal',
        data: {
          commentId,
          bookId,
          itemsPerPage,
          offSetIndex: itemOffset,
        },
      })
        .then((res) => {
          const { replies, repliesCount } = res.data;
          setCommentReplies(replies);
          setReplyCount(repliesCount);
          if (repliesCount > 0) setRepliesExists(true);
          getCommentReactions();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    */
  const handleViewReplies = () => {
    const buttonElement = document?.getElementById(`${commentId}_view_button`);
    if (buttonElement?.innerHTML) {
      if (viewReplies) {
        buttonElement.innerHTML = 'View Replies &#9660;';
        setViewReplies(false);
      } else {
        buttonElement.innerHTML = 'Hide Replies &#9650;';
        setViewReplies(true);
      }
    }
  };
  const submitReply = () => {
    if (!replyContent) return;
    const newReplyContent = `
      <a href="/profile/${replyToId}" style="color:#0d6efd; cursor:pointer;" >
      @${replyToUsername}
      </a>
      &nbsp;
      ${replyContent}`;
      // setUpdatingComments(true);
    // console.log(parse(newReplyContent));
    // setTestText(newReplyContent);
    axios({
      method: 'POST',
      url: '/api/comments/saveCommentOriginal',
      data: {
        userId,
        content: newReplyContent,
        bookId,
        responseToId: headCommentId,
      },
    })
      .then((res) => {
        const { newDate } = res.data;
        // console.log(savedComment);
        const updateObject = {
          _id: res.data.commentId,
          writer: {
            username,
            image: userImage,
          },
          bookId,
          content: newReplyContent,
          createdAt: newDate,
          responseTo: headCommentId,
        };
        updateCommentReplies(updateObject, commentId);
        setReplyContent(undefined);
        // setUpdatingComments(false);
      })
      .catch((resErr) => {
        // eslint-disable-next-line no-console
        console.log(resErr);
      });
  };

  useEffect(() => {
    /*
      if (commentId) {
        getCommentReplies();
      }
      */
    if (commentId) {
      getCommentReactions();
    }
  }, [commentId]);

  const getDate = (dateString:any) => {
    const newDate = new Date(dateString);
    return newDate.toDateString();
  };
  const reactionHandler = async () => {
    try {
      setHasReactions(false);
      if (heartActive) {
        const response = await axios.delete(
          '/api/comments/deleteCommentReaction',
          {
            data: {
              myUserId: userId, commentId,
            },
          },
        );
        if (response.status === 200) {
          setHeartActive(false);
          const updatedReactions = commentReactions.filter(
            (reaction:any) => reaction?.user?.username !== username,
          );
          // console.log(updatedReactions);
          setCommentReactions(updatedReactions || []);
          setViewReactions(updatedReactions.length > 0 ? updatedReactions.slice(0, 4) : []);
          setHasReactions(true);
        }
      } else {
        const response = await axios.post(
          '/api/comments/addCommentReaction',
          { myUserId: userId, commentId },
        );
        if (response.status === 200) {
          setHeartActive(true);
          const newReaction = {
            user: {
              username,
              image: userImage,
            },
            comment: commentId,
          };
          const updatedReactions = commentReactions;
          updatedReactions.unshift(newReaction);
          // console.log(commentReactions);
          setCommentReactions(updatedReactions);
          // console.log(updatedReactions);
          setViewReactions(updatedReactions.slice(0, 4));
          setHasReactions(true);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const renderReplies = () => commentReplies?.map((comment:any) => (
    <Box
      marginTop="20px"
    >
      <Box paddingTop="1px">
        <Flex
          gap="10px"
        >
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Avatar
              size="sm"
              src={comment.writer.image}
              filter="brightness(0.9)"
              _hover={{
                filter: 'brightness(1.1)',
              }}
              cursor="pointer"
            />
          </Link>
          <Link
            href={`/profile/${comment.writer._id}`}
            _focus={{ outline: 'none' }}
          >
            <Text
              fontSize={{ base: '12px', md: '16px' }}
              fontWeight="500"
              color={toggleColor}
            >
              {comment.writer.username}
            </Text>
          </Link>
          <Text
            marginTop="3px"
            fontSize={{ base: '10px', md: '12px' }}
            fontWeight="200"
            color={toggleColor}
          >
            {getDate(comment.createdAt)}
          </Text>
        </Flex>
        <Box
          paddingLeft="44px"
        >
          <Text
            fontSize={{ base: '10px', md: '14px' }}
          >
            {comment.content}
          </Text>
        </Box>
        <Box
          paddingLeft="44px"
        >
          <Box marginY="8px">
            <HeartButton id="heart-button" onClick={() => reactionHandler()} />
          </Box>
        </Box>
      </Box>
    </Box>
  ));
  /*
  useEffect(() => {
    if (document.getElementById('scroll-replies')) {
      document.getElementById('scroll-replies').onscroll = debounce((event) => {
        console.log(document.getElementById('scroll-replies').height());
        if (document.getElementById('scroll-replies').height +
        document.getElementById('scroll-replies').scrollTop
        === document.getElementById('scroll-replies').height - 100) {
        // Do awesome stuff like loading more content!
        // Do load more content here!
          getCommentReactions();
        }
      }, 100);
    }
  });
*/

  const handleReplyBox = () => {
    setReplyBox(true);
    const replyElement:any = document.getElementById(`${commentId}_reply`);
    if (replyElement?.value) replyElement.value = `@${replyToUsername} `;
  };
  return (
    <Box>
      <Box>
        <Flex>
          <Box marginY="8px" color={heartActive === false ? '#AAA' : '#FF0000'}>
            <HeartButton id="heart-button" onClick={() => reactionHandler()} />
          </Box>
          {hasReactions
            ? (
              <Flex>
                {viewReactions?.map((reaction:any) => (
                  <Flex marginY="8px" height="24px">
                    <Box
                      as="img"
                      src={reaction.user.image}
                      width="24px"
                      height="24px"
                      objectFit="cover"
                      borderRadius="50%"
                      verticalAlign="middle"
                    />
                    <HeartActive />
                  </Flex>
                ))}
              </Flex>
            ) : <Box />}
          <Flex marginY="8px" height="24px">
            <SeeMoreButton onClick={() => { onOpen(); setWindowOpen(true); }} />
          </Flex>
          <Box>
            { commentReactions.length > 0
              ? (
                <Modal
                  isOpen={isOpen}
                  onClose={() => { onClose(); setWindowOpen(false); }}
                >
                  <ModalOverlay />
                  <ModalContent
                    maxH="500px"
                  >
                    <ModalCloseButton />
                    <ModalBody>
                      <Box
                        id="scroll-replies"
                        marginY="3rem"
                        overflowY="scroll"
                        maxHeight="400px"
                      >
                        {commentReactions?.map((reaction:any, i:any) => (
                          // eslint-disable-next-line no-lone-blocks
                          <Box>
                            {
                            commentReactions.length === i + 1
                              ? (
                                <Flex
                                  marginY="1rem"
                                  ref={lastReactionRef}
                                >
                                  <Box
                                    as="img"
                                    src={reaction.user.image}
                                    width={{ base: '20px', md: '32px' }}
                                    height={{ base: '20px', md: '32px' }}
                                    objectFit="cover"
                                    borderRadius="50%"
                                    verticalAlign="middle"
                                    cursor="pointer"
                                    filter="brightness(0.9)"
                                    _hover={{
                                      filter: 'brightness(1.1)',
                                    }}
                                  />
                                  <HeartActive />
                                  <Text marginTop="5px" fontWeight="500">{reaction.user.username}</Text>
                                </Flex>
                              ) : (
                                <Flex marginY="1rem">
                                  <Box
                                    as="img"
                                    src={reaction.user.image}
                                    width={{ base: '20px', md: '32px' }}
                                    height={{ base: '20px', md: '32px' }}
                                    objectFit="cover"
                                    borderRadius="50%"
                                    verticalAlign="middle"
                                    cursor="pointer"
                                    filter="brightness(0.9)"
                                    _hover={{
                                      filter: 'brightness(1.1)',
                                    }}
                                  />
                                  <HeartActive />
                                  <Text marginTop="5px" fontWeight="500">{reaction.user.username}</Text>
                                </Flex>
                              )
                                  }
                          </Box>
                        ))}
                        <CustomPagination
                          pageCount={pageCount}
                          startingPage={startingPage}
                          handlePageClick={handlePageClick}
                        />
                      </Box>

                    </ModalBody>
                  </ModalContent>
                </Modal>
              ) : <Box />}
          </Box>
        </Flex>
      </Box>
      <Flex
        gap="10px"
        marginY="10px"
      >
        <Button
          fontSize={{ base: '10px', md: '12px' }}
          paddingY="5px"
          _focus={{ outline: 'none' }}
          onClick={() => { handleReplyBox(); }}
        >
          Reply
        </Button>
      </Flex>
      <Box>
        {replyBox
          ? (
            <Box>
              <Text
                marginY="10px"
              >
                @
                {replyToUsername}
                :
              </Text>
              <Textarea
                id={`${commentId}_reply`}
                borderRadius="5px"
                width={{ base: '100%' }}
                marginX="auto"
                _focus={{ outlineColor: '#DDD' }}
                onChange={(event) => {
                  setReplyContent(event.target.value);
                }}
                    // value={Comment}
                placeholder="write reply"
                fontSize="1.6rem"
              />
              <Flex
                justifyContent="flex-end"
                gap="10px"
                marginTop="10px"
              >
                <Button
                  onClick={() => {
                    setReplyContent('');
                    setReplyBox(false);
                  }}
                  fontSize={{ base: '12px', md: '16px' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitReply}
                  fontSize={{ base: '12px', md: '16px' }}
                >
                  Reply
                </Button>
              </Flex>
            </Box>
          ) : <Box />}
      </Box>
      <Box>
        {repliesExists ? (
          <Box>
            <Text
              id={`${commentId}_view_button`}
              as="span"
              color="blue.500"
              cursor="pointer"
              fontSize={{ base: '10px', md: '14px' }}
              _hover={{ color: 'blue.400' }}
              onClick={handleViewReplies}
            >
              View Replies &#9660;
            </Text>
          </Box>
        ) : <Box />}
      </Box>
      <Box>
        {viewReplies
          ? (
            <Box>
              {renderReplies()}
            </Box>
          ) : <Box />}
      </Box>
      <Box>
        {
              testText ? (
                <Text>
                  {parse(testText)}
                </Text>
              ) : <Box />
          }
      </Box>
    </Box>
  );
}
