/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Spinner,
  Button,
  Flex,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  useColorModeValue,
  IconButton,
} from '@chakra-ui/react';
import {
  AddIcon,
} from '@chakra-ui/icons';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import PropTypes, { string } from 'prop-types';
import axios from 'axios';
import UploadImg from '../../../assets/images/upload.png';
import { axiosPrivate } from '../../../api/axios';

const baseDropzoneStyle = {
  height: '400px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#888',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#000',
};

const ReleaseList = [
  { value: 'COMING_SOON', label: 'COMING_SOON' },
  { value: 'LIVE', label: 'LIVE' },
];
const CategoryList = [
  { value: 'None', label: 'None' },
  { value: 'Theology', label: 'Theology' },
  { value: 'Biography', label: 'Biography' },
  { value: 'Sermons', label: 'Sermons' },
  { value: 'History', label: 'History' },
];
export default function BookEdit(props: {
  bookId: string,
  coverFileName: string,
  contentId: string,
  title: string,
  author: string,
  description: string,
  releaseState: string,
  tags: string [],
  category: string,
  getBooks: () => void,
}) {
  const {
    bookId,
    coverFileName,
    contentId,
    title,
    author,
    description,
    releaseState,
    tags,
    category,
    getBooks,
  } = props;
  const [contentUpdating, setContentUpdating] = useState(false);
  const [currentTagName, setCurrentTagName] = useState<string>();
  const [tagProcessing, setTagProcessing] = useState(false);
  const [tagList, setTagList] = useState<string []>([]);
  const {
    isOpen: isOpenDeleteBook,
    onOpen: onOpenDeleteBook,
    onClose: onCloseDeleteBook,
  } = useDisclosure();
  const {
    isOpen: isOpenTags,
    onOpen: onOpenTags,
    onClose: onCloseTags,
  } = useDisclosure();
  const {
    isOpen: isOpenDeleteCover,
    onOpen: onOpenDeleteCover,
    onClose: onCloseDeleteCover,
  } = useDisclosure();
  const {
    isOpen: isOpenEditBook,
    onOpen: onOpenEditBook,
    onClose: onCloseEditBook,
  } = useDisclosure();
  const {
    isOpen: isOpenFeatured,
    onOpen: onOpenFeatured,
    onClose: onCloseFeatured,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateContent,
    onOpen: onOpenUpdateContent,
    onClose: onCloseUpdateContent,
  } = useDisclosure();

  useEffect(() => {
    if (tags.length) {
      setTagList([...tags]);
    }
  }, []);
  const deleteCoverImage = () => {
    axiosPrivate({
      method: 'DELETE',
      url: '/api/book/deleteBookCover',
      data: {
        coverFileName,
        bookId,
      },
    })
      .then((res) => {
        if (res.status === 200) window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const deleteBook = () => {
    axiosPrivate({
      method: 'DELETE',
      url: '/api/book/deleteBookById',
      data: {
        bookId,
        contentId,
        coverFileName,
      },
    })
      .then((res) => {
        if (res.status === 200) window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const openBook = () => {
    window.open(`/book/${bookId}`, '_blank');
  };
  const openOriginal = () => {
    window.open(`/originalbook/${bookId}`, '_blank');
  };
  const setFeatured = () => {
    axiosPrivate({
      method: 'POST',
      url: '/api/featuredbook/createbook',
      data: {
        bookId,
      },
    })
      .then((res) => {
        if (res.status === 200) window.location.reload();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          title,
          author,
          description,
          releaseState,
          category,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Must include a title'),
          author: Yup.string()
            .required('Must include an author'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          axiosPrivate({
            method: 'PUT',
            url: '/api/book/bookMetaData',
            data: {
              bookId,
              title: values.title,
              author: values.author,
              description: values.description,
              releaseState: values.releaseState,
              category: values.category,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                alert('Updated Book metada successfully!');
                getBooks();
                onCloseEditBook();
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props:any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >

              <Field name="title">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="title"
                      ml="4px"
                      fontWeight="450"
                    >
                      Title
                    </FormLabel>

                    <Input
                      id="title"
                      placeholder="Add a title to your file"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="author">
                {() => (
                  <FormControl marginY="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="author"
                      ml="4px"
                      fontWeight="450"
                    >
                      Author
                    </FormLabel>

                    <Input
                      id="author"
                      placeholder="Add a author for your file"
                      value={values.author}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="description"
                      ml="4px"
                      fontWeight="450"
                    >
                      Description (Optional)
                    </FormLabel>

                    <Textarea
                      fontFamily="PtSerif"
                      id="description"
                      placeholder="Add a book description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="releaseState">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="releaseState"
                      ml="4px"
                      fontWeight="450"
                    >
                      Release State
                    </FormLabel>
                    <Select
                      id="releaseState"
                      onChange={handleChange}
                      placeholder={releaseState}
                    >
                      {ReleaseList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="category">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel
                      fontFamily="PtSerif"
                      fontSize="18px"
                      htmlFor="category"
                      ml="4px"
                      fontWeight="450"
                    >
                      Category
                    </FormLabel>
                    <Select
                      id="category"
                      onChange={handleChange}
                      placeholder={category}
                    >
                      {CategoryList.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  fontFamily="PtSerif"
                  fontSize="18px"
                  type="button"
                  onClick={onCloseEditBook}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="10px"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  fontFamily="PtSerif"
                  fontSize="18px"
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="10px"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  const handleTagName = (event:any) => {
    setCurrentTagName(event.target.value);
  };
  const onDropAccepted = async (files:any) => {
    setContentUpdating(true);
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      timeout: 5 * 60 * 60 * 1000,
    };
    // console.log(files[0]);
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('bookId', bookId);
    formData.append('contentId', contentId);
    const res = await axiosPrivate.put('/api/book/editbookContent', formData, config);
    if (res.status === 200) {
      alert('File have been sucessfully uploaded!');
      window.location.reload();
    }
  };
  const updateTagServer = (newTags: string []) => {
    axiosPrivate({
      method: 'PUT',
      url: '/api/book/updateTagList',
      data: {
        bookId,
        tagList: newTags,
      },
    })
      .then((res) => {
        setTagList(res.data.tagList);
        setTagProcessing(false);
        const tagAdder:HTMLInputElement = document.getElementById('tag-adder') as HTMLInputElement;
        if (tagAdder) tagAdder.value = '';
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        return err;
      });
  };
  const addTag = () => {
    // console.log(newProfile);
    setTagProcessing(true);
    // setTagList(newTagList);
    // setProcessComplete(true);
    const newTagsList = tagList;
    if (currentTagName) {
      newTagsList.push(currentTagName);
      // console.log(newTagsList);
      updateTagServer([...newTagsList]);
    }
    // const allRoomUsers = [profile._id];
    // newRecipientList.forEach((recProfile) => {
    //   allRoomUsers.push(recProfile._id);
    // });
  };
  const removeTag = (tagName: string) => {
    // console.log(tagList);
    setTagProcessing(true);
    // console.log(tagName);
    const newTagsList: string[] = [];
    tagList.forEach((tag) => {
      // console.log(tag);
      if (tag !== tagName) {
        newTagsList.push(tag);
      }
    });
    // console.log(newTagsList);
    updateTagServer(newTagsList);
    // setTagList(() => newTagsList);
    // setTagProcessing(false);
    // setProcessComplete(true);
    // console.log(newRecipientList);
    // findConversation(newRecipientList);
  };
  const onDropRejected = () => alert('Your file format cannot be accepted');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['.txt'], // TODO: Accept other video types
    multiple: true,
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
  });
  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);
  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width={{
          base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1023px',
        }}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image marginX="auto" width="32px" objectFit="contain" src={UploadImg} />
        <Text
          fontFamily="PtSerif"
          fontSize={{ base: '1.2em', md: '1.5em' }}
          fontWeight="300"
          textAlign="center"
        >
          Drag and drop your book here to update
        </Text>
        <Text
          fontFamily="PtSerif"
        >
          or click here

        </Text>
      </Flex>
    </Flex>
  );
  return (
    <Box>
      <Menu>
        <MenuButton
          className="link-btn link-btn-night link-btn-small"
          marginTop="1rem"
          marginBottom="2rem"
        >
          Settings
        </MenuButton>
        <MenuList
          zIndex="9999"
          bg="#222"
          border="1px solid #888"
        >
          {
            releaseState === 'LIVE'
              ? (
                <MenuItem
                  onClick={openBook}
                  className="link-btn link-btn-night link-btn-small"
                >
                  Open Copy
                </MenuItem>
              ) : <Box />
          }
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={openOriginal}
          >
            Open Original
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenEditBook}
          >
            Edit Book
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenTags}
          >
            Add Tags
          </MenuItem>
          {coverFileName
            ? (
              <MenuItem
                className="link-btn link-btn-night link-btn-small"
                onClick={onOpenDeleteCover}
              >
                Remove Cover
              </MenuItem>
            ) : <Box />}
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenDeleteBook}
          >
            Delete Book
          </MenuItem>
          <MenuItem
            className="link-btn link-btn-night link-btn-small"
            onClick={onOpenFeatured}
          >
            Feature Book
          </MenuItem>
          {
            releaseState === 'COMING_SOON'
              ? (
                <MenuItem
                  className="link-btn link-btn-night link-btn-small"
                  onClick={onOpenUpdateContent}
                >
                  Update Content
                </MenuItem>
              ) : <Box />
          }
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenDeleteCover} onClose={onCloseDeleteCover}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="medium-text medium-text-white">Delete Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to Delete this book cover?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              mr={3}
              onClick={onCloseDeleteCover}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              onClick={deleteCoverImage}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenTags} onClose={onCloseTags}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tags to Book</ModalHeader>
          <ModalCloseButton />
          <Flex
            marginY="0.5em"
            paddingX="1em"
          >
            <Input
              id="tag-adder"
              onChange={handleTagName}
              placeholder="Add tag here"
            />
            <IconButton
              aria-label="add tag button"
              type="submit"
              icon={<AddIcon />}
              onClick={() => { addTag(); }}
              ml="12px"
              color="white"
              borderRadius="24px"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            />
          </Flex>
          <Box
            marginY="0.5rem"
            maxHeight="100px"
            overflowY="scroll"
            paddingX="1em"
          >
            {tagList.length > 0
              ? (
                <Flex
                  flexWrap="wrap"
                >
                  { tagList?.map((tag, index) => (
                    <Box
                      margin="5px"
                      id={`tag_${tag}`}
                      backgroundColor="#DDD"
                    >
                      <Flex>
                        <Box>
                          <Flex
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            marginLeft="0.5rem"
                          >
                            <Text
                              fontFamily="PtSerif"
                              color="#000"
                              fontWeight="600"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {tag}
                            </Text>
                          </Flex>
                        </Box>
                        <Box>
                          <Flex
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            marginX="0.5rem"
                          >
                            <Text
                              fontFamily="PtSerif"
                              as="span"
                              fontSize="1.2rem"
                              onClick={() => { removeTag(tag); }}
                              cursor="pointer"
                            >
                              &times;
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              ) : <Box />}
          </Box>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteBook} onClose={onCloseDeleteBook}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className="medium-text medium-text-white"
          >
            Delete Image
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              className="small-text small-text-white"
            >
              Are you sure you want to Delete this book?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              mr={3}
              onClick={onCloseDeleteBook}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              onClick={deleteBook}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenFeatured} onClose={onCloseFeatured}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="medium-text medium-text-white">Feature this Book</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="small-text small-text-white">Are you sure you want to set featured as this book?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              mr={3}
              onClick={onCloseFeatured}
            >
              Cancel
            </Button>
            <Button
              fontFamily="PtSerif"
              fontSize="18px"
              colorScheme="blue"
              onClick={setFeatured}
            >
              Set as Featured
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenEditBook} onClose={onCloseEditBook}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Book</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenUpdateContent} onClose={onCloseUpdateContent}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Book Content</ModalHeader>
          {contentUpdating === false ? <ModalCloseButton /> : <Box />}
          {
            contentUpdating === false ? (
              <ModalBody>
                {renderDropzone()}
              </ModalBody>
            ) : (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="400px"
              >
                <Spinner
                  thickness="4px"
                  emptyColor="gray.200"
                  color="#FF1A75"
                  size="xl"
                  mb="8px"
                />
                <Text
                  fontFamily="PtSerif"
                >
                  Loading Book...

                </Text>
              </Flex>
            )
          }
        </ModalContent>
      </Modal>
    </Box>
  );
}
