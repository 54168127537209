import React, {
  useEffect, useState,
} from 'react';
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import pageImg from '../../../../assets/images/pagination_theology.png';
import { BookContent } from '../../../../_interfaces/Books';
import updateBookPage from '../../../../_helper/BookHelper';

interface BookState {
  book: {
    currentBook: {
      desktopPagination: number[],
      currentPage: number,
    }
  }
}
interface Content {
  book: {
    bookContent: BookContent[],
  }
}

export default function DesktopPagination() {
  const {
    desktopPagination,
    currentPage,
  } = useSelector((res: BookState) => res.book.currentBook);

  const { bookContent } = useSelector((res: Content) => res.book);
  const [desktopPage, setDesktopPage] = useState<number>(1);
  const toggleBorder = useColorModeValue('#222', '#FFF');
  const bg = useColorModeValue('#FFF', '#222');
  useEffect(() => {
    if (currentPage) {
      if (currentPage % 2 === 0) {
        setDesktopPage(currentPage - 1);
      } else {
        setDesktopPage(currentPage);
      }
    }
  }, [currentPage]);

  const goToPage = (newPageNo:any) => {
    // console.log(newPageNo);
    let getChapter = '';
    if (newPageNo === 1) {
      getChapter = 'CHAPTER: INTRO';
    } else {
      bookContent.forEach((paragraph:BookContent) => {
        if (paragraph.page === newPageNo) {
          getChapter = paragraph.chapter;
        }
      });
    }
    updateBookPage(newPageNo, getChapter);
    document?.getElementById('book-header')?.scrollIntoView();
  };

  useEffect(() => {
    function handlekeydownEvent(event:any) {
      if (event.key === 'ArrowLeft') {
        // left arrow
        // console.log('left');
        if (desktopPage === 1) return;
        goToPage(desktopPage - 2);
        // setDesktopPage(currentPageNumber - 2);
      } else if (event.key === 'ArrowRight') {
        // right arrow
        // console.log('right');
        if (desktopPage >= desktopPagination[desktopPagination.length - 1]) return;
        goToPage(desktopPage + 2);
        // setDesktopPage(currentPageNumber + 2);
      }
    }
    document.addEventListener('keyup', handlekeydownEvent);
    return () => {
      document.removeEventListener('keyup', handlekeydownEvent);
    };
  });
  const checkPageRange = (pageNo:any) => {
    if (pageNo + 2 === desktopPage || pageNo + 4 === desktopPage
          || pageNo - 2 === desktopPage || pageNo - 4 === desktopPage
          || pageNo === desktopPage
    ) {
      return true;
    }
    return false;
  };
  const renderFirstPagePagination = () => desktopPagination?.map((pageNo:any) => (
    <Box
      height="inherit"
    >
      {pageNo < 11
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {desktopPage === pageNo
              ? (
                <Box
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              ) : (
                <Box
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));
  const renderPagePagination = () => desktopPagination?.map((pageNo:any) => (
    <Box>
      { checkPageRange(pageNo)
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {desktopPage === pageNo
              ? (
                <Box
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              ) : (
                <Box
                  position="relative"
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));
  const renderLastPagePagination = () => desktopPagination?.map((pageNo:any) => (
    <Box>
      {pageNo >= desktopPagination[desktopPagination.length - 5]
        ? (
          <Box
            onClick={() => { goToPage(pageNo); }}
            marginX="5px"
            cursor="pointer"
          >
            {desktopPage === pageNo
              ? (
                <Box
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                  border={`3px solid ${toggleBorder}`}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              ) : (
                <Box
                  className="book-page-item"
                  backgroundImage={`url(${pageImg})`}
                  _hover={{ filter: 'brightness(1.25)' }}
                >
                  <Text
                    className="small-text small-text-white"
                  >
                    {pageNo}
                    -
                    {pageNo + 1}
                  </Text>
                </Box>
              )}
          </Box>
        ) : <Box />}
    </Box>
  ));

  return (
    <Flex
      width={{ base: '95%', lg: '1023px' }}
      borderRadius="5px"
      backgroundColor={bg}
      marginX="auto"
      paddingY="1em"
      marginBottom="2em"
      justifyContent="center"
      flexDir="column"
      alignItems="center"
    >
      {desktopPage < 4
        ? (
          <Flex>
            {renderFirstPagePagination()}
          </Flex>
        ) : (
          <Box>
            {
            desktopPage >= desktopPagination[desktopPagination.length - 3]
              ? (
                <Flex>
                  {renderLastPagePagination()}
                </Flex>
              ) : (
                <Flex>
                  {renderPagePagination()}
                </Flex>
              )
          }
          </Box>
        )}
    </Flex>
  );
}
