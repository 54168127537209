/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  useColorModeValue,

} from '@chakra-ui/react';
import parse from 'html-react-parser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import { BookContent } from '../../../_interfaces/Books';

interface BookState {
  book: {
    currentBook: {
      currentPage: number,
    },
    bookContent: BookContent[],
  }
}

export default function MobileView() {
  const {
    currentPage,
  } = useSelector((res: BookState) => res.book.currentBook);
  const [bookView, setBookView] = useState<BookContent[]>([]);
  const { bookContent } = useSelector((res: BookState) => res.book);
  const bg = useColorModeValue('#FFF', '#222');

  useEffect(() => {
    if (bookContent) {
      setBookView(bookContent);
    }
  }, [currentPage]);

  const renderBook = () => bookView?.map((content:any) => (
    <Box>
      { currentPage === content.page
        ? (
          <Box
            paddingY="10px"
            textAlign="justify"
          >
            <Text
              as="span"
              id={`m_${content.paragraphNo}`}
              fontFamily="Garamond"
              fontSize="18px"
            >
              {parse(content.text)}
            </Text>
          </Box>
        ) : <Box />}
    </Box>
  ));

  return (
    <Box
      id="book-view"
      bg={bg}
      width="100%"
    >
      <Box
        marginX="auto"
        alignItems="left"
        marginTop="10px"
            // whiteSpace="nowrap"
        id="mobile-book-render"
        minH="400px"
        width={{ base: '90vw', sm: '400px', md: 'auto' }}
      >
        { renderBook() }
      </Box>
    </Box>
  );
}
