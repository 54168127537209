/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Box,
  Link,
  Text,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { axiosPrivate } from '../../api/axios';
import Header from './sections/Header';
import SermonPreview from '../../assets/images/sermon_preview.jpg';
import './LoginPage.scss';
import CustomButton from '../../components/CustomButton/CustomButton';

function LoginPage() {
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');
  const [deviceName, setDeviceName] = useState<string>();
  const navigate = useNavigate();
  const rememberMeChecked = !!localStorage.getItem('rememberMe');
  console.log(rememberMeChecked);
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  const initialEmail = localStorage.getItem('rememberMe') || '';
  useEffect(() => {
    let Name = 'Unknown OS';
    if (navigator.userAgent.indexOf('Win') !== -1) { Name = 'Windows OS'; }
    if (navigator.userAgent.indexOf('Mac') !== -1) { Name = 'Macintosh'; }
    if (navigator.userAgent.indexOf('Linux') !== -1) { Name = 'Linux OS'; }
    if (navigator.userAgent.indexOf('Android') !== -1) { Name = 'Android OS'; }
    if (navigator.userAgent.indexOf('like Mac') !== -1) { Name = 'iOS'; }
    // console.log(Name);
    setDeviceName(Name);
  }, []);

  const handleSpinner = (type: 'add' | 'remove') => {
    const spinnerElement = document.getElementById('sign-in-spinner')?.classList;
    spinnerElement?.add(type === 'add' ? 'spinner-on' : 'spinner-off');
    spinnerElement?.remove(type === 'add' ? 'spinner-off' : 'spinner-on');
  };

  return (
    <Box
      className="login-page"
    >
      <Header />
      <Formik
        initialValues={{
          email: initialEmail,
          password: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
          password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSpinner('add');
          setTimeout(() => {
            let setDeviceId = 'Unknown Device';
            if (window.location.hostname === 'localhost') {
              setDeviceId = window.localStorage.getItem('theologianTestDeviceId') || `test_${nanoid()}`;
            } else {
              setDeviceId = window.localStorage.getItem('theologianDeviceId') || nanoid();
            }
            // console.log(setDeviceId);
            const dataToSubmit = {
              email: values.email,
              password: values.password,
              deviceName,
              deviceId: setDeviceId,
            };
            axiosPrivate
              .post('/api/user/loginUser', dataToSubmit)
              .then((res: any) => {
                // console.log(res.payload.headers);
                const { theologianaccess, theologianrefresh } = res.headers;
                window.localStorage.setItem('theologianAccess', theologianaccess);
                window.localStorage.setItem('theologianRefresh', theologianrefresh);
                window.localStorage.setItem('theologianDeviceId', setDeviceId);
                if (rememberMe === true) {
                  window.localStorage.setItem('rememberMe', values.email);
                } else {
                  localStorage.removeItem('rememberMe');
                }
                navigate('/home');
              })
              .catch(() => {
                setFormErrorMessage('Error: username and/or password is incorrect');
                setSubmitting(false);
                handleSpinner('remove');
              });
            // console.log(dataToSubmit);
          }, 500);
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              as="form"
            >
              <Box
                className="login-form-container"
              >
                <Box className="login-left-side">
                  <Image
                    alt="Bible Opened"
                    width={{ base: '25rem', xl: '40rem' }}
                    objectFit="contain"
                    src={SermonPreview}
                  />
                </Box>
                <Box className="login-right-side">
                  <Text
                    as="h1"
                    className="section-heading"
                  >
                    Sign In
                  </Text>
                  <Field name="email">
                    {() => (
                      <FormControl
                        isRequired
                        isInvalid={errors.email && touched.email}
                        mb="4px"
                        maxW="500px"
                      >
                        <label
                          htmlFor="email"
                          className="small-text small-text-black"
                        >
                          Email
                          {' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          id="email"
                          className="input-custom"
                          aria-label="Enter Email"
                          type="email"
                          placeholder="John.Owen@EMAIL.COM"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Text
                          as="p"
                          className="small-text small-text-error"
                          marginTop="5px"
                        >
                          {touched.email && errors.email}
                        </Text>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {() => (
                      <FormControl
                        isRequired
                        isInvalid={errors.password && touched.password}
                        mb="4px"
                        maxW="500px"
                      >
                        <label
                          htmlFor="password"
                          className="small-text small-text-black"
                        >
                          Password
                          {' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          id="password"
                          className="input-custom"
                          aria-label="Enter Password"
                          type="password"
                          placeholder="Enter Your Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Text
                          as="p"
                          className="small-text small-text-error"
                          marginTop="5px"
                        >
                          {touched.password && errors.password}
                        </Text>
                      </FormControl>
                    )}
                  </Field>
                  {formErrorMessage && (
                  <FormLabel>
                    <Text
                      className="small-text small-text-error"
                    >
                      {formErrorMessage}
                    </Text>
                  </FormLabel>
                  )}
                  <Flex justifyContent="space-between" width="100%" maxW="500px" marginBottom="2rem">
                    <Field name="rememberMe">
                      {() => (
                        <FormControl className="remember-me-checkbox">
                          <Checkbox
                            id="rememberMe"
                            onChange={handleRememberMe}
                            isChecked={rememberMe}
                            borderColor="black"
                            transform="scale(1.5)"
                            size="lg"
                          />
                          <Text
                            as="p"
                          >
                            Remember Me
                          </Text>

                        </FormControl>
                      )}
                    </Field>
                    <Link
                      className="small-text small-text-blue"
                      href="/forgot-password"
                    >
                      Forgot Password?
                    </Link>
                  </Flex>
                  <CustomButton
                    buttonType="submit"
                    buttonColor="black"
                    testId="login-button"
                    buttonProps={{
                      id: 'login-button',
                      onClick: handleSubmit,
                      onLoad: isSubmitting,
                    }}
                    label="Sign In"
                    spinnerId="sign-in-spinner"
                    size="medium"
                  />
                  <Link
                    role="link"
                    className="small-text small-text-blue"
                    href="/register"
                    marginTop="2rem"
                    alignSelf="left"
                  >
                    {' '}
                    Register Here!
                  </Link>
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}

export default LoginPage;
