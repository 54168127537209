/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import HomePage from './pages/HomePage/Homepage';
import BookUpload from './pages/BookUpload/BookUpload';
import DetailedBookPage from './pages/DetailedBookPage/DetailedBookPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import RequireAuth from './_auth/RequireAuth';
import RequireNoAuth from './_auth/RequireNoAuth';
import Account from './pages/Account/Account';
import Subscription from './pages/Subscription/Subscription';
import AdminPage from './pages/AdminPage/AdminPage';
import BookSearch from './pages/BookSearch/BookSearch';
import SubSuccess from './pages/SubSuccess/SubSuccess';
import SubCancel from './pages/SubCancel/SubCancel';
import Terms from './components/Footer/sections/Terms';
import Privacy from './components/Footer/sections/Privacy';
import AcceptableUse from './components/Footer/sections/AcceptableUse';
import Cookies from './components/Footer/sections/Cookies';
import DoNotSell from './components/Footer/sections/DoNotSell';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import Friends from './pages/Friends/Friends';
import DiscoverFriends from './pages/DiscoverFriends/DiscoverFriends';
import FriendProfile from './pages/FriendProfile/FriendProfile';
import DetailedFriendBook from './pages/DetailedFriendBook/DetailedFriendBook';
import BookSeries from './pages/BookSeries/BookSeries';
import BookSeriesDetails from './pages/BookSeriesDetails/BookSeriesDetails';
import BookCategory from './pages/BookCategory/BookCategory';
import theme from './_chakra/theme';
import Fonts from './_chakra/fonts';
import Footer from './components/Footer/Footer';
import OriginalBookPage from './pages/OriginalBookPage/OriginalBookPage';
import TestBook from './pages/TestBook/TestBook';
import BookSearchRes from './pages/BookSearchRes/BookSearchRes';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Suspense fallback={<div style={{ minHeight: '100vh' }}><h1>loading...</h1></div>}>
        <Router>
          <Routes>
            <Route element={<RequireAuth allowedRoles={['2022']} />}>
              <Route path="/book/:bookId" element={<DetailedBookPage />} />
              <Route path="/category/:categoryName" element={<BookCategory />} />
              <Route path="/bookseries/:seriesId" element={<BookSeriesDetails />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/myAccount" element={<Account />} />
              <Route path="/subscription" element={<Subscription />} />
              { /* <Route path="/test" element={<Book />} /> */ }
              <Route path="/search" element={<BookSearch />} />
              <Route path="/search-result/:paramSearchQuery" element={<BookSearchRes />} />
              <Route path="/series" element={<BookSeries />} />
              <Route path="/subscription-cancel" element={<SubCancel />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/friends" element={<Friends />} />
              <Route path="discover-friends" element={<DiscoverFriends />} />
              <Route path="/profile/:userId" element={<FriendProfile />} />
              <Route path="/userbooks/:friendUsername/:bookId" element={<DetailedFriendBook />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={['2023']} />}>
              <Route path="/uploadbook" element={<BookUpload />} />
              <Route path="/adminPage" element={<AdminPage />} />
              <Route path="/originalbook/:bookId" element={<OriginalBookPage />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={['2023', '2024', '2025']} />}>
              <Route path="/subscription-success" element={<SubSuccess />} />
            </Route>
            <Route element={<RequireNoAuth />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password/:token" element={<PasswordReset />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/acceptable-use" element={<AcceptableUse />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/do-not-sell" element={<DoNotSell />} />
            <Route path="/test" element={<TestBook />} />
          </Routes>
        </Router>
      </Suspense>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
