/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  Link,
} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux';
import DesktopView from './DesktopView/DesktopView';
import MobileView from './MobileView';
import FootNotes from './FootNotes';
import ChapterList from './ChapterList/ChapterList';
import BookHeader from './BookHeader';
import DesktopPagination from './DesktopPagination/DesktopPagination';
import MobilePagination from './MobilePagination/MobilePagination';
import MyNotes from './MyNotes';
import ColorModeBtn from './ColorModeBtn/ColorModeBtn';

export default function BookView() {
  const bg = useColorModeValue('#FFF', '#222');
  const toggleColor = useColorModeValue('#222', '#FFF');
  // split by page number and line number
  // ensure that chapter jumping works correctly in mobile

  return (
    <Box
      bg={bg}
      color={toggleColor}
      paddingBottom="4rem"
    >
      <Box>
        <Box
          id="book-header"
          display={{ base: 'block', md: 'flex' }}
          width="90%"
          justifyContent="space-between"
          marginX="auto"
          paddingTop="2rem"
        >
          <Box>
            <Box
              display={{ base: 'flex', md: 'none' }}
            >
              <ColorModeBtn />
            </Box>
            <BookHeader />
            <ChapterList />
          </Box>
          <Box>
            <Box
              display={{ base: 'none', md: 'block' }}
            >
              <ColorModeBtn />
            </Box>
            <MyNotes />
          </Box>
        </Box>
        <Box
          display={{ base: 'none', md: 'block' }}
        >
          <DesktopView />
          <DesktopPagination />
        </Box>
        <Box
          display={{ base: 'block', md: 'none' }}
        >
          <MobileView />
          <MobilePagination />
        </Box>
        <FootNotes />
      </Box>
    </Box>
  );
}
