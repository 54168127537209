/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Button,
  Text,
  Textarea,
  Slide,
  useDisclosure,
  useColorModeValue,
  Alert,
  AlertIcon,
  Link,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import DetailedView from './DetailedView';
import updateBookPage from '../../../_helper/BookHelper';
import { Friend, UserBasic } from '../../../_interfaces/User';

interface BookData {
  book: {
    currentBook: {
      userBookId: string,
      bookId: string,
    }
  }
}

export default function FriendNotes() {
  const { userId, isPaid } = useSelector((state:UserBasic) => state.user.userData);
  const { userBookId, bookId } = useSelector((state: BookData) => state.book.currentBook);
  const [updatingFriends, setUpdateFriends] = useState<any>(false);
  const [friends, setFriends] = useState<any>([]);
  const noteBg = useColorModeValue('#DDD', '#333');
  const bg = useColorModeValue('#EEE', '#333');
  const bgHover = useColorModeValue('#DDD', '#444');
  const noteColor = useColorModeValue('#333', '#DDD');
  const borderColor = useColorModeValue('#CCC', '#444');
  const pageColor = useColorModeValue('#DDD', '#444');
  const pageColorHover = useColorModeValue('#CCC', '#555');
  const noteBorderColor = useColorModeValue('#555', '#CCC');
  const navigate = useNavigate();
  const {
    isOpen: isOpenNotes,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();

  const openNoteHandler = () => {
    // console.log(bookId);
    setUpdateFriends(true);
    // console.log(bookId, userBookId);
    axios({
      method: 'POST',
      url: '/api/userbook/friendsWithNotes',
      data: {
        userId,
        bookId: userBookId,
      },
    })
      .then((res) => {
        const { userList } = res.data;
        console.log(userList);
        const filtered = userList.filter((data:Friend) => data !== null);
        setFriends([...filtered]);
        setUpdateFriends(false);
        onOpenNotes();
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };

  return (
    <Box
      paddingLeft={{ base: '0', lg: '4em' }}
      display={{ base: 'flex', md: 'block' }}
      justifyContent="center"
    >
      <Button
        color={noteColor}
        bg={bg}
        _hover={{ bg: bgHover }}
        className="link-btn link-btn-small"
        onClick={() => { openNoteHandler(); }}
        minW={{ base: '90vw', sm: '14rem' }}
        width={{ base: '90vw', sm: '14rem' }}
        marginX={{ base: 'auto', sm: 'none' }}
      >
        Friend Notes

      </Button>
      <Slide
        direction="left"
        in={isOpenNotes}
        style={{
          height: '100vh', width: 'auto', zIndex: 100,
        }}
      >
        <Box
          color={noteColor}
          bg={bg}
          rounded="md"
          h="100vh"
          w={{ base: '100vw', sm: '400px' }}
          padding="1em"
        >
          <Flex
            justifyContent="space-between"
            marginX="auto"
          >
            <Heading fontSize={24} fontFamily="PTSerif">
              Friend&apos;s Notes
            </Heading>
            <Button
              _focus={{ outline: 'none' }}
              aria-label="Close Control Panel"
              onClick={onCloseNotes}
              fontSize="3rem"
            >
              &times;
            </Button>
          </Flex>
          {isPaid === false
            ? (
              <Box
                marginTop="1em"
              >
                <Alert
                  status="info"
                >
                  <AlertIcon />
                  <Text
                    onClick={() => { navigate('/subscription'); }}
                    className="medium-text"
                  >
                    Please Subscribe
                    {' '}
                    <Link
                      href="/subscription"
                      textDecor="underline"
                    >
                      here
                    </Link>
                    {' '}
                    to see all your friend&apos;s notes
                  </Text>
                </Alert>
              </Box>
            )
            : (
              <Box>
                {
                  updatingFriends ? (
                    <LoadingSpinner />
                  ) : (
                    <Box
                      overflow="auto"
                      marginTop="1em"
                      maxHeight="85vh"
                      id="all-friendnotes"
                    >
                      {
                        friends?.map((friend:Friend) => (
                          <Box
                            p={5}
                            shadow="md"
                            borderWidth="1px"
                            m="5px"
                            bg={noteBg}
                            color={noteColor}
                            key={friend._id}
                          >
                            <DetailedView
                              friendId={friend?._id}
                              notesModalUse={false}
                              username={friend?.username}
                              image={friend?.image}
                              bookId={userBookId}
                              updateBookPage={updateBookPage}
                            />
                          </Box>
                        ))
                      }
                    </Box>
                  )
                }
              </Box>
            )}
        </Box>
      </Slide>
    </Box>
  );
}
