/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import { axiosPrivate } from '../../../api/axios';
import { UserBasic } from '../../../_interfaces/User';
import CustomButton from '../../../components/CustomButton/CustomButton';

interface Admin {
  username: string,
  email: string,
}

export default function AdminUsers() {
  const { username } = useSelector((state: UserBasic) => state.user.userData);
  const [listOfAdmins, setListOfAdmins] = useState<Admin[]>([]);
  const [adminCandidate, setAdminCandidate] = useState<string>();

  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const promoteToAdmin = () => {
    // regular users that are not admins
    if (adminCandidate) {
      axiosPrivate({
        method: 'PUT',
        url: '/api/user/giveAdminRights ',
        data: { username: adminCandidate },
      })
        .then(() => {
          // eslint-disable-next-line no-alert
          alert(`${adminCandidate} is now an admin user!`);
          window.location.reload();
        })
        .catch((err) => {
        // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };
  const getAdmins = () => {
    axiosPrivate({
      method: 'GET',
      url: '/api/user/getAdmins',
    })
      .then((res) => {
        const { users } = res.data;
        setListOfAdmins(users);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    if (username) {
      getAdmins();
      // getTestUsers();
    }
  }, [username]);

  const setCurrentAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminCandidate(event.target.value);
  };

  return (
    <Box as="main" minH="80vh">
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        bg="#222"
        width={{ base: '95%', lg: '1023px' }}
        marginX="auto"
        minH="25vh"
      >
        <Text
          className="medium-text medium-text-white"
        >
          Promote to Admin with username
        </Text>
        <Input
          color="#000"
          width={{ base: '250px', lg: '400px' }}
          marginX="auto"
          style={{ color: '#FFF' }}
          placeholder="Username"
          border="0"
          outline="0"
          _focus={{ outline: '0' }}
          backgroundColor="#555"
          marginBottom="2rem"
          onChange={setCurrentAdmin}
        />
        <CustomButton
          label="Submit"
          testId={`set_admin_${adminCandidate}`}
          buttonColor="night"
          buttonProps={{
            onClick: (() => {
              if (adminCandidate) {
                onOpenEditModal();
              }
            }),
            id: `set_admin_${adminCandidate}`,
          }}
          spinnerId={undefined}
          buttonType="button"
          size="small"
        />
      </Flex>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent
          bg="#222"
          color="#FFF"
        >
          <ModalHeader
            className="medium-text medium-text-white"
          >
            Admin Add
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              className="small-text small-text-white"
            >
              Are you sure you want
              {' '}
              {adminCandidate}
              {' '}
              to be an admin?
            </Text>
          </ModalBody>
          <ModalFooter
            gap="1rem"
          >
            <CustomButton
              label="Cancel"
              testId={`cancel_admin_${adminCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  onCloseEditModal();
                }),
                id: `cancel_admin_${adminCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />
            <CustomButton
              label="Submit"
              testId={`submit_admin_${adminCandidate}`}
              buttonColor="night"
              buttonProps={{
                onClick: (() => {
                  promoteToAdmin();
                }),
                id: `submit_admin_${adminCandidate}`,
              }}
              spinnerId={undefined}
              buttonType="button"
              size="small"
            />

          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          height="100px"
        >
          <Text
            className="medium-text medium-text-white"
          >
            Admin Registry
          </Text>
        </Flex>
        <SimpleGrid
          gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr 1fr' }}
          width={{ base: '95%', lg: '1023px' }}
          marginX="auto"
          marginY="2rem"
          padding="2em"
          gap="1rem"
        >
          {listOfAdmins?.map((admin: Admin) => (
            <Box
              borderRadius="8px"
              bg="#333"
              boxShadow="rgba(100, 100, 111, 0.2) 0px 4px 16px 0px"
              overflowX="scroll"
              flexWrap="nowrap"
              width="95%"
              marginX="auto"
              scrollBehavior="smooth"
              textAlign="center"
              padding="1rem"
            >
              <Text
                className="small-text small-text-white"
              >
                {admin.username}
              </Text>
              <Text
                className="small-text small-text-white"
              >
                {admin.email}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
