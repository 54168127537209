/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  Button,
  Link,
} from '@chakra-ui/react';
import { BookListItem } from '../../_interfaces/Books';
import './BookList.scss';

export default function BookList(props: {
  bookList: BookListItem[],
  bookErr: string | undefined,
  type: string,
  title: string,
}) {
  const {
    bookList, type, title, bookErr,
  } = props;

  const moveLeft = () => {
    const bookCarousel = document.getElementById(type);
    if (bookCarousel) {
      bookCarousel.scrollLeft -= 600;
    }
  };

  const moveRight = () => {
    const bookCarousel = document.getElementById(type);
    if (bookCarousel) {
      bookCarousel.scrollLeft += 600;
    }
  };

  const renderBookList = () => bookList?.map((book:BookListItem) => (
    <Link
      marginX="1rem"
      flex="0 0 auto"
      key={book._id}
      href={`/book/${book.bookId ? book.bookId : book._id}`}
    >
      {book.coverImage
        ? (
          <Image
            src={book.coverImage}
            className="carousel-book"
            loading="lazy"
          />
        ) : (
          <Flex
            className="carousel-book carousel-book-no-image"
          >
            <Text
              className="small-text small-text-white"
            >
              {book.title}
            </Text>
            <Text
              className="x-small-text x-small-text-white"
              marginTop="1rem"
            >
              {book.author}
            </Text>
          </Flex>
        )}
    </Link>
  ));
  return (
    <Box
      as="section"
      paddingY="50px"
      position="relative"
    >
      {bookList.length
        ? (
          <>
            <Text
              marginLeft="8%"
              className="large-text large-text-white large-text-bold"
            >
              {title}
            </Text>
            <Flex>
              <button
                id="leftCarouselButton"
                type="submit"
                className="carousel-btn carousel-btn-left"
                onClick={moveLeft}
              >
                &lt;
              </button>
              <Box id={type} className="carousel-container">
                {renderBookList()}
              </Box>
              <button
                // display={{ base: 'none', md: 'block' }}
                id="rightCarouselButton"
                type="submit"
                className="carousel-btn carousel-btn-right"
                onClick={moveRight}
              >
                &gt;

              </button>
            </Flex>
          </>
        ) : (
          <Box>
            {
              bookErr
                ? (
                  <Text
                    height="200px"
                    className="section-heading"
                  >
                    {bookErr}
                  </Text>
                ) : <Box />
            }
          </Box>
        )}
    </Box>
  );
}
