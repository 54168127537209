/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import axios, { axiosPrivate } from '../../api/axios';
import Navbar from '../../components/Navbar/Navbar';
import TrialUsers from './sections/TrialUsers';
import { UserBasic } from '../../_interfaces/User';
import AdminUsers from './sections/AdminUsers';
import Testusers from './sections/TestUsers';
import RegularUsers from './sections/RegularUsers';

interface User {
  username: string,
  email: string,
}

function AdminPage() {
  // get number of users that aren't my email or andy's
  // promote users to admin
  // invite user via email maybe..
  // list out all admin and email
  // list of paid users (future);
  const user = useSelector((state: UserBasic) => state.user.userData);
  const [regUsers, setRegUsers] = useState<User[]>([]);
  const [numPaidUsers, setNumPaidUsers] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [numberRegUsers, setNumberRegUsers] = useState(1);

  const itemsPerPage = 18;

  const getPaidUsers = () => {
    axios({
      method: 'GET',
      url: '/api/user/getNumPaidUsers',
    })
      .then((res) => {
        const { userCount } = res.data;
        // console.log(userCount);
        setNumPaidUsers(userCount);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const getRegUsers = () => {
    // regular users that are not admins
    axiosPrivate({
      method: 'POST',
      url: '/api/user/initRegUsers',
      data: {
        itemsPerPage,
      },
    })
      .then((res) => {
        const { users, userCount } = res.data;
        setRegUsers(users);
        setNumberRegUsers(userCount);
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(userCount / itemsPerPage));
        setItemOffset(endOffset);
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setUsersDisplayed(standardUsers.slice(itemOffset, endOffset));
        // setListOfAdmins(adminUsers);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  useEffect(() => {
    if (user.username) {
      getPaidUsers();
      getRegUsers();
      // getTestUsers();
    }
  }, [user.username]);

  return (
    <Box
      color="#FFF"
      bg="#111"
    >
      <Navbar />
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100px"
      >
        <Text
          className="large-text large-text-white"
        >
          Hello
          {' '}
          {user.username}
          !
        </Text>
      </Flex>
      <Tabs position="relative" align="center" variant="unstyled">
        <TabList>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Admin
          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Testers
          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Users
          </Tab>
          <Tab
            _selected={{ borderBottom: '2px solid #FFF' }}
            className="medium-text medium-text-bold"
          >
            Trial
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AdminUsers />
          </TabPanel>
          <TabPanel>
            <Testusers />
          </TabPanel>
          <TabPanel>
            <RegularUsers />
          </TabPanel>
          <TabPanel>
            <TrialUsers />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default AdminPage;
