/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Box,
  MenuItem,
  Menu,
  MenuList,
  MenuButton,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { BookContent } from '../../../../_interfaces/Books';
import updateBookPage from '../../../../_helper/BookHelper';
import './ChapterList.scss';

interface BookState {
  book: {
    currentBook: {
      chapterList: string[],
      currentChapter: string,
    }
    bookContent: BookContent[]
  }
}

export default function ChapterList() {
  const chapters = useSelector((res: BookState) => res.book.currentBook.chapterList);
  const currentChapter = useSelector((res: BookState) => res.book.currentBook.currentChapter);
  const { bookContent } = useSelector((res: BookState) => res.book);
  const bg = useColorModeValue('#EEE', '#333');
  const bgHover = useColorModeValue('#DDD', '#444');
  const color = useColorModeValue('#333', '#EEE');

  const editChapter = (chapter: string) => {
    if (chapter === 'CHAPTER: INTRO') {
      updateBookPage(1, 'CHAPTER: INTRO');
    } else {
      bookContent.forEach((paragraph:BookContent) => {
        if (paragraph.chapterTitle === chapter) {
          updateBookPage(paragraph.page, paragraph.chapterTitle);
        }
      });
    }
  };

  const renderChapterList = () => chapters?.map((chapterTitle:string) => (
    <MenuItem
      whiteSpace="nowrap"
      onClick={() => { editChapter(chapterTitle); }}
      bg={bg}
      color={color}
      _hover={{ bg: bgHover }}
      className="flex-left-center"
      fontFamily="Garamond"
      fontSize={{ base: '12px', lg: '16px' }}
      textOverflow="ellipsis"
      overflowX="hidden"
      padding="1rem"
    >
      {chapterTitle}
    </MenuItem>
  ));
  return (
    <Box
      id="chapter-list"
    >
      <Box
        display={{ base: 'flex', md: 'block' }}
        justifyContent="center"
        marginX="auto"
        paddingBottom="10px"
        paddingLeft={{ base: '0', lg: '4em' }}
      >
        <Menu>
          <MenuButton
            as={Button}
            minW={{ base: '90vw', sm: '20rem' }}
            width={{ base: '90vw', sm: 'auto' }}
            fontSize="1.6rem"
            bg={bg}
            color={color}
            _hover={{ bg: bgHover }}
            overflowX="clip"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            padding="1rem"
            className="link-btn link-btn-small"
          >
            {currentChapter}
            {' '}
            &#x25BC;
          </MenuButton>
          <MenuList
            margin="auto"
            width={{ base: '80vw', sm: '400px', md: 'auto' }}
            bg={bg}
            border={`1px solid ${bg}`}
          >
            <MenuItem
              onClick={() => { editChapter('CHAPTER: INTRO'); }}
              bg={bg}
              color={color}
              _hover={{ bg: bgHover }}
              padding="1rem"
              className="small-text"
              textOverflow="ellipsis"
              overflowX="hidden"
            >
              CHAPTER: INTRO
            </MenuItem>
            {renderChapterList()}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}
