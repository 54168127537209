import React from 'react';
import {
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import theologiansImg from '../../../../assets/images/theologians.png';
import './MainBody.scss';

function MainBody() {
  // const toggleColor = useColorModeValue('#000', '#FFF');
  return (
    <Box
      as="section"
      className="main-body"
    >
      <Box
        as="div"
        className="main-body-text"
      >
        <Text
          as="h1"
          className="app-heading"
        >
          <em>Tolle lege. </em>
          Pick up and Read.
        </Text>
        <Text
          as="p"
          className="large-text large-text-grey"
        >
          Enjoy the richest theological writings of church history
        </Text>
        <Text
          as="p"
          className="large-text large-text-grey"
        >
          made easily accessible and readable.
        </Text>
      </Box>
      <Image
        maxWidth={{ base: '90%', lg: '1200px' }}
        marginX="auto"
        alt="Group of Theologians"
        width="100%"
        src={theologiansImg}
        loading="lazy"
      />
    </Box>
  );
}

export default MainBody;
