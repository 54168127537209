import React from 'react';
import {
  Box,
  Text,
  Link,
} from '@chakra-ui/react';

function Header() {
  return (
    <Box
      bg="#000"
      paddingBottom="1em"
    >
      <Box
        paddingLeft="2em"
      >
        <Link
          marginLeft="1em"
          href="/"
        >
          <Text
            fontFamily="sans-serif, Montserrat"
            fontSize={{ base: '12px', sm: '17px' }}
            fontWeight="750"
            letterSpacing="3px"
            textTransform="uppercase"
            cursor="pointer"
            color="#FFF"
          >
            Theologian&apos;s Pen
          </Text>
        </Link>
      </Box>
    </Box>
  );
}
export default Header;
