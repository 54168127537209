import { createRef } from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initState = {
  recaptchaRef: createRef(),
};

export const menuSlice = createSlice({
  name: 'navMenu',
  initialState: initState,
  reducers: {
    getRecaptcha: (state: any) => state.recaptchaRef,
  },
});

export const { getRecaptcha } = menuSlice.actions;
export default menuSlice.reducer;
