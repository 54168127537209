import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import MainBody from './sections/MainBody/MainBody';
import ContactUs from './sections/ContactUs/ContactUs';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import WhoWeAre from './sections/WhoWeAre/WhoWeAre';
import SignUp from './sections/SignUp/SignUp';
import NavbarLanding from '../../components/NavbarLanding/NabarLanding';

export default function LandingPage() {
  return (
    <Box
      as="main"
    >
      <NavbarLanding />
      <MainBody />
      <HowItWorks />
      <WhoWeAre />
      <SignUp />
      <ContactUs />
    </Box>
  );
}
