/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { BookContent } from '../_interfaces/Books';

const initState = {
  currentBook: {
    desktopPagination: [''],
    mobilePagination: [''],
    currentPage: 1,
    totalPages: 1,
    chapterList: [''],
    currentChapter: '',
    defaultBook: true,
    author: '',
    title: '',
    userNotes: [{}],
    footNotes: [{}],
    isUserBook: false,
    bookId: '',
    userBookId: '',
    contentId: '',
    highlights: [{}],
  },
  bookContent: [],
  errors: '',
};

export const bookSlice = createSlice({
  name: 'book',
  initialState: initState,
  reducers: {
    postBook: (state, action) => {
      const {
        bookData, bookContent, userBook, isPaid,
      } = action.payload;
      state.currentBook.desktopPagination = bookData.desktopPagination;
      state.currentBook.mobilePagination = bookData.mobilePagination;
      state.currentBook.chapterList = bookData.chapterList;
      state.currentBook.totalPages = bookData.totalPages;
      state.currentBook.author = bookData.author;
      state.currentBook.title = bookData.title;
      state.currentBook.footNotes = bookData.footNotes;
      state.currentBook.contentId = bookData.contentId;
      state.currentBook.bookId = bookData._id;
      state.bookContent = bookContent;
      if (isPaid) {
        state.currentBook.currentPage = bookData.currentPage;
        bookContent.forEach((paragraph:any) => {
          if (paragraph.page === bookData.currentPage) {
            state.currentBook.currentChapter = paragraph.chapter;
          }
        });
        if (userBook) {
          // console.log(book.userNotes);
          state.currentBook.defaultBook = false;
          state.currentBook.isUserBook = true;
          state.currentBook.userBookId = bookData.bookId;
          state.currentBook.userNotes = bookData?.userNotes || [];
          state.currentBook.highlights = bookData?.Highlights || [];
        }
      }
    },
    updateBook: (state, action) => {
      // console.log(action.payload)
      const bookData = action.payload;
      bookData.pagination = action.payload.mobilePagination;
      state.currentBook = bookData;
    },
    updateBookContent: (state, action) => {
      state.bookContent = action.payload;
    },
    updatePage: (state, action) => {
      const { currentChapter, currentPage } = action.payload;
      state.currentBook.currentChapter = currentChapter;
      state.currentBook.currentPage = currentPage;
    },
  },
});

export const {
  postBook, updateBook, updateBookContent, updatePage,
} = bookSlice.actions;

export default bookSlice.reducer;
