/* eslint-disable no-unused-vars */
import {
  Box,
  Heading,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import theologians from '../../assets/images/theologians.png';
import sermonPreview from '../../assets/images/sermon_preview.jpg';
import SeriesList from './SeriesList';
import './BookSeries.scss';

function BookSeries() {
  return (
    <Box>
      <Navbar />
      <Box
        className="book-series-container"
      >
        <SeriesList />
      </Box>
    </Box>
  );
}

export default BookSeries;
