import React from 'react';
import {
  Box,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
// import FreeTier from './FreeTier';
import StandardPackage from './Tiers/Standard';
import FreeRegister from './Tiers/Free';
import './SignUp.scss';

function SignUp() {
  return (
    <Box
      as="section"
      id="signup-cards"
      className="signup-section"
    >
      <Text
        as="h1"
        className="section-heading large-text-white"
        marginBottom="4rem"
      >
        Plans:
      </Text>
      <Box
        className="signup-section-cards"
      >
        <FreeRegister />
        <StandardPackage />
      </Box>
    </Box>
  );
}

export default SignUp;
