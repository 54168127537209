import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: 'PTSerif sans-serif Garamond',
      },
    },
  },

});

export default theme;
