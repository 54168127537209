/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, createRef, Ref,
} from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios, { axiosPrivate } from '../../api/axios';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import { BookListItem } from '../../_interfaces/Books';

export default function AllBooks() {
  const [myBookList, setMyBookList] = useState<BookListItem []>();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [startingPage, setStartingPage] = useState(0);
  const [allBooksCount, setAllBooksCount] = useState(0);
  const itemsPerPage = 16;
  const itemOffset = 0;
  // const [selectedBook, setSelectedBook] = useState();
  const [err, setErr] = useState();
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % allBooksCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`,
    // );
    axiosPrivate({
      method: 'POST',
      url: '/api/book/booksByIndex',
      data: {
        itemsPerPage,
        offSetIndex: newOffset,
      },
    })
      .then((res) => {
        // console.log(res.data.searchResults);
        const { books } = res.data;
        if (books.length < 0) {
          setMyBookList([]);
          return;
        }
        setMyBookList(books);
        setStartingPage(event.selected);
      })
      .catch((bookErr) => {
        // eslint-disable-next-line no-console
        console.log(bookErr);
      });
  };
  const getBooks = () => {
    axios({
      method: 'POST',
      url: '/api/book/booksByIndex',
      withCredentials: true,
      data: {
        offSetIndex: itemOffset,
        itemsPerPage,
      },
    })
      .then((res) => {
        const { books, bookCount } = res.data;
        setMyBookList(books);
        setPageCount(Math.ceil(bookCount / itemsPerPage));
        setAllBooksCount(bookCount);
      })
      .catch((resErr) => {
        setErr(resErr);
        // eslint-disable-next-line no-console
        navigate('/login');
      });
  };

  useEffect(() => {
    getBooks();
  }, []);

  const renderBooksList = () => myBookList?.map((book) => (
    <Link
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      key={book._id}
      href={`/book/${book._id}`}
    >
      {book.coverImage
        ? (
          <Image
            cursor="pointer"
            borderRadius="5px"
            src={book.coverImage}
            _hover={{ transform: 'scale(1.1)', transition: 'transform 0.25s ease' }}
          />
        ) : (
          <Flex
            color="#FFF"
                  // border="1px solid #AAA"
            borderRadius="5px"
            justifyContent="center"
            flexDir="column"
            textAlign="center"
            paddingX="5px"
            paddingTop="10rem"
          >
            <Text
              fontFamily="PtSerif"
              fontSize="16px"
            >
              {book.title}
            </Text>
            <Text
              fontFamily="PtSerif"
              marginTop="10px"
              fontSize="14px"
            >
              {book.author}
            </Text>
          </Flex>
        )}
    </Link>
  ));
  return (
    <Box
      paddingY="50px"
      width={{ base: '90%', lg: '90%', xl: '85%' }}
    >
      <Text
        fontFamily="PtSerif"
        fontSize={{ base: '24px', md: '28px' }}
        fontWeight="700"
        color="#FFF"
        letterSpacing="1px"
      >
        All Books
      </Text>
      <SimpleGrid
        id="admin-book-list"
        gridTemplateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        gridGap={{ base: '10px', md: '20px' }}
        rowGap={{ base: '20px', md: '20px' }}
        marginX="auto"
        minH="650px"
      >
        {renderBooksList()}
      </SimpleGrid>
      <CustomPagination
        pageCount={pageCount}
        startingPage={startingPage}
        handlePageClick={handlePageClick}
      />
      <Box />
    </Box>
  );
}
